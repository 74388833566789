import React, { Component } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { toast } from 'react-toastify';
import * as apiClient from "../../../components/network/apiClient";
import { Link } from 'react-router-dom';

export default class ServiceProviderSignUpForm extends Component {
    constructor(props) {
        super(props);
      //  console.log(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            company: "",
            designation: "",
            password: "",
            confirmPassword: "",
            requirementSubmitted: false,
            isPrivecyChecked: false,
            isTermsChecked: false,
            businessStatus: "",
            otherDesignation: "",
            otherBusinessStatus: "",
            isOtherDesignationSelected: false,
            isOtherBusinessStatusSelected: false,
            serverMessage: "",
            errors: {},
            accountCode: "",
        };
    }
    registerAccount = () => {
        const { businessStatus, otherDesignation, otherBusinessStatus, isOtherDesignationSelected, isOtherBusinessStatusSelected,
            firstName, lastName, email, mobile, company, designation, password, confirmPassword, isPrivecyChecked, isTermsChecked } = this.state;

        let errors = {};
        let hasError = false;
        if (firstName == "") {
            //toast.error("Name is required");
            errors.firstName = "First name is required";
            hasError = true;
        }

        if (email == "") {
            //toast.error("Email address is required");
            errors.email = "Email address is required";
            hasError = true;
        }
        if (mobile == "") {
            //toast.error("Mobile number is required");
            errors.mobile = "Mobile number is required";
            hasError = true;
            //return;
        }

        if (company == "") {
            errors.company = "company is required";
            hasError = true;
            //return;
        }
        if (password == "") {
            errors.password = "password is required";
            hasError = true;
            //return;
        }
        if (password.length < 5) {
            errors.password = "Password must be atleast 5 characters.";
            hasError = true;
            //return;
        }

        if (confirmPassword == "") {
            errors.confirmPassword = "Confirm password is required";
            hasError = true;
            //return;
        }
        if (confirmPassword != password) {
            errors.confirmPassword = "Password and Confirm password do not match";
            hasError = true;
            //return;
        }

        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(email)) {
            errors.email = "Email address is not valid";
            hasError = true;
            //return;
        }

        const pattern = /^[0-9 '.-]+$/;
        if (!pattern.test(mobile)) {
            errors.mobile = "Only numbers are allowed in mobile";
            hasError = true;
            //return;
        }

        if (mobile.length < 10) {
            errors.mobile = "mobile number is not valid";
            hasError = true;
            //return;
        }


        if (mobile.length < 10) {
            errors.mobile = "mobile number is not valid";
            hasError = true;
            //return;
        }

        if (isPrivecyChecked == false) {
            errors.isPrivecyChecked = "You Must agree with Privacy policy and Terms of services to register.";
            hasError = true;
            //return;
        }
        let d = designation;
        if (d == "Other") {
            d = otherDesignation;
        }
        let b = businessStatus;
        if (b == "Other")
            b = otherBusinessStatus;

        this.setState({
            errors: errors
        });
        if (hasError == true) return;

        let data = {
            Name: firstName + " " + lastName,
            phone: mobile,
            email: email,
            password: password,
            company: company,
            designation: d,
            businessstatus: b,
            isindividual: 0,
            registervia: "WEB",
            otp: "",
            usertype: 2,
            roletypeid: 13,
        };
        apiClient.registerAccount(data).then(res => {
           // console.log(res);
            if (res[0].result == false) {
                let m = res[0].message;
                toast.error(m);
                this.setState({ serverMessage: m, requirementSubmitted: false });
            } else {
                let accountCode = "AC00" + res[0].userId;
                this.setState({ accountCode: accountCode, requirementSubmitted: true });
            }

        }).catch(err => {
            console.log(err);
        })
    }
    componentDidMount() {

    }
    render() {
        const { isOtherDesignationSelected, isOtherBusinessStatusSelected, businessStatus, otherDesignation, otherBusinessStatus,
            firstName, lastName, email, mobile, subject, company, errors, serverMessage, accountCode,
            designation, password, confirmPassword, requirementSubmitted } = this.state;
        return (
            <section className="signup-form-wrapper">
                <div className="container">
                    { requirementSubmitted && <div className="signup-form-box">
                        <h3>Dear Partner,</h3><br />
                        <p>You have taken the right decision to join India’s 1st Full Fledged Technology Platform, SERVIZKART, to showcase your capabilities and grow your business while saving the business promotion and marketing cost.</p>
                        <p>Please note the reference no. { accountCode } for any future reference. You will also receive the acknowledgement on your registered email also.</p>
                        <p>Our Verification Team will review your details and will update for any additional information on email or on phone. Our Verification Team or any Third Party Appointed Verifier may visit your given office address.</p>
                        <br/>
                        <p>You can reach out to us for any query at <b>partnership@servizkart.com</b>.</p>
                        <p>Our entire process will take maximum 7 days to on board you on SERVIZKART platform.</p>
                        <br />
                        <p><strong>Thank You</strong></p>
                        <p><strong>Partner Relation Team</strong></p>

                    </div> }
                    { !requirementSubmitted && <div className="signup-form-box">
                        <div className="signup-form-top text-center">
                            <h5>Become a Verified Servizkart Partner</h5>
                            <div>
                                With SERVIZKART, you can take advantage of our full spectrum of professional services and grab a bigger piece of the pie. Expand your revenue potential by joining SERVIZKART Partner Network. We offer a multitude partner relationship options related to each of our service categories.<br />
                            </div>
                            {/* <p>with your social network.</p> */ }
                        </div>
                        {/* <div className="signup-form-signin d-flex">
                            <button className="theme-button google" type="submit">
                                Google <FaGoogle className="icon" />
                            </button>
                            <button className="theme-button facebook" type="submit">
                                Facebook <FaFacebookF className="icon" />
                            </button>
                            <button className="theme-button twitter" type="submit">
                                Twitter <FaTwitter className="icon" />
                            </button>
                        </div> */}
                        {/* <div className="connector text-center">
                            <span>or</span>
                        </div> */}
                        <form className="signup-form form-container">
                            <div className="form-controls-container">
                                <div className="form-control-parent">
                                    <input className="form-control" type="text"
                                        value={ firstName }
                                        onChange={ (event) => {
                                            this.setState({ firstName: event.target.value });
                                        } }
                                        required
                                        placeholder="First Name (*)" />
                                    { errors && errors.firstName && <div className="input-error">{ errors.firstName }</div> }
                                </div>

                                <div className="form-control-parent">
                                    <input className="form-control" type="text"
                                        value={ lastName }
                                        onChange={ (event) => {
                                            this.setState({ lastName: event.target.value });
                                        } }
                                        required
                                        placeholder="Last Name" />
                                    { errors && errors.lastName && <div className="input-error">{ errors.lastName }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <input className="form-control" type="text"
                                        value={ mobile }
                                        onChange={ (event) => {
                                            this.setState({ mobile: event.target.value });
                                        } }
                                        placeholder="Mobile (*)" />
                                    { errors && errors.mobile && <div className="input-error">{ errors.mobile }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <input className="form-control" type="email"
                                        value={ email }
                                        onChange={ (event) => {
                                            this.setState({ email: event.target.value });
                                        } }
                                        placeholder="Email Address (*)" />
                                    { errors && errors.email && <div className="input-error">{ errors.email }</div> }
                                </div>
                                <div className="full-width-control-parent">
                                    <input className="form-control full-width-control" type="text"
                                        value={ company }
                                        onChange={ (event) => {
                                            this.setState({ company: event.target.value });
                                        } }
                                        placeholder="Enter your Company" />
                                    { errors && errors.company && <div className="input-error">{ errors.company }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <select className="form-control"
                                        onChange={ (e) => {
                                            this.setState({ businessStatus: e.target.value });
                                            if (e.target.value == "Other")
                                                this.setState({ isOtherBusinessStatusSelected: true });
                                            else
                                                this.setState({ isOtherBusinessStatusSelected: false });
                                        } }>
                                        <option value="">Select Business Status</option>
                                        <option value="10">Private Limited</option>
                                        <option value="11">Public Limited</option>
                                        <option value="12">Limited Liability Partnership(LLP)</option>
                                        <option value="13">Sole Propietorship</option>
                                        <option value="14">Partnership (LLP)</option>
                                        <option value="15">Other</option>
                                    </select>
                                    { errors && errors.businessStatus && <div className="input-error">{ errors.businessStatus }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <input className="form-control" type="text"
                                        value={ otherBusinessStatus }
                                        disabled={ (isOtherBusinessStatusSelected) ? false : true }
                                        onChange={ (event) => {
                                            this.setState({ otherBusinessStatus: event.target.value });
                                        } }
                                        placeholder="Enter Business Status (Other)" />
                                    { errors && errors.otherBusinessStatus && <div className="input-error">{ errors.otherBusinessStatus }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <select className="form-control"
                                        onChange={ (e) => {
                                            this.setState({ designation: e.target.value });
                                            if (e.target.value == "Other")
                                                this.setState({ isOtherDesignationSelected: true });
                                            else
                                                this.setState({ isOtherDesignationSelected: false });
                                        } }
                                    >
                                        <option value="">Select Designation</option>
                                        <option value="Director">Director</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Proprietor">Proprietor</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    { errors && errors.designation && <div className="input-error">{ errors.designation }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <input className="form-control" type="text"
                                        value={ otherDesignation }
                                        disabled={ (isOtherDesignationSelected) ? false : true }
                                        onChange={ (event) => {
                                            this.setState({ otherDesignation: event.target.value });
                                        } }
                                        placeholder="Enter your Designation (Other)" />
                                    { errors && errors.otherDesignation && <div className="input-error">{ errors.otherDesignation }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <input className="form-control" type="password"
                                        value={ password }
                                        onChange={ (event) => {
                                            this.setState({ password: event.target.value });
                                        } }
                                        placeholder="Password (*)" />
                                    { errors && errors.password && <div className="input-error">{ errors.password }</div> }
                                </div>
                                <div className="form-control-parent">
                                    <input className="form-control" type="password"
                                        value={ confirmPassword }
                                        onChange={ (event) => {
                                            this.setState({ confirmPassword: event.target.value });
                                        } }
                                        placeholder="Confirm Password (*)" />
                                    { errors && errors.confirmPassword && <div className="input-error">{ errors.confirmPassword }</div> }
                                </div>
                            </div>

                            <div className="register-note">Note: All fields marked (*) are mandatory.</div>
                            <div className="checkboxes">
                                <label className="privacy-policy"> I agree to Servizkart's <Link target="_blank" to="/privacy-policy">Privacy Policy </Link> and <Link target="_blank" to="/terms-of-use-partner">Terms of Services.</Link>
                                    <input type="checkbox" onChange={ (event) => {
                                        this.setState({
                                            isPrivecyChecked: event.target.checked
                                        });
                                    } } />
                                    <span className="checkmark"></span>
                                </label>
                                { errors && errors.isPrivecyChecked && <div className="input-error">{ errors.isPrivecyChecked }</div> }
                            </div>
                            <div className='server-error'>{ serverMessage }</div>
                            <button type="button" value="Register Account" className="theme-button register-button"
                                onClick={ () => {
                                    this.registerAccount();
                                } }
                            >Register Account <FiChevronRight className="icon" /></button>
                        </form>
                        <p className="already-account">
                            Already have an account? <a href="http://admin.servizkart.com">Login</a>
                        </p>
                    </div> }
                </div>
            </section>
        )
    }
}
