import React, { Component } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import * as apiClient from "../network/apiClient";
import { ToastContainer, toast } from 'react-toastify';

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        //  console.log(props);
        this.state = {
            fullName: "",
            email: "",
            org: "",
            mobile: "",
            subject: "",
            message: "",
            region: "",
            enqury: "",
            requirementSubmitted: false,
            errors: {},
            cities: [],
            user: {},
        };
    }
    addServiceRequirement = () => {
        const { fullName, email, mobile, subject, message, org } = this.state;
        let errors = {};
        let hasError = false;

        if (fullName == "") {
            errors.fullName = "Name is required";
            hasError = true;
        }

        if (email == "") {
            errors.email = "Email address is required";
            hasError = true;
        }
        if (mobile == "") {
            errors.mobile = "Mobile number is required";
            hasError = true;
        }

        // if (subject == "") {
        //     errors.subject = "Subject is required";
        //     hasError = true;
        // }

        if (message == "") {
            errors.message = "Message is required";
            hasError = true;
        }


        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(email)) {
            errors.email = "Email address is not valid";
            hasError = true;
        }

        const pattern = /^[0-9 '.-]+$/;
        if (!pattern.test(mobile)) {
            errors.mobile = "Only numbers are allowed in mobile";
            hasError = true;
        }

        if (mobile.length < 10) {
            errors.mobile = "mobile number is not valid";
            hasError = true;
        }


        if (mobile.length < 10) {
            errors.mobile = "mobile number is not valid";
            hasError = true;
        }

        this.setState({
            errors: errors
        });
        console.log(errors);
        if (hasError == true) {
            toast.error("Please correct the issues.");
            return;
        }

        let data = {
            Name: fullName,
            EmailID: email,
            Mobile: mobile,
            Subject: subject,
            Remarks: message,
            EmailType: "CNT",
            ParentServiceCategoryID: -1,
            ServiceCategoryID: -1,
        };
        apiClient.submitServiceRequirment(data).then(res => {
            //console.log(res);
            this.setState({ requirementSubmitted: true });
        }).catch(err => {
            console.log(err);
        })
    }
    getAllCities = () => {
        apiClient.getAllCities().then(res => {
            //console.log(res);
            this.setState({
                cities: res
            });
        }).catch(err => {
            console.log(err);
        });
    }
    componentDidMount() {
        this.getAllCities();

        let userid = localStorage.getItem("userId");
        apiClient.getUserById(userid).then(res => {
            let user = res[0];
            let name = user.FirstName + " " + user.LastName;
            let email = user.EmailAddress;
            let mobile = user.MobileNumber;
            this.setState({ fullName: name, email: email, mobile: mobile, user: res[0] });
        }).catch(err => {
            console.log(err, "Get Error");
        });

    }

    render() {
        const { fullName, email, mobile, subject,
            enqury, region, cities, org,
            message, requirementSubmitted, errors } = this.state;
        return (
            <div>
                { requirementSubmitted && <div className="inner-heading">Thanks for submitting the request <br />We will contact you shortly.</div> }
                { !requirementSubmitted && <div className="contact-form-grid d-grid">

                    <form>
                        <div className="form-grid contact-us-form-container">
                            <div className="left">
                                {/* <label htmlFor="name">Full Name <sup>*</sup></label> */ }
                                <input type="text" id="name" placeholder="Full Name(*)"
                                    value={ fullName }
                                    onChange={ (event) => {
                                        this.setState({ fullName: event.target.value });
                                    } }
                                    required />
                                { errors && errors.fullName && <div className="input-error">{ errors.fullName }</div> }
                            </div>
                            <div className="right">
                                {/* <label htmlFor="email">Email <sup>*</sup></label> */ }
                                <input type="email" id="email" placeholder="Email(*)"
                                    value={ email }
                                    onChange={ (event) => {
                                        this.setState({ email: event.target.value });
                                    } }
                                    required />
                                { errors && errors.email && <div className="input-error">{ errors.email }</div> }
                            </div>
                            <div className="left">
                                {/* <label htmlFor="number">Organization <sup>*</sup></label> */ }
                                <input type="text" id="number" placeholder="Organization"
                                    value={ org }
                                    onChange={ (event) => {
                                        this.setState({ org: event.target.value });
                                    } }
                                    required />
                                { errors && errors.org && <div className="input-error">{ errors.org }</div> }
                            </div>

                            <div className="left">
                                {/* <label htmlFor="number">Contact Number <sup>*</sup></label> */ }
                                <input type="text" id="number" placeholder="Contact Number (*)"
                                    value={ mobile }
                                    onChange={ (event) => {
                                        this.setState({ mobile: event.target.value });
                                    } }
                                    required />
                                { errors && errors.mobile && <div className="input-error">{ errors.mobile }</div> }
                            </div>
                            <div className="right">
                                {/* <label htmlFor="subject">Region <sup>*</sup></label> */ }
                                <select
                                    value={ region }
                                    onChange={ (event) => {
                                        this.setState({ region: event.target.value });
                                    } }
                                    required>
                                    <option value="">City</option>
                                    {
                                        cities && cities.map((item, index) => {
                                            return <option key={ index } value={ item.cityName }>{ item.cityName }</option>
                                        })
                                    }
                                </select>
                                { errors && errors.region && <div className="input-error">{ errors.region }</div> }
                            </div>
                            <div className="right">
                                {/* <label htmlFor="subject">Inqury Type <sup>*</sup></label> */ }
                                <select
                                    value={ enqury }
                                    onChange={ (event) => {
                                        this.setState({ enqury: event.target.value });
                                    } }
                                    required>
                                    <option value="">Inquiry Type</option>
                                    <option value="Request for Services">Request for Services</option>
                                    <option value="Partner Enquiry">Partner Enquiry</option>
                                    <option value="Media">Media</option>
                                    <option value="Career">Career</option>
                                    <option value="Feedback">Feedback</option>
                                    <option value="Other">Other</option>
                                </select>
                                { errors && errors.enqury && <div className="input-error">{ errors.enqury }</div> }
                            </div>
                        </div>
                        <div className="form-textarea">
                            {/* <label htmlFor="message">Your Message <sup>*</sup></label> */ }
                            <textarea id="message"
                                value={ message }
                                onChange={ (event) => {
                                    this.setState({ message: event.target.value });
                                } }
                                placeholder="Write Message"></textarea>
                            { errors && errors.message && <div className="input-error">{ errors.message }</div> }
                        </div>
                        <button type="button" className="theme-button"
                            onClick={ () => {
                                this.addServiceRequirement();
                            } }
                        >Submit <FiChevronRight className="icon" /></button>
                    </form>
                </div> }
            </div>
        )
    }
}
