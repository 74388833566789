import React, { Component } from 'react'
import { FaGoogle, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import * as apiClient from "../../../components/network/apiClient";
import { ToastContainer, toast } from 'react-toastify';

export default class UserLoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoginWithOTP: false,
            mobile: "",
            otp: "",
            otpSent: "",
            message: "",
        };
    }

    getOTP = () => {
        const { mobile } = this.state;


        if (mobile == "") {
            toast.error("Mobile is required");
            this.setState({ message: "Mobile is required" });
            return;
        }

        var mobilePattern = /^[A-Za-z0-9 '.-]+$/;
        if (!mobilePattern.test(mobile)) {
            toast.error("Mobile is not valid");
            this.setState({ message: "Mobile is not valid" });
            return;
        }
        this.setState({ message: "" });
        apiClient.loginWithOTP(mobile).then(res => {
           // console.log(res);
            if (res && res.result == 'success') {
                this.setState({ otpSent: true });
            } else {
                toast.error(res.message);
                this.setState({ message: res.message });
            }
        }).catch(err => {
            console.log(err);
        })
    }
    loginAccount = () => {
        const { mobile, otp, otpSent } = this.state;
        if (!otpSent) return;

        if (mobile == "") {
            toast.error("Mobile is required");
            this.setState({ message: "Mobile is required" });
            return;
        }

        if (otp == "") {
            toast.error("OTP is required");
            this.setState({ message: "OTP is required" });
            return;
        }

        var mobilePattern = /^[A-Za-z0-9 '.-]+$/;
        if (!mobilePattern.test(mobile)) {
            toast.error("Mobile is not valid");
            this.setState({ message: "Mobile is not valid" });
            return;
        }

        this.setState({ message: "" });
        apiClient.verifyOTP(mobile, otp).then(res => {
           // console.log(res);
            if (res && res.result == 'fail') {
                toast.error(res.message);
                this.setState({ message: res.message });
            } else {
                let user = res;
                localStorage.setItem("firstname", user.firstName);
                localStorage.setItem("lastname", user.lastName);
                localStorage.setItem('username', user.UserName);
                localStorage.setItem("userId", user.userId);
                localStorage.setItem("type", user.userType);
                localStorage.setItem("displayName", user.firstName + " " + user.lastName);
                localStorage.setItem("role", user.userType);
                localStorage.setItem("token", res.token);
                //console.log(res);
                this.props.history.push("/");
            }
        }).catch(err => {
            console.log(err);
        })
    }
    render() {
        const { mobile, otp, otpSent, message } = this.state;
        return (
            <section className="signup-form-wrapper login-form-wrapper">
                <div className="container">
                    <div className="signup-form-box">
                        <div className="signup-form-top text-center">
                            <h1 className="signup-form-title">Customer Login!</h1>

                        </div>
                        <form className="signup-form">
                            <input className="form-control" type="text"
                                value={ mobile }
                                onChange={ (event) => {
                                    this.setState({ mobile: event.target.value });
                                } }
                                placeholder="Mobile Number (*)" />
                            { message && message.length > 0 && <div className="login-message">{ message }</div> }
                            { otpSent && <input className="form-control" type="number"
                                value={ otp }
                                onChange={ (event) => {
                                    this.setState({ otp: event.target.value });
                                } }
                                placeholder="OTP (*)" /> }
                            {/* <div className="checkboxes d-flex">
                                <label className="privacy-policy"> Remember Me
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                </label>
                                <Link to="/forgot-password">Forgot my password?</Link>
                            </div> */}
                            <div className="login-action">
                                <button type="button" value="Register Account"
                                    onClick={ () => {
                                        if (!otpSent)
                                            this.getOTP();
                                        else
                                            this.loginAccount();
                                    } }
                                    className="theme-button">{ otpSent ? "Login" : "Continue" }</button>
                                <Link to="/login" className="login-with-otp theme-button">Login With Email</Link>
                            </div>


                        </form>
                        <p className="already-account">
                            Not a member? <Link to="/sign-up">Register</Link>
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}
