import React, { Component } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export default class Accordions extends Component {
    render() {
        return (
            <div className="accordion-wrapper">
                <Accordion allowZeroExpanded={true}>
                <strong><u>FAQ for Clients :</u></strong> 
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                               What value add SERVIZKART brings:
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                               <strong>We don’t deal with FREELANCERS except the Subject Matter Experts. </strong>
                            </p>
                            <p>
                                SERVIZKART is not only a marketplace, it is a solution partner in your success. Apart from the getting the best available service partners, SERVIZKART helps the businesses and individuals in managing the day to day work through various tools, automation and by bringing everything under one roof which includes – competent service partners, automation tools, third party solutions. 
                            </p>
                            <p>
                              <strong>For existing running business </strong>– While performing the day to day work, various ad hoc requirements are being searched and SERVIZKART fits in that area. Apart from this we help in to identify the redundancies and help the businesses to save cost and still work efficiently. You don’t need to worry about the expansion or rapid growth, SERVIZKART is your partner in making you successful.
                            </p>
                             <p>
                                <strong>For new businesses/Startups</strong> – We understand the difficulties being faced by new business setups and start ups. We work as enabler in all business areas and to provide the solutions without much efforts by the business owners. We provide the full back office support from the single window. Our SERVIZKART Buddy works as your SPOC while dealing with multiple services.                            
                            </p>
                            <p>
                            <strong>For Anyone</strong> – Our <strong>INSTANT SERVICE</strong> caters the needs of all the businesses and individuals for their day to day operation or single use work requirement needed almost instantly.
                                </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            How do I share my requirement?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p>We have various ways you can share your work requirement. You can select the <strong>Main Service Category</strong> or its <strong>Sub Service Category</strong> and share addition information either through Email, Live Chat or speak to SERVIZKART Buddy. For any General Query you just drop your query either on email on Live Chat and we will get back to you at the earliest.</p>                         </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            What happens once I share my requirement?                             </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p><strong>For Business Solutions:</strong> Our team may call you to know your detailed requirement, budget and timelines if already not provided. Once all the information is available, it is updated in your requirement.</p>
<p>You can select 2 Service Providers at your own from the available Partners and SERVIZKART provides 1 best fit at its own. Alternatively, you have the option to leave everything on SERVIZKART and sit back and we will provide you the best fit as per your requirement.</p>
<p><strong>Instant Services:</strong> As name suggests, you don’t need to do much, just select the service and features you want, checkout and pay instantly. Our team will take it from here and will ask for any additional documents we may need.</p>
<p><strong>You can track your progress in the state of the art Dashboard in your profile. </strong></p>

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            How the work starts?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p><strong>For Business Solutions:</strong> Once you select your Service Partners or select SERVIZKART as your Solution Partner, the work may be divided into milestone based or in single step according to work quantum. We capture the work estimated value, deliverables and payment terms. All information is available in the Dashboard which is accessible to you and the Service Partner or SERVIZKART. </p>
<p><strong>Instant Services:</strong> When you select and pay, the work starts as per the outlines process and is completed within the set timelines.</p>

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            How the payment is processed? 
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p><strong>For Business Solutions:</strong><br/>
1. Once there is agreement on the milestones and payment schedules, you need to process the payment into SERVIZKART NODAL Bank Account as per the agreed terms.<br/><br/>
2. The payments are processed through secured bank payment gateways having multiple options like credit cards, debit cards, UPI, Net Banking, EMI etc. <br/><br/>
2. 3. Once we receive the funds in our Nodal Bank Account, the partner or SERVIZKART will begin the work. <br/><br/>
4. On successful completion of the milestone and acceptance of the work by you, the funds will be released to the partner from the Nodal Bank Account. <br/><br/>
<strong>For Instant Services:</strong>  It is simple just the way you purchase products online.</p>

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            What if I cancel the Work Assignment?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p>Once the work has been initiated, it can be cancelled only in extreme situation beyond once control. In such cases a cancellation fee is charged as per the work progress and rest of the amount is refunded to you in 15 workdays.
In case of <strong>Instant Services</strong>, cancellation is not possible. </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <strong><u>FAQ for Service Partners :</u></strong> 
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Why should I come on board with SERVIZKART?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p>SERVIZKART is a blended marketplace which works as Solution Provider to the clients through single window. A client may need multiple services and Service Partner has strong case of getting addition business with below benefits:<br/><br/>
1.	ZERO Business Development and Marketing Cost for any business coming through SERVIZKART. <br/>
2.	All collection efforts are with SERVIZKART. It is said that revenue is equivalent to sales collection. <br/>
3.	All Service Partner profiles are visible to clients, it creates the brand awareness. <br/>
4.	In case of Instant Services – you can bid the lead posted on the platform through your SERVIZKART Wallet. <br/>
5.	In nutshell – you get additional business at no extra cost to you. You simply deliver the work in timely manner and as per the scope and leave everything to SERVIZKART to deal with. </p>

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            How can I start working with SERVIZKART?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p>We have a stringent process of selecting our Partners as we provide only verified Service Partners to our clients. Such verification involves various steps, which includes capability assessment by us, KYC, reference check from your existing clients, ratings available on other platform etc. </p>
                        <p>If you want to know more simply connect at partnership@servizkart.com </p>

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Is my payment Guaranteed and Secured? 
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p>All payments are being deposited in neutral Nodal Bank Accounts as per terms supervised by RBI. Nodal Bank Account is being audited by an independent statutory auditors. Once the payment is collected from the Clients, it gets released to the Service Partner after deducting SERVIZKART Platform Fee. <br/><br/>
In case of missing the timelines or not delivering as per the Work Requirement, the work may be allotted to other Service Partner with prior information to you. In such cases a penalty can be levied and payments can be withheld.<br/><br/>
All payments are subject to TDS.</p>

                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            How to get more work from SERVIZKART platform?                            
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p>It depends on the various parameters as listed below:<br/><br/>
1.	Current work assignments in hand and capability to take more. <br/>
2.	Your pricing competitiveness as compared to other Service Providers. <br/>
3.	Feedback and ratings given by the Clients which are based on timely delivery and delivering the work as per the Client Requirement.</p>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            What will happen to our Statutory Obligations?                        
                            </AccordionItemButton>
                         </AccordionItemHeading>
                        <AccordionItemPanel>
                        <p>All statutory obligations like GST, labour compliances and other applicable regulations will be your responsibility. Depending on the Clients requirement and solution provided by SERVIZKART, you will be responsible for the invoicing either to the client or to SERVIZKART and depositing the GST collected on such invoices.<br/><br/> 
You will work with SERVIZKART as independent contractor. Please refer the Terms for Service Partners for more detail. .<br/><br/>
Connect with us at partnership@servizkart.com to know more.</p>

                        </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>
            </div>
        )
    }
}
