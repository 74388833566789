import React, { Component } from 'react'
import SectionsTitle from '../../components/common/SectionsTitle';
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import img1 from '../../assets/images/team-1.jpg'

export default class IndustryDetail extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
       
    }
    render() {
        const { industries } = this.props;
        return (
            <section className="team-wrapper">
                <div className="container">
                    <div className="text-center">
                        <SectionsTitle title="Industries supported" subtitle="Industries supported" />
                    </div>
                    <div className="team-grid text-center">
                        {industries.length > 0 && industries.map((item, index) => {
                            return (
                                <div className="team">
                                    <div className="team-img">
                                        <img src={item.image} alt="Team" className="industry-image" />
                                    </div>
                                    <div className="team-content">
                                        <h2 className="name">{item.name}</h2>
                                        <p className="designation">{item.details}</p>
                                    </div>
                                </div>
                            )
                        })}
                      
                    </div>
                </div>
            </section>
        )
    }
}
