import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { default as img1, default as img2, default as img3 } from '../../assets/images/about-img4.jpg';
// import moredetails from '../../assets/images/moredetails.jpg';
// import pricepackage from '../../assets/images/pricepackages.jpg';
// import serviceproviders from '../../assets/images/serviceproviders.jpg';
// import sharerequirement from '../../assets/images/sharerequirement.jpg';
// import videochat from '../../assets/images/videochat.jpg';
import SectionsTitle from '../../components/common/SectionsTitle';
import * as apiClient from "../../components/network/apiClient";

import customer_focused from '../../assets/images/customer_focused.png';
import data_driven from '../../assets/images/data_driven.png';
import innovate_for_succeed from '../../assets/images/innovate_for_succeed.png';
import high_integrity from '../../assets/images/high_integrity.png';
import result_oriented from '../../assets/images/result_oriented.png';
import safe_payment from '../../assets/images/safe_payment.png';


export default class Aboutus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buddyProgramContent: "<ul class='values-list'><li>Verified Service Partners</li><li>Save Time & Individual Efforts On Search</li><li>Best Quotes Gauranteed</li>Smooth Contracting Experience</li><li>Real-Time Conversations & Feedbacks</li><li>Safe Payment Policy - Gauranteed!</li></ul>",
            buddyProgramImage: img1,
            requestCallback: "<ul class='values-list'><li>Expand Your Business In A Cost-Effective Manner.</li><li>Work On Hot Leads From The Best Of The Industries</li><li>Save Lead Generation Cost, Time & Efforts</li><li>Real-Time Assistance</li><li>Verified Businesses Only</li></ul>",
            requestCallbackImage: img2,
            shareRequirement: "<ul class='values-list'><li>One-Click Search Saves Time</li><li>Best Price Gauranteed</li><li>User-Friendly Experience</li><li>Quick Turnaround Time</li><li>Verified Service Partners</li><li>Verified Businesses Only</li></ul>",
            shareRequirementImage: img3,
        };
    }
    tabstate = {
        tabs: [
            {
                img: img1,
                desc: "ServizKart is a blended serviz© marketplace which is a unique value creation in Professional Servizes offerings.<br/> ServizKart works as a solution partner in completely transparent and flexible way. We have following options available and clients can choose the best suited options:<br/>                1.	ServizKart Buddy: Once the option is selected, highly skilled ServizKart Buddy is assigned to interact with the prospective client and will identify the best available Serviz Provider through consortium or from the marketplace. <br/>                               This option comes with a unique feature of protection of money refund and guaranteed time bound delivery.      <br/>                          2.	Search directly various professional servize providers in our serviz marketplace where all serviz providers are selected through our rigorous selection process and they carry the ServizKart Verified Tag.   <br/>                             3.	At any point of time if a client needs the assistance, the client can opt for ServizKart Buddy option.",
                button: "Get Started"
            },
            {
                img: img2,
                desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
                button: "Get Started",
                img_position: "img_right"
            },
            {
                img: img3,
                desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
                button: "Get Started"
            }
        ]
    }

    tabsnav = {
        navs: [
            {
                title: "Our Values"
            },
            {
                title: "Mission"
            },
            {
                title: "Vision"
            }
        ]
    }
    getBuddyProgramImages = () => {
        apiClient.getContentPageConfig("BuddyProgramImages").then(res => {
            //console.log(res);
            if (res.length > 0) {
                //this.setState({ buddyProgramContent: res[0].propertyName, buddyProgramImage: res[0].imageUrl });
                this.setState({ buddyProgramImage: res[0].imageUrl });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    getRequestCallBackImages = () => {
        apiClient.getContentPageConfig("RequestCallBackImages").then(res => {
            //console.log(res);
            if (res.length > 0) {
                //this.setState({ requestCallback: res[0].propertyName, requestCallbackImage: res[0].imageUrl });
                this.setState({ requestCallbackImage: res[0].imageUrl });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    getShareTheRequirementsImages = () => {
        apiClient.getContentPageConfig("ShareTheRequirementsImages").then(res => {
            //console.log(res);
            if (res.length > 0) {
                // this.setState({ shareRequirement: res[0].propertyName, shareRequirementImage: res[0].imageUrl });
                this.setState({ shareRequirementImage: res[0].imageUrl });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    componentDidMount() {
        this.getBuddyProgramImages();
        this.getRequestCallBackImages();
        this.getShareTheRequirementsImages();
    }
    render() {
        const {
            buddyProgramContent, buddyProgramImage,
            requestCallback, requestCallbackImage,
            shareRequirement, shareRequirementImage
        } = this.state;
        return (
            <section className="tabs-wrapper">
                <div className="row text-center">
                    <div className="col-12">
                        <SectionsTitle title="Who we are" />
                    </div>
                </div>
                <div className="tabs-content-wrap home-about-us">
                    <Tabs>
                        <TabList className="tabs-nav">
                            { this.tabsnav.navs.map((item, index) => {
                                return <Tab key={ index }>{ item.title }</Tab>
                            }) }
                        </TabList>

                        <TabPanel className="tabs-content" key="0">
                            <div className={ 'tabs-content-flex ' }>
                                {/* <div className="tabs-left">
                                        <img className="home-whychoose-image" src={ buddyProgramImage } alt="Tabs" />
                                    </div> */}
                                <div className="tabs-full">
                                    <div className="about-values">
                                        <div className="value">
                                            <div className="sbicon"><img src={ customer_focused } className="icon" /></div>
                                            <div className="details">
                                                <div className="title">Customer Focused</div>
                                                <div className="description">We are proactive in our approach to provide easy solution to the client. We include our customers in all our derivatives and diligently work towards client-satisfaction.</div>
                                            </div>


                                        </div>
                                        <div className="value">
                                            <div className="sbicon"><img src={ high_integrity } className="icon" /></div>
                                            <div className="details">
                                                <div className="title">High Integrity</div>
                                                <div className="description">We are transparent in our approach and remain truthful and honest in all areas of operations.</div>
                                            </div>
                                        </div>
                                        <div className="value">
                                            <div className="sbicon"><img src={ data_driven } className="icon" /></div>
                                            <div className="details">
                                                <div className="title">Data Driven</div>
                                                <div className="description">We move quickly and deliberately, using data to guide our decisions and testing multiple factors each day to achieve breakthroughs in the process of delivering the best to our clients</div>
                                            </div>

                                        </div>
                                        <div className="value">
                                            <div className="sbicon"><img src={ result_oriented } className="icon" /></div>
                                            <div className="details">
                                                <div className="title">Result Oriented</div>
                                                <div className="description">We try our best to remain cost-conscious in all our activities yet continually improve on what we do to serve our customers. SERVIZKART aims to exceed client expectations, aggressively pursue market share, growth and industry leadership.</div>
                                            </div>
                                        </div>

                                        <div className="value">
                                            <div className="sbicon"><img src={ innovate_for_succeed } className="icon" /></div>
                                            <div className="details">
                                                <div className="title">Innovate To Succeed</div>
                                                <div className="description">We continue to innovate to make the entire process smooth and user-friendly.</div>
                                            </div>
                                        </div>
                                        <div className="value">
                                            <div className="sbicon"><img src={ safe_payment } className="icon" /></div>
                                            <div className="details">
                                                <div className="title">Safe Payment Policy - Gauranteed!</div>
                                                <div className="description">ServizKart understands the various issues faced by businesses while looking for functional service providers.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="tabs-content" key="1">
                            <div className={ 'tabs-content-flex img_right' }>
                                <div className="tabs-full">
                                    <div className="vision">
                                        <p className="service-items-description">SERVIZKART aims to become the biggest outsourcing platform in India. </p>
                                        <p className="service-items-description">As a brand, we aim to make SERVIZKART a name synonym for business outsourcing. We aim to make business outsourcing the new normal. We aim to make the process of business outsourcing simple yet effective and efficient in a cost-effective manner.</p>
                                        <p className="service-items-description">We at SERVIZKART aim to make business outsourcing available to all business models whether big or small, across nations, industries, and functions.</p>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel className="tabs-content" key="2">
                            <div className={ 'tabs-content-flex ' }>
                                <div className="tabs-full">
                                    <div className="mission">
                                        <p className="service-items-description">SERVIZKART aims to bridge the gap between leading business houses and talented vendors. </p>
                                        <p className="service-items-description">We at SERVIZKART aim to connect more and more verified vendors with leading business houses across India as well as globally. We aim to be indispensable among major business networks of start-ups, mid & small scale industries, and large scale industries.</p>
                                        <p className="service-items-description">SERVIZKART aims to create a loyal client and vendor base by delivering high-quality, cost-effective and value-added services to our clients changing the face of outsourcing and making it an essential part of business excellence.</p>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div >
            </section >
        )
    }
}
