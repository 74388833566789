import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { FiPhone, FiMapPin, FiChevronDown, FiChevronRight } from 'react-icons/fi'
import { FaEnvelope, FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'
import flat1 from '../../assets/images/flag.jpg'
import flat2 from '../../assets/images/flag2.jpg'
import flat3 from '../../assets/images/flag3.jpg'
import * as apiClient from "../network/apiClient";

export default class HeaderBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: "+918368265052",
            phoneHref: "tel:+918368265052",
            email: "stellularventures@gmail.com",
            emailHref: "mailto:stellularventures@gmail.com",
            officeLocation: "Guragon",
            name: "",
            isLoggedIn: false,
        };
    }
    getHelpLinePhoneConfig = () => {
        apiClient.getContentPageConfig("HelpLineNumber").then(res => {
            //console.log(res);
            if (res.length > 0) {
                this.setState({ phone: res[0].propertyName, phoneHref: "tel:" + res[0].propertyName });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    getHelpLineEmailConfig = () => {
        apiClient.getContentPageConfig("HelpLineEmail").then(res => {
            //console.log(res);
            if (res.length > 0) {
                this.setState({ email: res[0].propertyName, emailHref: "mailto:" + res[0].propertyName });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    getHelpLineLocationConfig = () => {
        apiClient.getContentPageConfig("HelpLineLocation").then(res => {
            //console.log(res);
            if (res.length > 0) {
                this.setState({ officeLocation: res[0].propertyName });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    onLogout = () => {
        localStorage.removeItem("firstname");
        localStorage.removeItem("lastname");
        localStorage.removeItem('username');
        localStorage.removeItem("userId");
        localStorage.removeItem("type");
        localStorage.removeItem("displayName");
        localStorage.removeItem("role");
        localStorage.removeItem("token");
        localStorage.removeItem("accountid");
        this.props.history.push("/login");
    }
    componentDidMount() {
        //this.getHelpLinePhoneConfig();
        //this.getHelpLineEmailConfig();
        //this.getHelpLineLocationConfig();
        const n = localStorage.getItem("firstname");
        const token = localStorage.getItem("token");
        if (token && token != "") {
            this.setState({
                name: n,
                isLoggedIn: true,
            });
        }
    }
    render() {

        const { name, isLoggedIn, phone, phoneHref, email, emailHref, officeLocation } = this.state;
        return (
            <div className="header-bar-wrapper">
                <div className="container">
                    <div className="header-bar-grid">
                        <div className="header-bar-left">
                            {/* <ul className="d-flex">
                                <li className="d-flex"><FiPhone className="icon" /> <a href={phoneHref}>{phone}</a></li>
                                <li className="d-flex"><FaEnvelope className="icon" /> <a href={emailHref}>{email}</a></li>
                                <li className="d-flex"><FiMapPin className="icon" />{officeLocation}</li>
                            </ul> */}
                        </div>
                        <div className="header-bar-right text-right">
                            <div className="header-bar-right-flex d-flex">
                                {/* <ul className="header-bar-social d-flex">
                                    <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                    <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                    <li><a href="https://youtube.com"><FaYoutube /></a></li>
                                </ul> */}
                                {/* <ul className="header-bar-language">
                                    <li>
                                        <a href="/" className="d-flex"><img src={flat1} alt="flag" /> English <FiChevronDown className="icon" /></a>
                                        <ul>
                                            <li><a href="/" className="d-flex"><img src={flat1} alt="flag" /> English</a></li>
                                            <li><a href="/" className="d-flex"><img src={flat2} alt="flag" /> USA</a></li>
                                            <li><a href="/" className="d-flex"><img src={flat3} alt="flag" /> France</a></li>
                                        </ul>
                                    </li>
                                </ul> */}
                                { isLoggedIn && <div className="header-bar-cta-btn header-userinfo">
                                    Welcome: { name }
                                </div> }
                                {/* {isLoggedIn && <div className="header-bar-cta-btn">
                                    <Link to="/profile" className="theme-button login-button ">Profile</Link>
                                </div>} */}
                                { isLoggedIn && <div className="header-bar-my-account-menu">
                                    <button className="myaccount theme-button">My Account</button>
                                    <div className="my-account-menu">
                                    <Link to="/dashboard" className="my-account-menu-item profile-link">Dashboard</Link>
                                        <Link to="/profile" className="my-account-menu-item profile-link">Profile</Link>
                                        <span
                                            onClick={ () => {
                                                this.onLogout();
                                            } }
                                            className="my-account-menu-item user-logout">Logout</span>
                                    </div>
                                </div> }
                                {/* {isLoggedIn && <div className="header-bar-cta-btn header-userinfo">
                                    <span
                                        onClick={() => {
                                            this.onLogout();
                                        }}
                                        className="user-logout">Logout</span>
                                </div>} */}
                                {/* { !isLoggedIn && <div className="header-partner-auth-links">
                                    <div className="link-parent">
                                        <div className="link-caption">Existing Partner</div>
                                        <Link to="http://admin.servizkart.com" className="sign-in">Sign In</Link>
                                    </div>
                                    <div className="link-parent">
                                        <div className="link-caption">Not a Servizkart Partner</div>
                                        <Link to="/service-provider-sign-up" className="sign-in">Sign up</Link>
                                    </div>
                                </div> } */}
                                { !isLoggedIn && <div className="header-bar-cta-btn">
                                    <Link to="/service-provider-auth" className="theme-button register-provider">Register as Partner <FiChevronRight className="icon" /></Link>
                                </div> }
                                { !isLoggedIn && <div className="header-bar-cta-btn">
                                    <Link to="/login" className="theme-button login-button ">Login/SignUp <FiChevronRight className="icon" /></Link>
                                </div> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
