import React, { Component } from 'react'
import { FiTarget, FiUserCheck } from 'react-icons/fi'
import { TiLightbulb } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import business from '../../assets/images/business.png'
import handshake from '../../assets/images/handshake.jpg'
import member1 from '../../assets/images/member1.png'
import vision from '../../assets/images/vision.jpg'
import Aboutus from "../../pages/blocks/about_us"
import amrendra from '../../assets/images/amrendra.jpg';
import "./team.css";

export default class TeamMembersRow extends Component {
    constructor() {
        super()
        this.state = {
            isMemberOpen: false,
            memberName: "",
            designation: "",
            description: "",
        }

    }
    displayMember = (memberName, designation, description) => {
        this.setState({
            isMemberOpen: true,
            memberName: memberName,
            designation: designation,
            description: description
        });
    }
    closeMember = () => {
        this.setState({
            isMemberOpen: false
        });
    }
    render() {
        const { isMemberOpen, memberName, designation, description } = this.state;
        const { members } = this.props;
        return (

            <div class="item-list">
                <ul class="leader-item-list">
                    { members && members.map((item, index) => {
                        return (
                            <li key={ index } class="leader-item" onClick={ () => {
                                this.displayMember(
                                    item.name,
                                    item.designation,
                                    item.details
                                );
                            } }>
                                <article data-history-node-id="86" role="article" class="leader info-box-item">
                                    <div class="field_picture">
                                        <img src={ item.image } width="210" height="210" alt="Venkat Rangapuram" typeof="foaf:Image" />
                                    </div>
                                    <div class="info-card-data">
                                        <h3><span class="title">{ item.name }</span>
                                        </h3>
                                        <div class="field_role">{ item.designation }</div>
                                        <div class="info-box-toggle"></div>
                                    </div>
                                </article>
                            </li>
                        )
                    }) }

                    {/* <li class="leader-item" onClick={ () => {
                        this.displayMember(
                            "Shivakumar Ganesan (Shivku)",
                            "Chief Information Officer",
                            "Shivku, a computer science graduate from BITS, Pilani, started his career at Yahoo! where he worked on Jobs and Maps. After being a (Yahoo!) superstar there, he left to start Roopit, a C2C marketplace that eventually pivoted to Exotel."
                        );
                    } }>
                        <article data-history-node-id="311" role="article" about="/index.php/node/311" class="leader info-box-item">
                            <div class="field_picture">
                                <img src={ member1 } width="210" height="210" alt="Jose Martinez" typeof="foaf:Image" />
                            </div>
                            <div class="info-card-data">
                                <h3><span class="title">Shivakumar Ganesan (Shivku)</span>
                                </h3>
                                <div class="field_role">Chief Information Officer</div>
                                <a href="#" class="info-box-toggle"></a>
                            </div>
                        </article>
                    </li> */}
                    { isMemberOpen && <li class="info-box-content-wrapper">
                        <div href="#" class="close" onClick={ () => { this.closeMember(); } }></div>
                        <div class="wrapper">
                            <div class="basic-info">
                                <div><span class="title">{ memberName }</span>
                                </div>

                                <div class="field_role">{ designation }</div>

                            </div>
                            <div class="divider"></div>
                            <div class="description">
                                <div class="body" dangerouslySetInnerHTML={ { __html: description } }></div>
                            </div>
                        </div>
                    </li> }
                </ul>
            </div>
        )
    }
}


