import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import CareerDetails from '../components/contact/CareerDetails'
import Footer from './blocks/Footer'
import { Link } from "react-router-dom";
import * as apiClient from "../components/network/apiClient";
import { ToastContainer, toast } from 'react-toastify';

export default class ProfilePage extends Component {
    constructor(props) {
        super(props);
       // console.log(props);
        this.state = {
            user: {},
            states: [],
            cities: [],
            businessSizes: [],
            businessStatus: "",
            otherDesignation: "",
            otherBusinessStatus: "",
            isOtherDesignationSelected: false,
            isOtherBusinessStatusSelected: false,
        };
    }
    setUserData = (field, value) => {
        const { user } = this.state;
        user[field] = value;
        this.setState({
            user: user
        });
    }

    registerAccount = () => {
        const { user } = this.state;

        if (user.FirstName == "") {
            toast.error("Name is required");
            return;
        }

        if (user.EmailAddress == "") {
            toast.error("Email address is required");
            return;
        }
        if (user.MobileNumber == "") {
            toast.error("Mobile number is required");
            return;
        }

        if (user.AccountName == "") {
            toast.error("company is required");
            return;
        }

        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(user.EmailAddress)) {
            toast.error("Email address is not valid");
            return;
        }

        const pattern = /^[0-9 '.-]+$/;
        if (!pattern.test(user.MobileNumber)) {
            toast.error("Only numbers are allowed in mobile");
            return;
        }

        if (user.MobileNumber.length < 10) {
            toast.error("mobile number is not valid");
            return;
        }


        if (user.MobileNumber.length < 10) {
            toast.error("mobile number is not valid");
            return;
        }

        apiClient.updateBuyerProfile(user).then(res => {
            //console.log(res);
            toast("Profile updated successfully");

        }).catch(err => {
            console.log(err);
        })
    }
    getAllStates = () => {
        apiClient.getAllStates(133).then(res => {
            this.setState({
                states: res
            });
        }).catch(err => {
            console.log(err);
        });
    }
    getAllCitiesForState = (stateId) => {
        apiClient.getAllCitiesForState(stateId).then(res => {
            this.setState({
                cities: res
            });
        }).catch(err => {
            console.log(err);
        })
    }
    componentDidMount() {
        this.getAllStates();
        let userid = localStorage.getItem("userId");
        apiClient.getUserById(userid).then(res => {
            //console.log(res, "GetUser");
            this.setState({ user: res[0] });

        }).catch(err => {
            console.log(err, "Get Error");
        });
    }
    render() {
        const { user, states, cities, businessSizes, isOtherDesignationSelected, isOtherBusinessStatusSelected, businessStatus, otherDesignation, otherBusinessStatus,
            firstName, lastName, email, mobile, subject, company,
            designation, password, confirmPassword, requirementSubmitted } = this.state;
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Profile" />
                <section className="profile-wrapper">
                    <div className="container">
                        <section className="signup-form-wrapper">
                            <div className="container">

                                {<div className="signup-form-box">
                                    <div className="signup-form-top text-center">
                                        <h1 className="signup-form-title">My Account</h1>
                                        <h5>View and edit your personal info below..</h5>
                                    </div>

                                    <form className="signup-form form-container">
                                        <div className="form-controls-container">
                                            <input className="form-control" type="text"
                                                value={user.FirstName}
                                                onChange={(event) => {
                                                    this.setUserData("FirstName", event.target.value);
                                                }}
                                                required
                                                placeholder="First Name (*)" />

                                            <input className="form-control" type="text"
                                                value={user.LastName}
                                                onChange={(event) => {
                                                    this.setUserData("LastName", event.target.value);
                                                }}
                                                required
                                                placeholder="Last Name" />

                                            <input className="form-control" type="text"
                                                value={user.MobileNumber}
                                                onChange={(event) => {
                                                    this.setUserData("MobileNumber", event.target.value);
                                                }}
                                                placeholder="Mobile (*)" />

                                            <input className="form-control" type="email"
                                                value={user.EmailAddress}
                                                onChange={(event) => {
                                                    this.setUserData("EmailAddress", event.target.value);
                                                }}
                                                placeholder="Business Email Address (*)" />

                                            <select className="form-control"
                                                value={user.BusinessStatus}
                                                onChange={(e) => {
                                                    this.setUserData("BusinessStatus", e.target.value);
                                                    if (e.target.value == "Other")
                                                        this.setState({ isOtherBusinessStatusSelected: true });
                                                    else
                                                        this.setState({ isOtherBusinessStatusSelected: false });
                                                }}>
                                                <option value="">Select Business Status</option>
                                                <option value="Pvt Ltd Company">Pvt Ltd Company</option>
                                                <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                                                <option value="Partnership Firm">Partnership Firm</option>
                                                <option value="Proprietorship">Proprietorship</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <input className="form-control" type="text"
                                                value={otherBusinessStatus}
                                                disabled={(isOtherBusinessStatusSelected) ? false : true}
                                                onChange={(event) => {
                                                    this.setState({ otherBusinessStatus: event.target.value });
                                                }}
                                                placeholder="Enter Business Status (Other)" />

                                            <select className="form-control"
                                                value={user.Designation}
                                                onChange={(e) => {
                                                    this.setUserData("Designation", e.target.value);
                                                    if (e.target.value == "Other")
                                                        this.setState({ isOtherDesignationSelected: true });
                                                    else
                                                        this.setState({ isOtherDesignationSelected: false });
                                                }}
                                            >
                                                <option value="">Select Designation</option>
                                                <option value="Director">Director</option>
                                                <option value="Partner">Partner</option>
                                                <option value="Proprietor">Proprietor</option>
                                                <option value="IT Head">IT Head</option>
                                                <option value="Other">Other</option>
                                            </select>

                                            <input className="form-control" type="text"
                                                value={otherDesignation}
                                                disabled={(isOtherDesignationSelected) ? false : true}
                                                onChange={(event) => {
                                                    this.setState({ otherDesignation: event.target.value });
                                                }}
                                                placeholder="Enter your Designation (Other)" />

                                            <input className="form-control " type="text"
                                                value={user.AccountName}
                                                onChange={(event) => {
                                                    this.setUserData("AccountName", event.target.value);
                                                }}
                                                placeholder="Enter your Company" />

                                            <input className="form-control " type="text"
                                                value={user.GSTNO}
                                                onChange={(event) => {
                                                    this.setUserData("GSTNO", event.target.value);
                                                }}
                                                placeholder="GST Number" />


                                            <input className="form-control " type="text"
                                                value={user.ADHARNO}
                                                onChange={(event) => {
                                                    this.setUserData("ADHARNO", event.target.value);
                                                }}
                                                placeholder="UAM No. if any" />

                                            <select className="form-control"
                                                value={user.BusinessSizeID}
                                                onChange={(e) => {
                                                    this.setUserData("BusinessSizeID", e.target.value);
                                                }}
                                            >
                                                <option value="">Select Company Size (Employees)</option>
                                                <option value="Director"> 1-50 </option>
                                                <option value="Partner"> 51-100</option>
                                                <option value="Proprietor"> 201-500</option>
                                                <option value="IT Head"> > 500</option>
                                            </select>

                                            <h6 className="work-address full-width-control" >Work Address</h6>

                                            <input className="form-control " type="text"
                                                value={user.Address1}
                                                onChange={(event) => {
                                                    this.setUserData("Address1", event.target.value);
                                                }}
                                                placeholder="Address line 1" />

                                            <input className="form-control " type="text"
                                                value={user.Address2}
                                                onChange={(event) => {
                                                    this.setUserData("Address2", event.target.value);
                                                }}
                                                placeholder="Address line 2" />

                                            <select className="form-control"
                                                value={user.StateID}
                                                onChange={(e) => {
                                                    this.setUserData("StateID", e.target.value);
                                                    this.getAllCitiesForState(e.target.value);
                                                }}
                                            >
                                                <option value="">Select State</option>
                                                {states.map((item, index) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.stateName}</option>
                                                    )
                                                })}
                                            </select>
                                            <select className="form-control"
                                                value={user.CityID}
                                                onChange={(e) => {
                                                    this.setUserData("CityID", e.target.value);
                                                }}
                                            >
                                                <option value="">Select City</option>
                                                {cities.map((item, index) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.cityName}</option>
                                                    )
                                                })}
                                            </select>

                                            <input className="form-control " type="text"
                                                value={user.ZipCode}
                                                onChange={(event) => {
                                                    this.setUserData("ZipCode", event.target.value);
                                                }}
                                                placeholder="Pincode" />
                                            <div className="form-control no-border"></div>

                                        </div>

                                        <div className="register-note">Note: All fields marked (*) are required.</div>

                                        <button type="button" value="Register Account" className="theme-button"
                                            onClick={() => {
                                                this.registerAccount();
                                            }}
                                        >Update Profile</button>
                                    </form>
                                </div>}
                            </div>
                        </section>
                    </div>
                </section>
                {/* <Footer /> */}
            </>
        )
    }
}
