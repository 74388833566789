import React, { Component } from 'react'
import { FaGoogle, FaFacebookF, FaTwitter } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import * as apiClient from "../../../components/network/apiClient";
import { ToastContainer, toast } from 'react-toastify';

export default class UserLoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            requirementSubmitted: false,
            isLoginWithOTP: false,
            mobile: "",
            otp: "",
            otpSent: "",
            messsage:"",
        };
    }
    loginAccount = () => {
        const { email, password } = this.state;


        if (email == "") {
            toast.error("Email address is required");
            return;
        }

        if (password == "") {
            toast.error("password is required");
            return;
        }
        if (password.length < 5) {
            toast.error("Password must be atleast 5 characters.");
            return;
        }


        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(email)) {
            toast.error("Email address is not valid");
            return;
        }

        let data = {
            username: email,
            password: password,
            usertype: "3",
        };
        apiClient.loginUser(data).then(res => {
           // console.log(res);
            if (res.data && res.data.result == true) {
                let user = res.data;
                localStorage.setItem("firstname", user.name);
                localStorage.setItem("lastname", "");
                localStorage.setItem('username', user.name);
                localStorage.setItem("userId", user.userId);
                localStorage.setItem("type", user.userType);
                localStorage.setItem("displayName", user.name);
                localStorage.setItem("role", user.userType);
                localStorage.setItem("token", res.token);
                localStorage.setItem("accountid", user.accountid);
                //console.log(res);
                this.props.history.push("/");
            } else {
                this.setState({messsage: res[0].message});
                toast.error(res[0].message);
            }
        }).catch(err => {
            console.log(err);
        })
    }
    render() {
        const { email, password, messsage} = this.state;
        return (
            <section className="signup-form-wrapper login-form-wrapper">
                <div className="container">
                    <div className="signup-form-box">
                        <div className="signup-form-top text-center">
                            <h1 className="signup-form-title">Customer Login!</h1>
                            {/* <p>with your social network.</p> */ }
                        </div>
                        {/* <div className="signup-form-signin d-flex">
                            <button className="theme-button google" type="submit">
                                Google <FaGoogle className="icon" />
                            </button>
                            <button className="theme-button facebook" type="submit">
                                Facebook <FaFacebookF className="icon" />
                            </button>
                            <button className="theme-button twitter" type="submit">
                                Twitter <FaTwitter className="icon" />
                            </button>
                        </div> */}
                        {/* <div className="connector text-center">
                            <span>or</span>
                        </div> */}
                        <form className="signup-form">
                            <input className="form-control" type="text"
                                value={ email }
                                onChange={ (event) => {
                                    this.setState({ email: event.target.value });
                                } }
                                placeholder="Username, or email (*)" />

                            <input className="form-control" type="password"
                                value={ password }
                                onChange={ (event) => {
                                    this.setState({ password: event.target.value });
                                } }
                                placeholder="Password (*)" />
                            <div className="checkboxes d-flex">
                                <label className="privacy-policy"> Remember Me
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                </label>
                                <Link to="/forgot-password">Forgot my password?</Link>
                            </div>
                            <div className="login-action">
                                <button type="button" value="Register Account"
                                    onClick={ () => {
                                        this.loginAccount();
                                    } }
                                    className="theme-button">Login Now <FiChevronRight className="icon" /></button>
                                <Link to="/loginotp" className="login-with-otp theme-button">Login With OTP</Link>
                            </div>
                            { messsage && <div className="register-message">{ messsage }</div> }
                        </form>
                        <p className="already-account">
                            Not a member? <Link to="/sign-up">Register</Link>
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}
