import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { FiTarget, FiMonitor } from "react-icons/fi";
import { MdAccountBalance } from "react-icons/md";
import { AiOutlineDollar, AiOutlineFileSearch } from "react-icons/ai";
import { IoIosBuild } from "react-icons/io";
import * as apiClient from "../network/apiClient";
import slider1 from '../../assets/images/img12.jpg';
import OwlCarousel from 'react-owl-carousel';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import $ from 'jquery';


export default class Service extends Component {
    constructor(props) {
        super(props);
       // console.log(props);
        this.state = {
            services: []
        };
    }
    getServiceCategory = () => {
        apiClient.getServiceCategoryForHomepage(20).then(res => {
            //console.log(res);
            let items = res.map(item => {
                return {
                    icon: item.logo,
                    title: item.serviceName,
                    description: item.serviceDetails,
                    id: item.id
                }
            });
            //console.log(items);
            this.setState({ services: items });
        }).catch(err => {
            console.log("Error : " + err);
        });
    }

    movetoServiceDetail = (item) => {
        //localStorage.setItem("service-category", JSON.stringify(item));
        this.props.history.push("/service-details?category=" + item.id);
        $('html, body').animate({ scrollTop: 0 }, 1000);
    }

    componentDidMount() {
        this.getServiceCategory();
    }

    render() {
        const { services } = this.state;
        return (
            <section className="services-area">
                <div className="container">

                    <div className="services-items-slider">
                        {services.length > 0 && <OwlCarousel className="owl-theme service-content-details-slider" loop={true}
                            autoplay={true} margin={10} dots={false} nav={true} items={5}
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                600: {
                                    items: 1,
                                },
                                1000: {
                                    items: 5,
                                },
                            }}
                            slideBy={1}>
                            {services.map((item, index) => {
                                return <div key={index} className="item">
                                    <img src={item.icon} onClick={() => {
                                        this.movetoServiceDetail(item);
                                    }}
                                        className="service-category-logo" alt="Service Details Slider" />
                                    <div className="service-slider-details">
                                        <div className="service-items-title">{item.title}</div>
                                        {/* <div className="slider-category-description">Category description</div> */}
                                    </div>

                                </div>
                            })}
                        </OwlCarousel>}
                    </div>
                    {/* 
                    <div className="services-items-grid">
                        {services && services.map((item, index) => {
                            return <div className="service-items" key={index}>
                                <span className="service-items-num">{index}</span>
                                <img src={item.icon} className="service-items-icon-image" />
                                <Link to="/service-details">
                                    <h3 className="service-items-title">{item.title}</h3>
                                </Link>
                                <p className="service-items-description">{item.description}</p>
                            </div>
                        })
                        }
                    </div> */}
                </div>
            </section>
        )
    }
}

