import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiDownload } from 'react-icons/fi'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import * as apiClient from "../../../components/network/apiClient";

export default class ServiceProviders extends Component {
    constructor(props) {
        super(props);
        let category = props.category;
       // console.log(category);
        this.state = {
            userList: [],
            category: category
        };
    }
    getServiceProviders = () => {
        apiClient.getServiceProvidersForCategory(this.props.category.id).then(res => {
          //  console.log(res);
            this.setState({ userList: res });
        }).catch(err => {
            console.log(err);
        })
    }
    componentDidMount() {
        this.getServiceProviders();
    }
    render() {
        const { userList } = this.state;
        return (
            <>
                <div className="service-providers-list">
                    <div className="list">
                        {userList && userList.map((item, index) => {
                            return (
                                <div className="list-item">
                                    <div className="provider-details-top">
                                        <div className="provider-image-parent">
                                            {item.LogoUrl == null && <img src="https://servizkart.blob.core.windows.net/servizkartdocs/scdclogo.png" className="service-provider-image" />}
                                            {item.LogoUrl != null && <img src={item.LogoUrl} className="service-provider-image" />}
                                        </div>
                                        <div className="service-provider-detail">
                                            <div className="provider-name">{item.AccountName}</div>
                                            <div className="provider-details">{item.ProfileDetails}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        {/* <div className="list-item">
                            <div className="provider-details-top">
                                <div className="provider-image-parent">
                                    <img src="https://servizkart.blob.core.windows.net/servizkartdocs/scdclogo.png" className="service-provider-image" />
                                </div>
                                <div className="service-provider-detail">
                                    <div className="provider-name">Smartcloud Development Consultant</div>
                                    <div className="provider-details">Sample details for the provider...</div>
                                </div>
                            </div>
                        </div>

                        <div className="list-item">
                            <div className="provider-details-top">
                                <div className="provider-image-parent">
                                    <img src="https://servizkart.blob.core.windows.net/servizkartdocs/goldenred-logo.png" className="service-provider-image" />
                                </div>
                                <div className="service-provider-detail">
                                    <div className="provider-name">Goldenred Consultant</div>
                                    <div className="provider-details">Sample details for the provider...</div>
                                </div>
                            </div>
                        </div>

                        <div className="list-item">
                            <div className="provider-details-top">
                                <div className="provider-image-parent">
                                    <img src="https://servizkart.blob.core.windows.net/servizkartdocs/scdclogo.png" className="service-provider-image" />
                                </div>
                                <div className="service-provider-detail">
                                    <div className="provider-name">Smartcloud Development Consultant</div>
                                    <div className="provider-details">Sample details for the provider...</div>
                                </div>
                            </div>
                        </div>

                        <div className="list-item">
                            <div className="provider-details-top">
                                <div className="provider-image-parent">
                                    <img src="https://servizkart.blob.core.windows.net/servizkartdocs/goldenred-logo.png" className="service-provider-image" />
                                </div>
                                <div className="service-provider-detail">
                                    <div className="provider-name">Goldenred Consultant</div>
                                    <div className="provider-details">Sample details for the provider...</div>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </>
        )
    }
}
