import React, { Component } from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';

import AdvisoryMemberDetail from '../components/team/advisory-member-detail';
import Divider from '../components/other/Divider'
import CtaForm from '../components/other/CtaForm';
import Footer from './blocks/Footer';
import * as apiClient from "../components/network/apiClient";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default class AdvisoryPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
            isPopupDisplayed: false,
            selectedMember: null,
        };
    }

    onPartnerClick = (item) => {
        //console.log(item);
        this.setState({
            isPopupDisplayed: true,
            selectedMember: item
        });
    }
    popupToggle = () => {
        const { isPopupDisplayed } = this.state;
        let c = !isPopupDisplayed;
        this.setState({ isPopupDisplayed: c });
    }

    getAdvisoryPanel = () => {
        apiClient.getAdvisoryPanel().then(res => {
            //console.log(res);
            this.setState({ members: res });
        }).catch(err => {
            console.log("Error : " + err);
        });
    }

    componentDidMount() {
        this.getAdvisoryPanel();
    }
    render() {
        const { members, selectedMember, isPopupDisplayed } = this.state;
        return (
            <div className="teamgrid-page">
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Advisory Panel" />
                <div className="member-parent">
                    <AdvisoryMemberDetail members={ members } onClick={ this.onPartnerClick } />
                </div>
                <Footer />
                <Modal isOpen={ isPopupDisplayed } toggle={ this.popupToggle } className="my-model-popup">
                    <ModalHeader toggle={ this.popupToggle }>
                        More details
                    </ModalHeader>
                    <ModalBody>
                        { selectedMember && <div>
                            <div className="team-details-content">
                                <h1 className="name">{ selectedMember.name }</h1>
                                <p className="desc" dangerouslySetInnerHTML={ { __html: selectedMember.description } }></p>
                                {/* <div className="team-expertise-education-grid d-grid">
                                    <div className="left">
                                        <h2>Industries Served</h2>
                                        <ul className="team-details-expertise lists" dangerouslySetInnerHTML={ { __html: selectedMember.areaofExpertise } }>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <h2>Functional Areas</h2>
                                        <ul className="team-details-education lists" dangerouslySetInnerHTML={ { __html: selectedMember.education } }>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            {/* <h3>A Little Description About Me.</h3>
                            <div dangerouslySetInnerHTML={ { __html: selectedMember.moredescription } }></div> */}
                        </div> }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.popupToggle }>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
