import React, { Component } from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';

import IndustryDetail from './blocks/industry-detail';
import Divider from '../components/other/Divider'
import CtaForm from '../components/other/CtaForm';
import Footer from './blocks/Footer';
import * as apiClient from "../components/network/apiClient";


export default class Industry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            industries: [],
        };
    }

    getIndustries = () => {
        apiClient.getIndustries().then(res => {
            //console.log(res);
            this.setState({ industries: res });
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    componentDidMount() {
        this.getIndustries();
    }
    render() {
        const { industries } = this.state;
        return (
            <div className="teamgrid-page">
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Industries" />
                <IndustryDetail industries={industries} />
                <Footer />
            </div>
        )
    }
}
