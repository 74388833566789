import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import { Link } from 'react-router-dom'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import CtaForm from '../components/other/CtaForm'
import Footer from './blocks/Footer';
import Partner from "./partners/partner";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as apiClient from "../components/network/apiClient";

export default class PreferredPartners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            isPopupDisplayed: false,
            selectedPartner: null,
        };
    }
    onPartnerClick = (item) => {
        this.setState({
            isPopupDisplayed: true,
            selectedPartner: item
        });
    }
    popupToggle = () => {
        const { isPopupDisplayed } = this.state;
        let c = !isPopupDisplayed;
        this.setState({ isPopupDisplayed: c });
    }
    componentDidMount() {
        apiClient.getPreferredPartners().then(res => {
           // console.log(res);
            this.setState({
                partners: res,
            });
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        const { partners, isPopupDisplayed, selectedPartner } = this.state;
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Preferred partners" />
                <div className="page-intro">
                    <p>SERVIZKART Preferred Partners are handpicked <b>featured service providers</b> of their respective area of expertise. These SERVIZKART Partners have their set up for decades and able to deliver the solutions at faster speed. SERVIZKART is proud of getting associated with these Service Providers as its Partner.</p>
                    <p>We have thousands of other Service Providers associates as our Partners. </p>
                    <p>Check their professional profile. Reach out to us to contact with any of the Partner </p>
                </div>
                <section className="blog-wrapper blog-grid-page">
                    <div className="container">
                        <div className="blog-grid">
                            { partners.map((item, index) => {
                                return <Partner key={ index } partner={ item } onClick={ this.onPartnerClick } />;
                            }) }
                        </div>
                        {/* <ul className="pagination d-flex">
                            <li><Link className='page-link' to="/blog-full-width"><FiChevronsLeft /></Link></li>
                            <li><Link className='page-link' to="/blog-full-width">1</Link></li>
                            <li><span className="page-link current">2</span></li>
                            <li><Link className='page-link' to="/blog-full-width">3</Link></li>
                            <li><Link className='page-link' to="/blog-full-width"><FiChevronsRight /></Link></li>
                        </ul> */}
                    </div>
                </section>
                {/* <div className="form-white-bg">
                    <CtaForm />
                </div> */}
                <Footer />
                <Modal isOpen={ isPopupDisplayed } toggle={ this.popupToggle } className="my-model-popup">
                    <ModalHeader toggle={ this.popupToggle }>
                        { selectedPartner && selectedPartner.name }
                    </ModalHeader>
                    <ModalBody>
                        { selectedPartner && <div className="partner-parent">
                            <div className="partner-initial">
                                <img src={ selectedPartner.image } />
                                <div className="details">
                                    <div className="name">{ selectedPartner.name } </div>
                                    <div className="segment">{ selectedPartner.skillsets } </div>
                                    <div className="offices">{ selectedPartner.ofiices } </div>
                                </div>
                            </div>
                            {/* <div dangerouslySetInnerHTML={ { __html: selectedPartner.description } }></div>  */}
                            <div className="more-description" dangerouslySetInnerHTML={ { __html: selectedPartner.moreDescription } }></div> 
                            <div className="clientele">Services Offered</div>
                            <div className="services-offered">{selectedPartner.serviceOffered}</div>
                            <div className="clientele">Clientele</div>
                            <img src={selectedPartner.clientLogo} className="clientele"/>
                        </div> }

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.popupToggle }>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
