import React, { Component } from 'react'
import img1 from '../../assets/images/img1.jpg'
import { FiMapPin, FiPhone, FiChevronRight } from 'react-icons/fi'
import { FaEnvelope } from 'react-icons/fa'

export default class ContactSidebar extends Component {
    render() {
        return (
            <aside className="contact-sidebar">
                <div className="contact-sidebar-infos">
                    <div className="item d-flex">
                        <div className="icon">
                            <span><FiMapPin /></span>
                        </div>
                        <div className="details">
                            <h3>Address.</h3>
                            <span>Office No. 4, Golf Course Road</span>
                            <span>Opposite HDFC Bank, Sector 54,</span>
                            <span>Gurgaon -122002, Haryana, India</span>
                        </div>
                    </div>

                    {/* <div className="item d-flex">
                        <div className="icon">
                            <span><FiPhone /></span>
                        </div>
                        <div className="details">
                            <h3>Phone.</h3>
                            <span>+91-8368265052</span>
                        </div>
                    </div> */}

                    <div className="item d-flex">
                        <div className="icon">
                            <span><FaEnvelope /></span>
                        </div>
                        <div className="details">
                            <h3>Email.</h3>
                            <span>connect@servizkart.com</span>
                        </div>
                    </div>
                </div>

                {/* <button className="theme-button" type="submit">Contact With Us <FiChevronRight className="icon" /></button> */ }
            </aside>
        )
    }
}
