import React, { Component } from 'react';
import Navbar from '../components/common/Navbar';
import BreadcrumbSmall from '../components/common/BreadcrumbSmall';
//import ContactDetails from '../components/contact/ContactDetails';
import ContactForm from '../components/contact/ContactForm';
import Footer from './blocks/Footer';
import contact from "../assets/images/contact.png";
import location from "../assets/images/location.png";
import map from "../assets/images/map.png";
import { FaFacebookF, FaGooglePlusG, FaInstagram, FaLinkedinIn, FaTwitter, FaMailBulk, FaYoutube } from 'react-icons/fa';
import SectionsTitle from '../components/common/SectionsTitle';
//import Maps from '../components/contact/Maps';
import ContactSidebar from '../components/contact/ContactSidebar';
import { FiChevronRight } from 'react-icons/fi';
import * as apiClient from "../components/network/apiClient";

export default class ContactPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fbLink: "",
            twLink: "",
            instaLink: "",
            linkedinLink: "",
            youtubeLink: "",
            directPhoneNumber: "+918368265052",
            directEmail: "info@servizkart.com/ help@servizkart.com",
        };
    }

    getFooterLinks = async () => {
        let fb = await apiClient.getContentPageConfig("FacebookLink");
        let tw = await apiClient.getContentPageConfig("TwitterLink");
        let insta = await apiClient.getContentPageConfig("InstagramLink");
        let linkiedin = await apiClient.getContentPageConfig("LinkedinLink");
        let youtube = await apiClient.getContentPageConfig("YoutubeLink");
        let phone = await apiClient.getContentPageConfig("DirectPhoneNumbers");
        let email = await apiClient.getContentPageConfig("DirectEmails");

        this.setState({
            fbLink: fb[0].propertyName,
            twLink: tw[0].propertyName,
            instaLink: insta[0].propertyName,
            linkedinLink: linkiedin[0].propertyName,
            youtubeLink: youtube[0].propertyName,
            directPhoneNumber: phone[0].propertyName,
            directEmail: email[0].propertyName,
        });
    }

    componentDidMount() {
        this.getFooterLinks();
    }
    render() {
        const { aboutUs, fbLink, twLink, instaLink, linkedinLink, youtubeLink, directPhoneNumber, directEmail } = this.state;
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <BreadcrumbSmall title="Contact Us" />
                <div className="page-banner">
                    <img src={ contact } className="page-banner-image" />
                    <div className="contact-form-link">
                        <a href="#contactform" className="theme-button contact-our-team-btn">
                            Contact Our Team
                        </a>
                    </div>
                </div>
                <section className="contact-form-wrapper">
                    <div className="container">
                        { <div className="contact-form-gri contact-form-main-container">
                            <div className="left">
                                <SectionsTitle title="CONNECT WITH US" subtitle="" />
                                <div className="sidebar-social-profile">
                                    <ul className="contact-form-socials d-flex">
                                        <li><a target="_blank" href="mailto:connect@servizkart.com"><FaMailBulk /></a></li>
                                        <li><a target="_blank" href={ fbLink }><FaFacebookF /></a></li>
                                        <li><a target="_blank" href={ twLink }><FaTwitter /></a></li>
                                        <li><a target="_blank" href={ youtubeLink }><FaYoutube /></a></li>
                                        <li><a target="_blank" href={ linkedinLink }><FaLinkedinIn /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right">
                                <div className="contact-us-details">
                                    We at SERVIZKART, take pride in serving our clients, providing business solutions through our Verified Partner Network.
                                </div>

                                <div className="contact-us-details">
                                    If you are searching for a competent service provider for your corporate and other needs, your search end here.
                                </div>

                                <ul className="contact-us-bullet">
                                    <li>
                                        Follow us on Social Media. Join the conversation on <a href={ fbLink }>Facebook</a>, <a href={ linkedinLink }>LinkedIn</a>, <a href={ youtubeLink }>Youtube</a> or <a href={ twLink }>Twitter</a>.
                                    </li>
                                    <li>
                                        Explore the Latest News. Learn how SERVIZKART is helping businesses to provide solutions for their corporate needed, elevates experiences and supports a sustainable world
                                    </li>
                                    <li>
                                        <a href="/faq">FAQ</a>
                                    </li>
                                </ul>
                                <a href="#contactform" className="theme-button contact-our-team-btn">
                                    Contact Our Team
                                </a>
                                {/* <ContactDetails /> */ }
                            </div>
                        </div> }
                        <SectionsTitle title="OUR LOCATION" subtitle="" />
                        <div className="contact-form-map-container contact-form-map-grid d-grid">
                            <div className="map-image-container left">
                                <div className="map-image-parent">
                                    <img src={ location } className="map-image" />
                                </div>

                                <a href="https://goo.gl/maps/QctP7SETEsFLRizR7" target="_blank" className="get-directions">Get Directions <FiChevronRight className="icon" /></a>
                            </div>
                            <div className="right">
                                <ContactSidebar />
                            </div>
                        </div>
                        <div id="contactform"></div>
                        <div className="contact-form-container">
                            <SectionsTitle title="TAKE THE FIRST STEP" subtitle="" />
                            <div className="contact-form-subheading subheading">
                                Serving customers by adding values and solving problems through our technology based search of Verified Partners for various functions.
                            </div>
                            <div className="contact-form-subheading subheading">
                                <strong>Let’s talk how SERVIZKART can help in finding solutions for your business.</strong>
                            </div>
                        </div>
                        <ContactForm />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}
