import React from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FiPlus, FiChevronRight } from 'react-icons/fi'
import { FaRegComment } from 'react-icons/fa'



export default function Partner(props) {
    const { name, image,serviceOffered,skillsets, contactFirstName, contactLastName, contactEmail, description, emailAddress, webSite } = props.partner;

    return (
        <>
            <div className="blog preffered-partners">
                <div className="blog-img-box">
                    <img src={image} alt="Blog" />
                    {/* <div className="blog-overlay">
                    <Link to="/blog-details" ><FiPlus className="icon" /></Link>
                </div> */}
                </div>
                <div className="blog-content-box" onClick={() => {
                        props.onClick(props.partner);
                    }}>
                    <h2 className="blog-title">
                        {name}
                    </h2>
                    <h2 className="blog-desc">
                        {skillsets}
                    </h2>
                    
                    {/* <div className="partner-item">
                    <span className="blog-meta-name">Area of Expertise : </span>
                    <span className="blog-meta-value">{contactFirstName} {contactLastName}</span>
                </div>
                <div className="partner-item">
                    <span className="blog-meta-name">Industry Served : </span>
                    <span className="blog-meta-value">{contactEmail}</span>
                </div>
                <div className="partner-item">
                    <span className="blog-meta-name">Brand Served : </span>
                    <span className="blog-meta-value">{emailAddress}</span>
                </div>
                <div className="partner-item">
                    <span className="blog-meta-name">Servicing since : </span>
                    <span className="blog-meta-value">{emailAddress}</span>
                </div>
                <div className="partner-item">
                    <span className="blog-meta-name">Status : </span>
                    <span className="blog-meta-value">{emailAddress}</span>
                </div> */}
                </div>
            </div>
        </>

    )
}

