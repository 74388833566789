import React, { Component } from 'react'
import Navbar from "../../../components/common/Navbar";
import BreadcrumbSmall from '../../../components/common/BreadcrumbSmall';
import UserLoginForm from './LoginForm';
import Footer from '../../blocks/Footer';
import login from "../../../assets/images/login.jpg";

export default class UserLoginPage extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <BreadcrumbSmall title="Login" />
                <div className="login-page-banner page-banner">
                    <img src={ login } className="page-banner-image" />
                </div>
                <UserLoginForm { ...this.props } />
                {/* <Footer /> */ }
            </>
        )
    }
}
