import React, { Component } from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import Pricing from '../components/other/Pricing';
import CtaForm from '../components/other/CtaForm';
import Footer from './blocks/Footer';

export default class TermsOfUsePartner extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="TERMS OF USE FOR PARTNERS" />
                <div className="container plain-pricing-table">
                    <div className="privacy-policy-container">
                        <p><strong>TERMS OF USE FOR PARTNERS</strong></p>
                        <p>1. <u><strong>Agreement</strong></u>:</p>
                        <p>Following are the terms of use (to be called as Terms hereinafter):</p>
                        <p> This is a legal agreement between<strong>SERVIZKART, a brand of Stellular Ventures Private Limited., </strong>a
                            Company having its principal workplace at,<strong>H 1106, Maple Heights, Sushant Lok 1, Gurgaon - 122009,
                                India</strong>(to be referred to as "Company" or we or similar hereinafter) of the first part AND
                            the<strong>Partner</strong>(defined below) of the second part (hereinafter collectively referred to as you, yours or
                            Partner). The Terms shall be governing the use of and access to www.servizkart.com (the Website) and the
                            SERVIZKART technology platform accessible through desktops, mobile phones, smartphones, and tablets (the
                            Application) which offers a B2B marketplace for outsourcing digital services connecting Client(s) (defined
                            below) with Partners and offering various Services (defined below) as may be introduced by the Company from time
                            to time. The Company is the owner of the Website and Application offered to the Partner(s). The Website and the
                            Application and all promotional, marketing, and other activities associated therewith shall collectively be
                            referred to as SERVIZKART Platform (Platform hereinafter). Please carefully go through these Terms and the
                            privacy policy available at www.servizkart.com (Privacy Policy) before you decide to access or use the Platform
                            made available by the Company. These Terms and the Privacy Policy together constitute a legal agreement
                            (Agreement) between you and the Company in connection with your access and use of the Platform. By clicking
                            "sign up" or the 'I accept' tab at the time of registration, or by entering into an agreement with the Company
                            to offer Services as set out in these Terms, or through the continued use or accessing the Platform, You agree
                            to be subject to these Terms. We request You to please read these Terms carefully and do not click Create an
                            account, sign up or I accept or continue the use of the Platform unless You totally agree with these Terms.</p>
                        <p>2. <u><strong>Definitions:</strong></u></p>
                        <p> The following terms shall have the meaning as given below:</p>

                        <ol type='a'>
                            <li><strong>Bid</strong> means the proposal that the Partner submits in response to the Project posted by the Client
                                on the Platform. Every Bid submitted by the Partner shall be routed only through the Platform.</li>
                            <li><strong>Client</strong> means anyone who signs up and registers as a customer by creating an Account through the
                                Platform and who posts Projects/Work.</li>

                            <li>"<strong>Client Information</strong>" means information regarding Clients which includes information such as
                                name and address, legal status, nature of business, company information, details of the Project, etc.</li>

                            <li>"<strong>Confidential Information</strong> means all information and materials furnished by a party which:
                            </li>

                            <li>If in written format is marked as confidential, or</li>
                            <li>If disclosed verbally is noted as confidential at the time of disclosure, or</li>
                            <li>In the absence of either (a) or (b) is information which a reasonable party would deem to be non-public
                                information and confidential.</li>
                        </ol>

                        <p>Confidential Information includes, but not limited to the Client Information; such as details about the Project, such
                            as specific requirements, specifications, description of services mentioned in the Project; all types of data,
                            information, ideas, processes, procedures, software specifications, technical processes, product designs, financial
                            information, business plans, projections, marketing data and other similar information provided by one party to the
                            other; documentation, the existence and contents of this Agreement or agreements between SERVIZKART, Client and
                            Partner, whether such is transmitted in writing, orally, visually, or on magnetic media, and includes all
                            proprietary information, customer and prospect lists, trade secrets, or proposed trade names, know-how, concepts,
                            drawings, flow charts, diagrams and other intellectual property relating to the subject matter of this Agreement.
                        </p>
                        <ol>
                            <li> <strong>Effective Date</strong> means the date on which You accept these Terms by clicking Log in, 'Sign Up' or
                                'I Accept' or entering into an agreement with the Company</li>
                            <li><strong>Nodal Account</strong> means bank account maintained by SERVIZKART to hold the payments payable by the
                                Client to the Partner for their Services in a Project, and from which account the Partner shall be paid for the
                                Service upon completion of the Project or as mutually agreed upon by the Client and Partner.</li>

                            <li><strong>Project</strong> means the assignment for which the Client wishes to engage the Services offered on the
                                Platform through the Partners who have registered therein and which may include details such as project type,
                                category, and sub-category, duration, project value, key deliverables, milestones & payment details, location
                                (and whether the project can be completed remotely), the scope of work, a project description, which is provided
                                by a Client to the Partners so that they can Bid for the Project/Work.</li>

                            <li><strong>Service</strong> means services offered on the Platform through Partners who specialize in relevant
                                fields required for the Projects/Works posted by the Clients such as digital services, hiring developers, mobile
                                and web development, software development, IT services, BPOs, KPOs, and such other services as the Company may
                                offer from time to time.</li>

                            <li><strong>Partner</strong> could include a complete agency itself that specializes in offering services for which
                                the Project is posted or duly qualified and experienced professionals working in that agency depending upon the
                                nature and scope of the Project. The Client could either hire an agency or resources from the agency depending
                                upon the requirements of the Project/Work.</li>
                            <li><strong>Partner Information</strong> means information relating to name, address, documents such as
                                certificates/licenses/registrations to verify the legal status, expertise in a specific field, copies of
                                certificates/degrees/qualification/certifications in the area of specialization and expertise, professional
                                memberships and such other information/documents that the Company may require to be submitted from time and time
                                or as the case may be, etc.</li>

                            <li><strong>Project/Work Agreement</strong> means an Agreement entered into by the Partner and Client before the
                                Partner starts to offer Services to the Client and which may be either provided by the Company or furnished by
                                the Client or Partner as per the situation.</li>

                            <li>All other capitalized terms will bear the meaning ascribed to them in the Other Terms.</li>
                        </ol>

                        <p>3. <u><strong>Registration as Partner:</strong></u></p>
                        <ol>
                            <li>In order to access and use the features available on the Platform offered by the Company and for offering the
                                Services it is mandatory for you to accept the Terms and then create an account ("Account") by registering. You
                                represent, warrant, and covenant that you will provide accurate and complete registration information
                                (including, but not limited to your name, agency name, the user name ("User Name"), corporate e-mail address,
                                phone number, and a password you will use to access the Service) and such other Partner Information as may be
                                required by the Company from time to time and to keep your registration information accurate and up-to-date.
                                Please note that failure to do so shall constitute a breach of the Terms, which may result in immediate
                                termination of your Account.</li>

                            <li>In order to be able to register yourself as a Partner through the Website and/or downloading the Application,
                                You represent and warrant to the Company that:</li>

                            <li>You are major and have the authority to enter into this Agreement;</li>
                            <li>This Agreement is binding and enforceable against you;</li>
                            <li>If you are using the Website and/or Application on behalf of any other person/s (including individuals) or
                                entity such as the agency (including bodies corporate and others), you further represent and warrant that you
                                are authorized to accept these Terms on such entity's/person's behalf, and that such entity agrees to indemnify
                                the Company for violation of these Terms by you;</li>
                            <li>You have adequate qualifications and experience and shall provide all the Services professionally and to the
                                best of your skills and shall not indulge in any unethical, immoral, or illegal activities;</li>
                            <li>You may be required to provide your complete name, age proof, identity proof, details of your bank account,
                                Permanent Account Number (PAN), GSTIN, residence/office proof, details of your educational qualification and
                                upload professional degrees in the field you are qualified in, years of experience along with experience
                                certificates, etc. And other Partner Information as the Company may require from time to time and</li>
                            <li>Most importantly that all the information provided at the time of registration is true and verified.</li>
                        </ol>

                        <p><u><strong>4. The RFQ (Request for Quotation), Bid, and Project/Work Agreement</strong></u></p>
                        <ol>
                            <li>The Client will post an RFQ on the Platform providing required details of the Project.</li>


                            <li>The Company will use its unique algorithm to match the requirements of the Client as mentioned in the Project
                                with the skill sets and services mentioned by the Partners and request them to offer their Bid.</li>

                            <li>The Partners will then submit their Bid including their technical qualifications, their commercial proposal, and
                                submit the same with the Company. </li>
                            <li>The Company will then use the algorithm to process the Bids based on several variables and then provide the
                                Client with the most suitable Bids to choose from.</li>

                            <li>The client will then select the Partner they want to engage for the Project and initiate discussion and may
                                enter into a Project Agreement with the Partner.</li>
                        </ol>

                        <p><u><strong>5. Registration Fees, Project/Work Fees, Commission, Cancellation, and Refund:</strong></u></p>
                        <ol>
                            <li><strong>Registration Fees:</strong>The Company, at this stage, shall provide you access to register on the
                                Platform free of cost. The Company, however, reserves the right to charge you a certain fee at a later date for
                                registering on the Website and/or Application when you sign up and register as a Partner or for continued use of
                                the Platform (Registration Fees). The Registration Fees payable may be fixed or may vary depending upon the
                                plans that may be introduced by the Company.</li>

                            <li><strong>Project/Work Fees:</strong>If You win a Bid and/or sign a Project/Work Agreement with the Client and
                                mutually agree upon the terms of engagement such as milestones and delivery dates, the payment terms, etc. The
                                said terms shall be uploaded on the T Platform by You. You shall then raise an Invoice (inclusive of GST) for
                                the milestones periodically as agreed upon and approved by the Client. You shall upload the Invoice on the
                                Platform. The Client shall then pay the required amount for the Services you render either in total or as per
                                the amount and timeline decided for each milestone (Project/Work Fees) by transferring the Project Fees to the
                                Companys Nodal Account. The Project/Work Fees shall be paid to you once the Project or the milestone has been
                                completed by you and approved by the Client. The Client shall have Fourteen days to approve the request for
                                Project/Work Fees. If the Client approves the payment of the Project/Work Fees then the payment will be released
                                from the Companys Nodal Account to the Partners account within the next two working days. If the Client doesnt
                                approve the request within Fourteen days then on the 15<sup>t</sup><sup>h</sup>day the Project/Work Fees will
                                get released from the Companys Nodal Account to the Partners bank account after deducting Commission payable to
                                the Company or any other charges as mutually agreed. If the Client disputes the payment of the Project/Work Fees
                                then the same will not be released and will be subject to the dispute resolution process adopted by the Partner
                                and the Client.</li>

                            <li><strong>Lead Bid: </strong>For certain kind of services to be delivered, the Company will auction the verified
                                lead to it Partners based on the algorithm and Partners can bid to buy such verified leads. The Partners need to
                                have Credit Points in the SERVIZKART Wallet. Such Credit Points can be purchased from the Platform. The Company
                                will roll out such scheme and will inform the service partner on the registered email.</li>

                            <li><strong>Commission:</strong>The Company shall transfer the Project/Work Fees to You from the Companys Nodal
                                Account after deducting a certain sum payable to the Company as commission for enabling you to secure the
                                Project through use of the Platform (Commission). The details of the Commission to be paid are provided in
                                Partner Dashboard based on Service Categories and may be revised from time to time. The Company shall also raise
                                an invoice (inclusive of GST) for the Commission amount before deducting the same. The Project/Work Fees after
                                deduction of Commission shall be transferred from the Nodal Account to the Your account only when the milestone
                                is achieved, and the Client approves the invoice raised by You for release of payments after each milestone is
                                completed.</li>
                            If, due to factors such as the value and complexity of work, the Client does not agree to transfer the Project/Work
                            Fees to the Companys Nodal Account, the Commission payable to the Company shall still be due from the Partner for
                            the entire period for which the Partner is offering Services to the Client from the commencement of the Project/Work
                            if the same continues for such a period. In such a case, upon receiving confirmation from the Client of payment made
                            to you from the Client, the Company shall raise an invoice for Commission from You inclusive of GST. You shall
                            release the total Commission due to the Company for the Project within twenty days of the invoice being raised. In
                            the case of long-term Projects/Work where the Client provides you with the monthly Consideration, then You shall pay
                            the Commission monthly as well.

                            <li><strong>Cancellation:</strong>Once a Project/Work has commenced and the Client has paid the Project/Work Fees in
                                entirety or part thereof, the Project/Work cannot be cancelled. In exceptional scenarios, where the Client is
                                requesting for a cancellation/refund after having confirmed the Partner to proceed with the Project/Work and
                                having paid the Project/Work Fees then the Company may at its discretion and upon alignment with the Partner
                                choose to refund the Project/Work Fees paid by the Client after deducting 5% of the Project Fees as cancellation
                                fees. The cancellation fees which may be collected are paid to the Partner. The Project/Work can be cancelled if
                                the Project/Work Fees has not been paid and the work on the Project has not commenced.</li>
                            If the Partner requests for cancellation, then the money is refunded to the Client without any deductions and the
                            Company shall also attempt to provide the Client with a replacement Partner. The Company may take action if they
                            believe that the Partner is cancelling too many Projects/Work or cancelling the Project(s) without justified
                            reasons.<br />
                            I, If the Client is not satisfied with your Service as the same was unsatisfactory, deficient, incomplete or the
                            Project/Work was delayed due to your negligence then the Company has the discretion to cancel your account and/or
                            refund the Consideration amount to the Client and levy a penalty upon you for loss of business which shall not be
                            less than the Commission that the Company would have secured on successful completion of the Project/Work.
                        </ol>
                        <p><u><strong>6. Obligations of the Partner</strong></u></p>
                        <ol>
                            <li>You agree that the Information provided by you regarding your credentials and your legal status, qualifications,
                                experience, area of specialization, etc. are true and verified. As part of the registration process and at any
                                time thereafter, you may be required to provide us with various information such as your photo Id, your
                                qualifications, and registration details with the concerned authorities, and other information to prove that You
                                are qualified to offer the Services. We may verify such information or may ask You for additional information.
                                We may also make inquiries from third parties to verify the authenticity of Your information. You authorize us
                                to make such inquiries from such third parties, and You agree to hold them and us harmless from any claim or
                                liability arising from the request for or disclosure of such information.</li>
                            <li>You agree and undertake that you will Bid for Projects posted on the Platform by Clients only if the Project is
                                within your field of expertise. You shall send your Bid for the Project and quote your rates and timeline for
                                completing the Project.</li>
                            <li>You agree and undertake that You are responsible for all risks associated with submitting your Bids and
                                undertaking the Projects.</li>

                            <li>You agree that on winning the Bid when a Project/Work would be allotted to you, you shall complete the
                                Project/Work as per the timelines mentioned in the Bid or Project/Work Agreement.</li>

                            <li>You undertake that you will not bypass the Company to avoid payment of any Commission to the Company by:</li>

                            <li>Sharing your phone numbers, email addresses, or any other contact information with the Client without the prior
                                approval of the Company,</li>
                            <li>Offering a catalog or a link to a third-party website from which any Client may obtain the Service directly;
                            </li>
                            <li>Offering the opportunity to the Client through the Company Website and/or Application to take the Service or any
                                other service outside of the Company;</li>
                            <li>Use your profile page or user name to promote services not offered on or prohibited services through the Website
                                and/or Application. In case the Company realizes that You are involved in any of the above activities, Company
                                holds the sole discretion to blacklist You, terminate your account, withhold any outstanding credits or payments
                                to you and charge a penalty. The Company has the right to initiate appropriate legal actions to address such a
                                breach and has the right to claim damages including a penalty amount depending upon the losses caused to the
                                Company as a result of the bypass.</li>
                            <li>All communications with the Client including the Bid, details of the Project/work, Project/Work Agreement, the
                                progress of the Assignment, and any other communication pertaining to the Project/Work or Services being offered
                                shall be communicated through the Website and/or Application.</li>

                            <li>You agree that you will update the progress status of the Project/Work at each stage on the Platform to
                                facilitate the monitoring of the progress of the Project/Work by Client and the Company and that you shall be
                                diligent and professional in your conduct and communication with the Clients.</li>

                            <li>You are solely responsible for ensuring that Your use of the Website and/or Application complies with all the
                                applicable laws and is always in accordance with these agreed Terms.</li>

                            <li>You will keep your information updated and will inform us immediately should any portion of your information be
                                revoked, is cancelled, or expired.</li>

                            <li>You agree that We may terminate your access to or use of the Website and/or Application at any time if We are
                                unable at any time to determine or verify the Partner Information provided by you at the time of or
                                post-registration. We reserve the right to carry out re-verification of the Partner Information provided by You
                                as and when required, and the above rights and commitments will extend to re-verification as well.</li>
                        </ol>

                        <p><u><strong>7. The role of the Company:</strong></u></p>

                        <ol type='a'>
                            <li>Our role is that of an 'intermediary' as defined under the Information Technology Act, 2000 and the rules
                                thereunder. Being an intermediary, we merely provide a marketplace to connect Partners with Clients and thus
                                have no responsibility and/or liability in respect of the content and transactions being conducted on the
                                Platform including any interactions amongst and between the Partners and Clients.</li>
                            <li>The Company may help the Client in selecting the best Bids submitted by Partners concerning the posting of a
                                Project/Work by the Client and selecting the most suitable partner for the Project/Work, but such help does not
                                create any liability on the Company as to the matters incidental thereto in relation to the Services provided by
                                the Partner and payments made by the parties. Company is not responsible for the dealings between Client and
                                Partner including but not limited to losses arising out of non-delivery, poor quality of delivery, partial
                                delivery, excess delivery or late delivery of agreed deliverable, partial payment, deductions on payment,
                                delayed payment, non-payment, withdrawal of Project/Work or, change of Project/Work unless Company has provided
                                any additional monetary protection stated otherwise. </li>
                            <li>The company does not direct, has no control over, makes no representations; and does not guarantee the quality,
                                safety, or credibility of the Client, the truth or accuracy of Projects/Works submitted by the Client, the
                                ability of Clients to pay for the Services, etc. The company is not responsible if the Client decides not to
                                continue a Project/Work post signing the Project Agreement or transferring the Project/Work Fees.</li>
                            <li>The company is not a party to the dealings between Clients and Partners, including Bids, Projects/Works,
                                pitches, performance of Partners, etc. Clients and Partners are independent contractors. The company is not
                                responsible for and disclaims any and all liability related to the actions of Clients and Partners.</li>
                            <li><u>The company is not liable for the Partner's statutory compliance or obligations such as payment of GST, TDS,
                                etc.</u></li>
                        </ol>

                        <p>8<u><strong>. Grant of Rights:</strong></u></p>
                        <ol>
                            <li>This Website and/or Application is owned and operated by the Company. All the content featured or displayed on
                                this Website and/or Application, including, but not limited to, text, graphics, data, images(photographic and
                                moving), workflow, algorithms, illustrations, pitches, client lists, a summary of proposals, other information
                                incidental thereto and selection and arrangement thereof (the "SERVIZKART Content" excluding the Client
                                Information and Partner Information), is owned by the Company</li>
                            <li>The Company owns all rights, title, and interest, including all intellectual property rights such as copyright,
                                trademarks, trade secrets, patents and other proprietary rights in and to the Website, the SERVIZKART Content,
                                and the Application, the present or future modifications/upgradations thereof and standard enhancements thereto.
                            </li>

                            <li>The Company subject to the terms of the Agreement, grants You and You accept a non-exclusive, personal,
                                non-transferable, limited right to have access to and use the Website and/or Application, SERVIZKART Content,
                                and Services offered therein for the duration you are registered with Us as a Partner.</li>

                            <li>This license is non-transferable and does not permit any resale or commercial use of this Platform or SERVIZKART
                                Contents except as permitted under the Terms; any downloading or copying of account information for the benefit
                                of anyone other than Your use; or any use of data mining, robots, or similar data gathering and extraction
                                tools.</li>

                            <li>The Platform or any portion thereof (including but not limited to any copyrighted material, trademarks, or other
                                proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed, or
                                otherwise exploited for any commercial purpose without express written consent of the Company. Except as
                                expressly permitted by these Terms, any, exploitation, copying, making derivative works, transmitting, posting,
                                linking, deep linking, redistribution, sale, de-compilation, translation, or disassembly of the Platform
                                embodied in the Website and/or Application is strictly prohibited. Any unauthorized use of the Platform shall
                                terminate the permission or revoke the license granted by the Company.</li>
                            <li>The Terms do not and shall not transfer any ownership or proprietary interest in the Website and/or Application,
                                SERVIZKART Content from the Company to You, except as may be otherwise expressly provided in these Terms or as
                                may be agreed to by and between Company and You.</li>

                            <li>The Company hereby states that the Client Information provided on the Website and/or Application is proprietary
                                to and owned by the Clients and is provided to you under license by the Client solely to submit your Bid and/or
                                render your Services.</li>

                            <li>You are solely responsible for your conduct and any Partner Information that you submit, post, or display on or
                                via the Platform. We shall have no liability for your conduct in relation to your use of the Platform. Breach of
                                these Terms may result in legal consequences prescribed under applicable laws.</li>
                            <li>We provide you the facility to create, share and post content i.e. Partner Information and claim no ownership
                                rights over the Partner Information created by you. We take no responsibility and assume no liability for any
                                Partner Information that you or any other Partner creates, stores, shares, posts, or sends through the Platform.
                                You shall be solely responsible for the Partner Information you post/enter/publish/share and you agree that we
                                are only acting as a passive conduit for your online distribution and publication of your Partner Information.
                            </li>
                            <li>You agree that You are the owner of all rights, including all Intellectual Property Rights, title, and interest
                                in and to the Partner Information that You post, share, publish on the Platform.</li>

                            <li>You hereby grant Us and Clients a perpetual, non-revocable, worldwide, royalty-free license to make use of the
                                Partner Information including the right to copy, distribute, display, reproduce, modify, adapt, the Partner
                                Information, and create derivate works of as the case may be.</li>

                            <li>, You affirm, represent, and warrant that the Partner Information shared by you will not violate any law or
                                infringe any rights of any third party, including but not limited to any Intellectual Property Rights or privacy
                                rights.</li>
                        </ol>

                        <p><u><strong>9. Use of the Website and/or Application:</strong></u></p>
                        <ol>
                            <li>You may use the Platform to create your account and access the various features and offer the Services available
                                therein.</li>

                            <li>You may use the Platform for receiving notifications and updates regarding Projects which Clients post.</li>

                            <li>You may use the Platform to submit Bids in response to Projects posted by the Client.</li>

                            <li>Once you win the Bid then You may use the Platform to sign the Partner/Work Agreement and secure adequate
                                approvals before commencing the Project/Work posted by the Client.</li>

                            <li>You may use the Platform to communicate with the Clients with respect to the Projects/Works, analyse and review
                                the progress of the Project/Work, get alerts from Clients, keep track of the Project/Work schedule, etc.</li>

                            <li>You may use the Platform to receive Project/Work Fees for Services offered to the Clients in relation to the
                                Project.</li>

                            <li>You will not use the Platform or any feature provided therein for any purposes not intended under the Terms.
                            </li>

                            <li>You will not permit any third party to have access to or use the Platform.</li>

                            <li>You shall not</li>
                            <li>Use the Website and/or Application for time-sharing, rental, or service bureau purposes;</li>
                            <li>Make the Website and/or Application, in whole or in part, available to any other person, entity, or business;
                            </li>
                            <li>Modify the contents of the Website and/or Application or use such content for any commercial purpose, or any
                                public display, performance, sale, or rental other than envisaged in the Agreement;</li>
                            <li>Copy, reverse engineer, decompile or disassemble the software embedded in the Website and/or Application; or
                            </li>
                            <li>Modify the software or combine the software in the Website and/or Application with any other software or
                                services not provided or approved by Us.</li>


                            <li>You will not intentionally use the Website and/or Application in any way that is unlawful or harms Us, our
                                directors, employees, affiliates, distributors, partners, and/or any Client and/or data or content on the
                                Website and/or Application.</li>

                            <li>You undertake that :</li>

                            <li>You will not delete or modify any content on the Website/Application including but not limited to, legal
                                notices, disclaimers, or proprietary notices such as copyright or trademark symbols, logos, that You do not own
                                or have express permission to modify;</li>
                            <li>You will not decompile, reverse engineer, or disassemble the software embedded in the Website and/or
                                Application, or You will not remove any copyright, trademark registration, or other proprietary notices from the
                                Website and/or Application. You further agree not to access or use this Website or Application in any manner
                                that may be harmful to its operation or its content;</li>
                            <li>You will not use the Website and/or Application and/or services in any unlawful way, or harms the Company or any
                                other person or entity, as determined in the Company's sole discretion;</li>
                            <li>You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming", "spamming",
                                "flooding", "trolling", "phishing" and "grieving" as those terms are commonly understood and used on the
                                Internet and</li>
                            <li>You will not host, display, upload, modify, publish, transmit, update or share any information that belongs to
                                another person and to which the user does not have any right to; is grossly harmful, harassing, blasphemous
                                defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially,
                                ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise
                                unlawful in any manner whatever; harm minors in any way; infringes any patent, trademark, copyright or other
                                proprietary rights(s), violates any law for the time being in force; deceives or misleads the addressee about
                                the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
                                impersonate another person; contains software viruses or any other computer code, files or programs designed to
                                interrupt, destroy or limit the functionality of any computer resource; threatens the unity, integrity, defence,
                                security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement
                                to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other
                                nation.</li>

                            <li>You will immediately notify Us, without any unreasonable delay, of any breach or suspected breach of the
                                security of the Website and/or Application of which You become aware, or any unauthorized use or disclosure of
                                information within or obtained from the Website and/or Application, and You will take such action to mitigate
                                the breach or suspected breach as We may direct, and will cooperate with Us in investigating and mitigating such
                                breach.</li>

                            <li>You Undertake to provide best possible services and execute the Project as per agreed terms once you win the Bid
                                in case of customized solutions and will provide the Services as per the understanding with the Client.</li>
                        </ol>

                        <p><u><strong>10. Confidential Information of Company and Clients:</strong></u></p>
                        <ol>
                            <li>You will treat all information received from Us and the Clients on the Website and/or Application as
                                confidential.</li>

                            <li>You may not disclose such Confidential Information to any other person, and You may not use any confidential
                                information except as provided herein. Except as otherwise provided in Terms, You may not, at any time, during
                                or after the applicability of these Terms, directly or indirectly, divulge or disclose Confidential Information
                                for any purpose or Your benefit or the purposes or benefit of any other person. You agree to hold all
                                Confidential Information in strict confidence and to take all measures necessary to prevent unauthorized
                                copying, use, or disclosure of the same or from being disclosed into the public domain or into the possession of
                                persons not bound to maintain confidentiality. You will disclose Confidential Information only to your
                                employees, agents, or contractors who need to use it for rendering Services as permitted under the Terms only.
                                You will inform all such recipients of the confidential nature of Confidential Information and will instruct
                                them to deal with the same in accordance with these Terms.</li>
                            <li>You will promptly notify Us in writing of any improper disclosure, misappropriation, or misuse of the
                                Confidential Information by any person, which may come to Your attention.</li>

                            <li>You agree that We or the Clients will suffer irreparable harm if You fail to comply with the obligations
                                outlined in this Section, and You further agree that monetary damages will be inadequate to compensate Us or the
                                Clients for any such breach. Accordingly, You agree that We and the Clients will, in addition to any other
                                remedies available to Us and Clients at law or in equity, be entitled to seek injunctive relief to enforce the
                                provisions hereof, immediately and without the necessity of posting a bond.</li>
                            <li>This Section will survive the termination or expiration of these Terms of Agreement for any reason.</li>
                        </ol>

                        <p><u><strong>11. Disclaimer and Exclusion of Warranties:</strong></u></p>
                        <ol>
                            <li>You agree that Our role is limited to</li>
                            <li>Providing a platform for the Partners to connect with Clients,</li>
                            <li>Providing a platform for Partners to receive information and updates regarding various Projects posted by
                                Clients,</li>
                            <li>Allowing the Partners to directly Bid for RFQ provided by Clients</li>
                            <li>Allowing the Partner and the Client to sign the Project/Work Agreement upon winning the Bid if required,</li>
                            <li>Enable the Partners and Clients to be updated about and keep track of the Projects,</li>
                            <li>Providing a platform to Partners to communicate with clients on all aspects of the project, and</li>
                            <li>Providing a platform for facilitating collection and disbursal of Platform Fees and Commission. Accordingly, the
                                Company is merely an intermediary providing online marketplace service and is only a Platform connecting Client
                                with Partners who shall offer Services to the Client on the execution of the Partner Agreement. The Partner
                                Agreement for availing the Service shall be a contract solely between You and the Client. At no time shall
                                SERVIZKART have any obligations or liabilities in respect of such contract unless provided otherwise specified
                                by the Company.</li>
                            <li>We are not responsible for the accuracy or completeness of information available from or through the Platform.
                                You assume full risk and responsibility for the use of the information You obtain from or through the Platform,
                                and You agree that we would neither be responsible nor liable for any claim, loss, or liability arising from the
                                use of the information.</li>
                            <li>Any interactions between You and other Partners or Clients on the Platform or outside the Platform are strictly
                                between You and the other Partners or Client. You shall not hold SERVIZKART responsible for any interactions and
                                associated issues.</li>

                            <li>The website, the application, the Platform and the services offered and the information contained therein are
                                provided on an "as is" and "as available" basis without any warranty of any kind, expressed or implied,
                                including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and
                                non-infringement. You are solely responsible for any and all acts or omissions taken or made in reliance on the
                                website, application, or the information therein, including inaccurate or incomplete information. You further
                                agree that neither we nor any of our affiliates will be liable for any harms, which lawyers and courts often
                                call direct, indirect, remote, punitive, incidental, special, consequential, or exemplary damages, including,
                                but not limited to, damages for loss of profits, goodwill, data or other intangible losses, arising out of or in
                                connection with the platform, even if we have been advised of the possibility of such damages, resulting from:
                            </li>

                            <li>The use or the inability to use the platform;</li>
                            <li>Any products, data, information including servizkart information or client information obtained or services
                                offered or messages received or transactions entered into, through or from the platform;</li>
                            <li>Unauthorized access to or alteration of your transmissions or data;</li>
                            <li>Statements or conduct of anyone on the service;</li>
                            <li>Malicious or criminal behavior, or false or fraudulent transactions or</li>
                            <li>Technical or other operational lapses on the platform or</li>
                            <li>Any other matter relating to the service offered on the platform.</li>

                            <li>You Acknowledge That Clients Have Access To The Website And Application. Such Clients Have Committed To Comply
                                With Terms Set Out With Them And Our Policies And Procedures Concerning Use Of The Application And Website;
                                However, The Actions Of Such Clients Are Beyond Our Control. Accordingly, We Do Not Assume Any Liability For Or
                                Relating To Any Impairment Of The Privacy, Security, Confidentiality, Integrity, Availability, Or Restricted Use
                                Of Any Information On The Website And/or Application Resulting From Any Clients' Actions Or Failures To Act.
                            </li>
                            <li>We Expressly Disclaim Any Liability For The Consequences To You Arising Because Of Your Use Of The Website,
                                Application Or Rendering The Services. We Expressly Disclaim Any Liability For Any Incorrect Information
                                Provided To You By Any Client And For Any Failure To Receive Payment Or Non-Payment Of Project Fees By Clients.
                            </li>
                            <li>While It Is Our Objective To Make The Website And Application Accessible At All Times, The Websites And/or
                                Application May Be Unavailable From Time To Time For Any Reason Including, Without Limitation, Routine
                                Maintenance. In Addition, Various Portions Of The Website Or Application May Operate Slowly From Time To Time.
                                You Understand And Acknowledge That Due To Circumstances Both Within And Outside Our Control, Access To The
                                Website And/Or The Application May Be Interrupted, Suspended Or Terminated From Time To Time. In Particular, And
                                Not In Limitation Of The Foregoing, Company Shall Not Be Liable In Any Way For Any Loss Of Business Or Any
                                Damages Arising From Any Such Interruption, Suspension Or Termination Of The Website And/Or Application.</li>
                            <li>We do not warrant that your use of the website, application, and services under these terms will not violate any
                                law or regulation applicable to you.</li>
                        </ol>

                        <p><u><strong>12. Limitation of Liability:</strong></u></p>

                        <p>Notwithstanding the above, in the event company should have any liability to you or any third party for any loss,
                            harm, or damage, you and the company agree that such liability shall under no circumstances exceed the value of any
                            platform fees received by the company from you in the preceding twelve months in connection with use of the website
                            and application or INR 500 whichever is lower. You and Company agree that the foregoing limitation of liability is
                            an agreed allocation of risk between you and the company. You acknowledge that without your assent to this section,
                            the company would not provide access to the website, application, and services, to you.</p>

                        <p><u><strong>13. Indemnification:</strong></u></p>
                        <p>You agree to indemnify, defend, and hold harmless the Company, Our affiliates, officers, directors, and agents, from
                            and against any and all loss, damage, liability, claim, deficiency, action, judgment, interest, award, penalty,
                            fine, cost or expense, including any reasonable attorney and professional fees and costs, arising out of or
                            occurring in connection with:</p>

                        <ol type='a'>
                            <li>Any Client relying or acting upon the proposal, Bid, or any other information posted by you on the Application
                                and/or Website relating to the RFQ posted by the Client;</li>
                            <li>Your use or misuse of the Platform;</li>
                            <li>Any breach by You of the Terms or any obligations, representations, or warranties contained in these Terms;</li>
                            <li>Your infringement of any intellectual property or other rights of any person or entity,</li>
                            <li>The actions of any person gaining access to the Website and/or Application under a User Name assigned to You;
                            </li>
                            <li>Any harm to the reputation and goodwill of SERVIZKART directly attributable to your acts;</li>
                            <li>Or any fraud, theft, negligence, willful misconduct, intentional wrongdoing, or deficiency on your part or the
                                part of your employees and agents in the performance of their Services under these Terms or any harm to the
                                reputation and goodwill of SERVIZKART directly attributable to your actions or inactions. You shall not settle
                                or compromise any such matter without our prior written consent. We shall be entitled to participate in such
                                defense through our counsel at your cost and expense.</li>
                            <li>You shall be liable to indemnify and hold SERVIZKART harmless against all damages, losses, costs, and expenses
                                incurred by SERVIZKART as a consequence of any complaint from any Client received by SERVIZKART with respect to
                                deficiency in Services offered by you.</li>
                        </ol>
                        <p><u><strong>14. Termination /Modification/Suspension:</strong></u></p>
                        <ol>
                            <li>The Company may at any time, terminate its legal agreement with you if you have breached any provision of
                                the terms (or have acted in a manner which clearly shows that you do not intend to, or are unable to comply
                                with the provisions of the terms).</li>


                            <li>You may terminate this agreement with the Company by deleting your Account from the Website or the Application.
                            </li>

                            <li>Notwithstanding anything to the contrary in these Terms, We have the right, on providing notice to You,
                                immediately to terminate, suspend, or amend the provision of your Services on the platform without liability:
                            </li>

                            <li>To comply with any order issued or proposed to be issued by any governmental agency;</li>
                            <li>To comply with any provision of law; or</li>
                            <li>If the performance of any term of these Terms by either Party would cause it to violate the law.</li>
                            <li>If you receive poor ratings from Client(s) or if Client(s) are not happy with the level of services being
                                offered by you or the standard of services offered by you are not as per market standards we may also
                                suspend/terminate your Account.</li>

                            <li>We may suspend Your services immediately pending Your cure of any breach of these Terms, or in the event, We
                                determine in Our sole discretion that accesses to or use of the Website/ Application by You may jeopardize the
                                confidentiality, privacy, security, integrity, or availability of information within the Website and/or
                                Application or that You have violated or may violate these Terms, or have jeopardized or may jeopardize the
                                rights of any third party, or that any person is or may be making unauthorized use of the Website and/or
                                Application with any User Name assigned to You. Our election to suspend your services shall not waive or affect
                                Our rights to terminate these Terms as applicable to You as permitted under these Terms.</li>
                            <li>Upon termination, You will cease to use the Website and/or Application and We will terminate Your access to the
                                Website and/or Application. Upon termination for any reason, You will cease to have access to the Website and/or
                                Application, and You will return to Us all hardware, software, and documentation provided by or on behalf of Us.
                            </li>
                        </ol>

                        <p><u><strong>15. Governing Law and Jurisdiction:</strong></u></p>
                        <p>The interpretation of this Agreement and the resolution of any disputes arising under this Agreement shall be
                            governed by the laws of India and the courts at Gurugram, Haryana subject to clause 16 below, shall have
                            jurisdiction.</p>
                        <p><u><strong>16. Arbitration:</strong></u></p>
                        <ol>
                            <li>Any dispute, claim or controversy arising out of or relating to this Agreement or the breach, termination,
                                enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of
                                this Agreement to arbitrate, or to Your use of the Website and/or Application or its features or the information
                                to which it gives access, shall be determined by Arbitration in India, and referred to the sole arbitrator to be
                                appointed by the company (i.e. SERVIZKART) in accordance with the Arbitration and Conciliation Act 1996 or any
                                modifications or amendments thereof for the time being in force. The venue of such arbitration shall be
                                Gurugram, Haryana India. The governing law of the Agreement shall be the substantive law of India. All
                                proceedings of such arbitration, including, without limitation, any awards, shall be in the English language.
                                The award shall be final and binding on the Parties.</li>
                            <li>The Parties shall have the right to apply to a court of competent jurisdiction to obtain interim injunctive
                                relief in respect of any dispute, pending resolution of such dispute in accordance with the Agreement.</li>
                        </ol>

                        <p><u><strong>17. Disputes between Clients and Partners:</strong></u></p>
                        <ol>
                            <li>The provisions regarding disputes between Clients and You in connection with the offering and receiving
                                Services, Bid, Project/Work, including payment of Project/Work Fees and performance of any Service, and any
                                other terms, conditions, warranties, or representations associated with such transactions or dealings, are
                                solely between you and the Client. You should do reasonable due diligence you deem necessary before rendering
                                Services or entering into any transaction with any third party, including without limitation, Clients. You
                                understand that deciding whether to provide Services to a Client or bidding for an RFQ is your personal decision
                                for which you alone are responsible. Please note that that the Company does not warrant and cannot make
                                representations as to the suitability of any Client you may decide to interact with on or through the Website
                                and/or Application and the accuracy of the information they post on the Website or Application. While the
                                Company may attempt to seek information about the background of a Client you understand that Clients may
                                register themselves suo moto. Please also note that any so-called background check undertaken by Company is not
                                exhaustive, at the end of the day, the Partner should take an informed decision on his/her own accord and keep
                                in mind the fact that Company only seeks to provide a platform wherein Clients and Partners have an opportunity
                                to interact with each other.</li>
                            <li>Notwithstanding the foregoing, You agree that since Company only seeks to provide a platform wherein the Client
                                and Partner can be brought together and Company itself does not have any role in the execution or provision of
                                Services itself, Company shall not be responsible or liable for any loss or damage of any sort whatsoever
                                incurred as the result of any such transaction or dealings.</li>

                            <li>If there is a dispute between Client and Partner, you acknowledge and agree that Company is under no obligation
                                to become involved. However, the Company may offer assistance in the dispute resolution process, as may be
                                ordered by the Court.</li>
                            <li>If a dispute arises between you and one or more Clients, you hereby release the Company, its officers, managers,
                                members, directors, employees, attorneys, agents, and successors in rights from any claims, demands, and damages
                                (actual and consequential) of every kind or nature, known or unknown, suspected or unsuspected, foreseeable or
                                unforeseeable, disclosed or undisclosed, arising out of or in any way related to such disputes and/or the
                                Website or any Client thereunder<u><strong>.</strong></u></li>
                        </ol>

                        <p><u><strong>18. Copyright Infringement Take Down Procedure</strong></u></p>
                        The Company has high respect for intellectual property and expects the same from its users. The company may, in
                        appropriate circumstances and at its discretion, terminate the Account or prohibit access to the Website and/or
                        Application of users who infringe upon the intellectual property rights of others. If you believe that your work has
                        been copied and posted on the Website and/or Application in a way that constitutes copyright infringement and/or
                        trademark infringement, please send the following information to us at<u>partnership@servizkart.com</u>.<br />
                        <ol>
                            <li>Identification of the copyrighted and/or trademarked work claimed to have been infringed, or, if multiple works
                                at a single online site are covered by a single notification, a representative list of such works at that site;
                            </li>
                            <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and
                                that is to be removed or access to which is to be disabled at the Website, and information reasonably sufficient
                                to permit Company to locate the material;</li>
                            <li>A written statement stating that you have a good faith belief that the disputed use is not authorized by the
                                copyright and/or trademark owner, its agent, or the law;</li>
                            <li>Information reasonably sufficient to permit Company to contact you as the complaining party, such as an address,
                                telephone number, and, if available, an electronic mail address at which you may be contacted;</li>
                            <li>An electronic or physical signature of the person authorized to act on behalf of the owner of an exclusive
                                interest that is allegedly infringed; and</li>
                            <li>A statement by you, made under penalty of perjury, that the information in your report is accurate and that you
                                are the owner of the exclusive right or authorized to act on the behalf of the owner of the exclusive right. A
                                statement by you comprised of the foregoing points is referred to herein as the "Notice."</li>
                            Only the owner of the intellectual property rights is permitted to report potentially infringing items through
                            Company's reporting system set forth above. If you are not the intellectual property rights owner, you should
                            contact the intellectual property rights owner and they can choose whether to use the procedures outlined in these
                            Terms of Use.
                        </ol>
                        <p><u><strong>19. Grievance officer:</strong></u></p>

                        <p>In accordance with Information Technology Act 2008 and rules made thereunder, the name and contact details of the
                            Grievance Officer is as follows:<br />Attention- Grievance Officer <br /><strong>Email
                                Id:</strong>asingh@SERVIZKART.com</p>

                        <p><u><strong>20. Miscellaneous Provisions:</strong></u></p>
                        <ol>
                            <li><strong>Severability:</strong>If any provision of this Agreement is held by a court of competent jurisdiction or
                                arbitral tribunal to be unenforceable under applicable law, then such provision shall be excluded from the
                                Agreement, and the remainder of the Agreement shall be interpreted as if such provision were so excluded and
                                shall be enforceable in accordance with its terms.</li>

                            <li><strong>Notices:</strong>All notices and other communications required or permitted hereunder to be given to a
                                Party shall be in writing, in the English language, and shall be sent by facsimile, e-mail, or mailed by prepaid
                                nationally-recognized courier, or otherwise delivered by hand or by messenger, addressed to such Party's address
                                as set forth above.</li>

                            <li><strong>Waiver:</strong>No term of the Agreement shall be deemed waived and no breach excused unless such waiver
                                or consent shall be in writing and signed by the Party claimed to have waived or consented. Any consent by any
                                Party to, or waiver of a breach by the other, whether expressed or implied, shall not constitute consent to,
                                waiver of, or excuse for any other different or subsequent breach.</li>

                            <li><strong>Force Majeure:</strong>This agreement is subject to force majeure. Neither party's failure to perform
                                shall be deemed a breach or default hereunder or shall give rise to the other party when failure is attributable
                                to or caused by war, revolution or other civil disorder, strikes or labour disturbance, allocation prohibition
                                or restriction on import or export orders or acts of any government instrumentality, fires, floods, acts of god,
                                or any cause of a similar or dissimilar nature beyond the reasonable control of party whose performance is
                                prevented or hindered hereby
                            </li>

                            <li><strong>Project:</strong>You shall not assign or sub-license, without the prior written consent of the Company,
                                the rights, duties, or obligations under this Agreement, in whole or in part, to any person or entity.</li>

                            <li>The Terms contain the entire understanding of the Parties, and there are no other written or oral understandings
                                or promises between the Parties with respect to the subject matter of the Terms of Use other than those
                                contained or referenced in the Terms of Use.</li>
                        </ol>

                        <p>These Terms were last updated on:<strong>21<sup>s</sup><sup>t</sup>August 2021</strong><br />
                            Please reach out to us at <u>partnership@servizkart.com </u>for any query or clarification.</p>
                    </div>

                </div>

                <Footer />
            </>
        )
    }
}
