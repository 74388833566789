import * as http from "./http";


const getApi = () => {
    let hostname = window.location.host;
    var arrValue = hostname.split(".");
    var result = arrValue[0];
    if (result.includes("localhost") === true) {
        result = "http://localhost:8484/";
    }
    else {
        result = "https://szi.servizkart.com/";
        //result = "http://52.172.190.126:8484/";
    }
    result = "https://szi.servizkart.com/";
    return result;
};
export const APIUrl = getApi();

export const ArtifactTypeID = {
    NOTES: 100,
    IMAGES: 101,
    RECORDINGS: 102,
    VIDEOS: 103,
    CALANDERS: 104
}

export const UploadFile = (field, data) => {
    const formData = new FormData();
    formData.append(field, data);
    return fetch(`${APIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getServiceCategories = () => {
    let api = `${APIUrl}service-categories?filter[order]=displayOrder`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getContentPageConfig = (sectionType) => {
    let api = APIUrl + `content-page-configs?filter[where][sectionType]=${sectionType}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getServiceCategoryByFilter = (filter) => {
    let api = APIUrl + `service-categories-by-filter?filter=${filter}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getServiceCategory = (limit) => {
    let api = APIUrl + `service-categories?filter[offset]=0&filter[limit]=${limit}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getServiceCategoryForHomepage = (limit) => {
    let api = APIUrl + `service-categories?filter[where][parentId]=0&filter[offset]=0&filter[limit]=${limit}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getTopLevelServiceCategories = () => {
    let api = APIUrl + `service-categories?filter[where][parentId]=0`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getSubCategories = (id) => {
    let api = APIUrl + `service-categories?filter[where][parentId]=${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getCategortById = (id) => {
    let api = APIUrl + `service-categories?filter[where][id]=${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAdvisoryPanel = () => {
    let api = APIUrl + `advisory-panels?filter[order]=displayOrder%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const loginUser = (user) => {
    let api = APIUrl + "user/login";
    return http.post(api, user).then(response => {
        return response;
    });
}

export const verifyAccount = (email, token) => {
    let api = APIUrl + `accounts/verify?email=${email}&token=${token}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAppPartners = () => {
    let api = APIUrl + `app-partners?filter[order]=displayOrder%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getPreferredPartners = () => {
    let api = APIUrl + `partners?filter[order]=displayOrder%20ASC`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getAdvisoryPanelByID = (id) => {
    let api = APIUrl + `advisory-panels/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getIndustries = () => {
    let api = APIUrl + `industries`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getTestimonials = () => {
    let api = APIUrl + `testi-monials`;
    return http.get(api).then(response => {
        return response;
    });
}


export const submitAccount = (data) => {
    let api = APIUrl + "accounts";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const addUserAccountMap = (data) => {
    let api = APIUrl + "useraccountmaps";
    return http.post(api, data).then(response => {
        return response;
    });
}
// export const loginUser = (user) => {
//     let api = APIUrl + "login";
//     return http.post(api, user).then(response => {
//         return response;
//     });
// }
export const loginWithOTP = (mobile) => {
    let api = APIUrl + `user/signin/${mobile}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const verifyOTP = (phone, otp) => {
    let api = APIUrl + `user/verifyOtp/${phone}/${otp}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const submitEnquery = (enqueryDetail) => {
    let api = APIUrl + "enquiries";
    return http.post(api, enqueryDetail).then(response => {
        return response;
    });
}

export const getServiceProviders = (categoryid, StartIndex = 0, PageSize = 20) => {
    let api = `${APIUrl}account-servicecat-maps?filter[where][serviceCategoryId]=${categoryid}&filter[include][0][relation]=account`;
    return http.get(api).then(response => {
        return response;
    });
}



export const getAllCategories = () => {
    let api = `${APIUrl}partfamilies`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getUserByToken = (token) => {
    let api = `${APIUrl}users?filter[where][passwordresettoken]=${token}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getAllCountries = (name = undefined) => {
    let api = `${APIUrl}countries`;
    let whr = "";
    if (name != undefined && name != "") {
        api = `${api}?filter[where][name][like]=%${name}%`;
    }
    return http.get(api).then(response => {
        return response;
    });
}
export const getAllCities = () => {
    let api = `${APIUrl}cities`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getAllCitiesForState = (stateId, cityName) => {
    let api = `${APIUrl}cities`;
    let whr = "";
    api = `${api}?filter[where][stateId]=${stateId}`;
    if (cityName != undefined && cityName != "") {
        api = `${api}&filter[order][0]=cityName ASC&filter[where][cityName][like]=%${cityName}%`;
    }
    return http.get(api).then(response => {
        return response;
    });
}
export const getAllStates = (countryId = 0, stateName = undefined) => {
    let api = `${APIUrl}states`;
    let whr = "";
    api = `${api}?filter[where][countryId]=${countryId}`;
    if (stateName != undefined && stateName != "") {
        api = `${api}&filter[order][0]=stateName ASC&filter[where][stateName][like]=%${stateName}%`;
    }
    return http.get(api).then(response => {
        return response;
    });
}

export const submitServiceRequirment = (enqueryDetail) => {
    let api = APIUrl + "service-categories/insert-requirements";
    return http.post(api, enqueryDetail).then(response => {
        return response;
    });
}

export const submitCareerRequirment = (data) => {
    let api = APIUrl + "enquiry/career";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const registerAccount = (enqueryDetail) => {
    let api = APIUrl + "/account/register";
    return http.post(api, enqueryDetail).then(response => {
        return response;
    });
}

export const updateBuyerProfile = (profile) => {
    let api = APIUrl + "/users/updatebuyerprofile";
    return http.post(api, profile).then(response => {
        return response;
    });
}

export const get_dashboard = (userid) => {
    let api = APIUrl + `users/dashboard/`+userid;
    return http.get(api).then(response => {
        return response;
    });
}

export const getUserById = (userid) => {
    let api = APIUrl + "user/getuserbyid/" + userid;
    return http.get(api).then(response => {
        return response;
    });
}

export const getServiceBuddies = () => {
    let api = `${APIUrl}/service-categories/online-service-buddy`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getServiceProvidersForCategory = (id) => {
    let api = `${APIUrl}/service-categories/${id}/service-providers`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getServiceCategoriesForFilter = (filter) => {
    let api = `${APIUrl}/service-categories/${filter}`;
    return http.get(api).then(response => {
        return response;
    });
}
