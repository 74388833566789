import React, { Component } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import * as apiClient from "../../../components/network/apiClient";


export default class ServiceRequirements extends Component {
    constructor(props) {
        super(props);
      //  console.log(props);
        this.state = {
            fullName: "",
            email: "",
            mobile: "",
            subject: "",
            message: "",
            requirementSubmitted: false,
            errors: {},
            user: {},
        };
    }
    addServiceRequirement = () => {
        const { fullName, email, mobile, subject, message } = this.state;
        let errors = {};
        let hasError = false;

        if (fullName == "") {
            errors.fullName = "Name is required";
            hasError = true;
        }

        if (email == "") {
            errors.email = "Email address is required";
            hasError = true;
        }
        if (mobile == "") {
            errors.mobile = "Mobile number is required";
            hasError = true;
        }

        if (subject == "") {
            errors.subject = "Subject is required";
            hasError = true;
        }

        if (message == "") {
            errors.message = "Message is required";
            hasError = true;
        }



        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(email)) {
            errors.email = "Email address is not valid";
            hasError = true;
        }

        const pattern = /^[0-9 '.-]+$/;
        if (!pattern.test(mobile)) {
            errors.mobile = "Only numbers are allowed in mobile";
            hasError = true;
        }

        if (mobile.length < 10) {
            errors.mobile = "mobile number is not valid";
            hasError = true;
        }


        if (mobile.length < 10) {
            errors.mobile = "mobile number is not valid";
            hasError = true;
        }
        this.setState({
            errors: errors
        });
        if (hasError == true) return;


        let data = {
            Name: fullName,
            EmailID: email,
            Mobile: mobile,
            Subject: subject,
            Remarks: message,
            EmailType: "REQ",
            ParentServiceCategoryID: this.props.category.parentId,
            ServiceCategoryID: this.props.category.id,
        };
        apiClient.submitServiceRequirment(data).then(res => {
           // console.log(res);
            this.setState({ requirementSubmitted: true });
        }).catch(err => {
            console.log(err);
        })
    }
    componentDidMount() {
        let userid = localStorage.getItem("userId");
        apiClient.getUserById(userid).then(res => {
            let user =  res[0];
            let name  = user.FirstName + " " + user.LastName;
            let email = user.EmailAddress;
            let mobile = user.MobileNumber;
            this.setState({fullName: name,email: email,mobile:mobile, user: res[0] });
        }).catch(err => {
            console.log(err, "Get Error");
        });
    }
    render() {
        const { fullName, email, mobile, subject, message, requirementSubmitted, errors } = this.state;
        return (
            <>
                { requirementSubmitted && <div className="inner-heading">Thanks for submitting the request <br />We will contact you shortly.</div> }
                { !requirementSubmitted && <div className="share-requirement-form ">
                    <div className="inner-heading">Share your requirements</div>
                    <div className="contact-form-grid">
                        <form>
                            <div className="form-grid d-grid">
                                <div className="left">
                                    <label htmlFor="name">Your Name <sup>*</sup></label>
                                    <input type="text" id="name" placeholder="Full Name"
                                        value={ fullName }
                                        onChange={ (event) => {
                                            this.setState({ fullName: event.target.value });
                                        } }
                                        required />
                                    { errors && errors.fullName && <div className="input-error">{ errors.fullName }</div> }
                                </div>
                                <div className="right">
                                    <label htmlFor="email">Your Email <sup>*</sup></label>
                                    <input type="email" id="email" placeholder="Email Address"
                                        value={ email }
                                        onChange={ (event) => {
                                            this.setState({ email: event.target.value });
                                        } }
                                        required />
                                    { errors && errors.email && <div className="input-error">{ errors.email }</div> }
                                </div>
                                <div className="left">
                                    <label htmlFor="number">Your Number <sup>*</sup></label>
                                    <input type="text" id="number" placeholder="Your Phone"
                                        value={ mobile }
                                        onChange={ (event) => {
                                            this.setState({ mobile: event.target.value });
                                        } }
                                        required />
                                    { errors && errors.mobile && <div className="input-error">{ errors.mobile }</div> }
                                </div>
                                <div className="right">
                                    <label htmlFor="subject">Subject <sup>*</sup></label>
                                    <input type="text" id="subject" placeholder="Subject"
                                        value={ subject }
                                        onChange={ (event) => {
                                            this.setState({ subject: event.target.value });
                                        } }
                                        required />
                                    { errors && errors.subject && <div className="input-error">{ errors.subject }</div> }
                                </div>
                            </div>
                            <div className="form-textarea">
                                <label htmlFor="message">Your Message <sup>*</sup></label>
                                <textarea id="message" placeholder="Write Message"
                                    value={ message }
                                    onChange={ (event) => {
                                        this.setState({ message: event.target.value });
                                    } }
                                ></textarea>
                                { errors && errors.message && <div className="input-error">{ errors.message }</div> }
                            </div>
                            <button type="button" className="theme-button" onClick={ () => {
                                this.addServiceRequirement();
                            } }>Send Us Message <FiChevronRight className="icon" /></button>
                        </form>
                    </div>
                </div> }
            </>
        )
    }
}
