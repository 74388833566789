import React from 'react';

export default function MySearches({ data }) {
    return (
        <table className="detail-table">
            <thead>
                <tr>
                    <th>Search Date</th>
                    <th>Service Category   </th>
                    <th>Subcategory  </th>
                    <th>Partner Searched </th>
                    <th>Action</th>
                </tr>
            </thead>
            { data && data.length > 0 && data.map((row, index) => (
                <tr key={ index }>
                    <td component="th" scope="row">
                        { row.name }
                    </td>
                    <td>{ row.alert_mobile_no }</td>
                    <td >{ row.session }</td>
                </tr>
            )) }
        </table>
    );
}
