import React, { Component } from 'react'
import Navbar from '../../components/common/Navbar'
import Breadcrumb from '../../components/common/Breadcrumb'
import CareerDetails from '../../components/contact/CareerDetails'
import Footer from '../blocks/Footer'
import { Link } from "react-router-dom";
import * as apiClient from "../../components/network/apiClient";
import { ToastContainer, toast } from 'react-toastify';
import JobProgress from "./job-progress";
import MySearches from './my-searches';
import Subscriptions from './subscriptions';
import CountBlock from './count-block';

import './dashboard.css';

export default class DashboaardPage extends Component {
    constructor(props) {
        super(props);
        // console.log(props);
        this.state = {
            user: null,
            bs: null,
            otc: null,
            ta: null,
            isServiceProvider: true
        };
    }
    loadDashboard = () => {
        let userid = localStorage.getItem("userId");
        apiClient.get_dashboard(userid).then(res => {
            if (res.length > 0 && res[0].Ord == "Business Solutions") {
                let bs = res.filter(item => item.Ord == "Business Solutions");
                let otc = res.filter(item => item.Ord == "OTC");
                let ta = res.filter(item => item.Ord == "Transaction Amount");
                this.setState({
                    bs: bs,
                    otc: otc,
                    ta: ta
                });
            }

        }).catch(err => {
            console.log(err);
        });
    }

    componentDidMount() {
        let userid = localStorage.getItem("userId");
        apiClient.getUserById(userid).then(res => {
            //console.log(res, "GetUser");
            this.setState({ user: res[0] });

        }).catch(err => {
            console.log(err, "Get Error");
        });
        this.loadDashboard();
    }
    render() {
        const { user , isServiceProvider, bs, otc, ta} = this.state;
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Dashboard" />
                <section className="profile-wrapper">
                    <div className="container">
                        <section className="signup-form-wrapper">
                            <div className="container">

                                <div className="flexCenter">
                                    <div className='user-information'>
                                        { user && <div className='user-info-name'>Hello { user.FirstName } { user.LastName }</div> }
                                        { user && <div>Company: { user.AccountName }, City name</div> }
                                        { user && <div><strong>Email :</strong>{ user.EmailAddress } , <strong>Phone :</strong>{ user.MobileNumber }</div> }
                                    </div>
                                    { isServiceProvider && <h2 className='block-heading'>My Searches</h2> }
                                    { isServiceProvider && <div className='block-container'>
                                        <div className='services-explored'><strong>Services Explored:</strong> [Corporate Legal Services, Strategy and Management]</div>
                                        <MySearches data={ [] } />
                                    </div> }

                                    { isServiceProvider && <h2 className='block-heading'>Subscriptions:</h2> }
                                    { isServiceProvider && <div className='block-container'>
                                        <Subscriptions data={ [] } />
                                    </div> }
                                   
                                    { isServiceProvider && <div className=''>
                                        {bs && bs.length > 0 && <div><h2 className='block-heading'>Business Solutions</h2></div>}
                                        {bs && bs.length > 0 && <div className='block-container'>
                                            { bs.map((item, index) => {
                                                return (
                                                    <div key={ index }>
                                                        <CountBlock title={ item.Descriptions } value={ item.TotalCount } icon={ item.IconUrl } />
                                                    </div>
                                                )
                                            }) }
                                        </div>}
                                        {otc && otc.length > 0 && <div><h2 className='block-heading'>OTC</h2></div>}
                                        {otc && otc.length > 0 && <div className='block-container'>
                                            { otc.map((item, index) => {
                                                return (
                                                    <div key={ index }>
                                                        <CountBlock title={ item.Descriptions } value={ item.TotalCount } icon={ item.IconUrl } />
                                                    </div>
                                                )
                                            }) }
                                        </div>}
                                        { ta && ta.length > 0 && <div><h2 className='block-heading'>Transaction Amount</h2></div>}
                                        { ta && ta.length > 0 && <div className='block-container'>
                                            { ta && ta.map((item, index) => {
                                                return (
                                                    <div key={ index }>
                                                        <CountBlock title={ item.Descriptions } value={ item.TotalCount } icon={ item.IconUrl } />
                                                    </div>
                                                )
                                            }) }
                                        </div>}
                                    </div> }

                                    <h2 className='block-heading'>Job Progress</h2>
                                    <div className='block-container'>
                                        <div className='services-explored'><strong>Services Explored:</strong>Service Category Name, Sub Category Name etc</div>
                                        <JobProgress data={ [] } />
                                    </div>

                                    <h2 className='block-heading'>Messages</h2>
                                    <div className='block-container'>
                                        <JobProgress data={ [] } />
                                    </div>

                                    <h2 className='block-heading'>Profile</h2>
                                    { user && <div className='block-container profile-container'>
                                        <div className='value-parent full-width'><b>Company Name:</b>{ user.AccountName }</div>
                                        <div className='value-parent'><b>Designation:</b>{ user.Designation }</div>
                                        <div className='value-parent'><b>Company Webite:</b>http://www.company.com</div>
                                        <div className='value-parent'><b>Landline No:</b> { user.MobileNumber }</div>
                                        <div className='value-parent'><b>Email Address:</b> { user.EmailAddress }</div>
                                        <div className='value-parent full-width'><b>GST No: </b> { user.GSTNO }</div>
                                        <div className='value-parent'><b>Alternate Contact Person:</b> Mr. Amit Jain</div>
                                        <div className='value-parent'><b>Alternate Contact No:</b> 124-456-235 </div>
                                        <div className='heading-parent'><b>Company Address</b> </div>
                                        <div className='value-parent full-width'><b>Address 1: </b>{ user.Address1 }</div>
                                        <div className='value-parent full-width'><b>Address 2: </b>{ user.Address2 }</div>
                                    </div> }

                                </div>

                            </div>
                        </section>
                    </div>
                </section>
                {/* <Footer /> */ }
            </>
        )
    }
}
