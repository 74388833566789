import React, { Component } from 'react';
import About from '../components/about/About';
import Breadcrumb from '../components/common/Breadcrumb';
import Navbar from "../components/common/Navbar";
import Footer from './blocks/Footer';

export default class About_Page extends Component {
    render() {
        return (
            <main className="about-page">
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="About Us." />
                <About />
                <Footer />
            </main>
        )
    }
}
