import React, { Component } from 'react';
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import Divider from '../components/other/Divider';
import CtaForm from '../components/other/CtaForm';
import ServiceSidebar from './services/ServiceSidebar';
import ServiceDtlsContent from './services/ServiceDtlsContent';
import Footer from './blocks/Footer';
import * as apiClient from "../components/network/apiClient";
import { FiChevronDown, FiShoppingCart, FiSearch, FiChevronRight } from "react-icons/fi";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import queryString, { parse } from "query-string";
import $ from 'jquery';
import UserSignUpForm from "../pages/auth/user/SignUpForm";
export default class ServiceDetailsPage extends Component {
    constructor(props) {
        super(props);
       // console.log(props);
        this.state = {
            categories: [],
            treeData: [],
            sideBarCategories: [],
            selectedCategory: null,
            isSidebarDisplayed: true,
            categoryid: 0,
            filter: "",
            isPopupDisplayed: false,
            isLoggedIn: false,
        };
    }
    popupToggle = () => {
        const { isPopupDisplayed } = this.state;
        let c = !isPopupDisplayed;
        this.setState({ isPopupDisplayed: c });
    }
    getChildCategories = (category) => {
        const {categories} = this.state;
        let child = this.loadChildCategories(category, categories);
        return child;
    }
    loadChildCategories = (category, allCategories) => {
        let allChildrens = allCategories.filter(i => i.parentId == category.id);
        allChildrens.forEach(cat => {
            let child = this.loadChildCategories(cat, allCategories);
            cat.serviceName = cat.serviceName;
            cat.children = child;
            cat.selected = false;
        });
        return allChildrens;
    }
    filterServiceCategories = () => {
        const { filter } = this.state;
        apiClient.getServiceCategoryByFilter(filter).then(res => {
           // console.log(res);
            res.forEach(cat => {
                let child = [];
                cat.serviceName = cat.serviceName;
                cat.children = child;
                cat.selected = false;
            });
            if (res.length > 0) {
                this.setState({
                    treeData: res,
                    categories: res,
                    sideBarCategories: res,
                    selectedCategory: res[0]
                }, () => {
                    this.onCategorySelect(res[0]);
                });
            } else {
                this.setState({
                    treeData: [],
                    categories: [],
                    sideBarCategories: [],
                    selectedCategory: null
                }, () => {
                });
            }

        }).catch(err => {
            console.log(err);
        });
    }
    loadServiceCategories = () => {
        apiClient.getServiceCategories().then(res => {
           // console.log(res);
            if (res.length > 0) {
                this.setState({ categories: res });
                let parentCategories = res.filter(item => item.parentId == 0);
                //console.log(parentCategories);
                parentCategories.forEach(cat => {
                    let child = this.loadChildCategories(cat, res);
                    cat.serviceName = cat.serviceName;
                    cat.children = child;
                    cat.selected = false;
                });
                parentCategories[0].selected = true;
                //console.log(parentCategories);
                this.setState({
                    treeData: parentCategories,
                    sideBarCategories: parentCategories,
                    selectedCategory: parentCategories[0]
                });
            }
        }).catch(err => {
            console.log("erro : " + err);
        });
    }

    loadCategory = (id) => {
        apiClient.getCategortById(id).then(res => {
           // console.log("The Categoiry : ");
           // console.log(res);
            if (res.length > 0) {
                let cat = res[0];
                cat.children = this.getChildCategories(cat);
                this.onCategorySelect(cat);
            }

        }).catch(err => {

        });
    }
    onCategorySelect = (item) => {
        const { sideBarCategories } = this.state;
        let catArr = [...sideBarCategories];
        catArr.forEach(inner => { inner.selected = false; });
        let index = catArr.findIndex(inner => inner.id == item.id);
        if(index >= 0)
            catArr[index].selected = true;
        this.setState({
            sideBarCategories: catArr,
            selectedCategory: item,
            isSidebarDisplayed: false,
        });
    }
    onSubCategorySelect = (item) => {
        this.setState({
            selectedCategory: item,
            isSidebarDisplayed: false,
        });
       // $('html, body').animate({ scrollTop: 0 }, 1000);
    }
    onSidebarToggle = () => {
        const { isSidebarDisplayed } = this.state;
        let isSidebar = !isSidebarDisplayed;
        this.setState({ isSidebarDisplayed: isSidebar });
    }

    onMoveToParentCategory = () => {
        const { selectedCategory } = this.state;
      //  console.log(selectedCategory);
        if (selectedCategory.parentId != 0) {
            this.loadCategory(selectedCategory.parentId);
            
        } else {
            this.onMoveTomainCategory();
        }
    }
    onMoveTomainCategory = () => {
        const { sideBarCategories } = this.state;
        $('html, body').animate({ scrollTop: 0 }, 1000);
        this.setState({ isSidebarDisplayed: true });
        let catArr = [...sideBarCategories];
        catArr.forEach(inner => { inner.selected = false; });
        catArr[0].selected = true;
        this.setState({
            sideBarCategories: catArr,
            selectedCategory: catArr[0],
        });
    }
    componentDidMount() {
        const n = localStorage.getItem("firstname");
        const token = localStorage.getItem("token");
        if (token && token != "") {
            this.setState({
                isPopupDisplayed:false,
                isLoggedIn: true,
            });
        }else{
            this.setState({
                isPopupDisplayed:true,
                isLoggedIn: false,
            });
        }
        this.loadServiceCategories();
        const parsed = queryString.parse(this.props.location.search);
        const id = (parsed.category) ? parsed.category : 0;
        if (id != 0) {
            this.loadCategory(id);
            this.onSidebarToggle();
        }

    }
    render() {
        const { treeData, selectedCategory, sideBarCategories,isPopupDisplayed, isSidebarDisplayed, filter } = this.state;
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Services" />
                <section className="service-details-wrapper">
                    <div className="container">
                        <div className="search-searvices">
                            <div className="header-menu-searchform">
                                <form onSubmit={ () => { this.filterServiceCategories(); } }>
                                    <input className="form-control" type="text" placeholder="Search services..." value={ filter } onChange={ (e) => {
                                        this.setState({ filter: e.target.value }, () => { this.filterServiceCategories(); });
                                    } } />
                                    <div className="form-icon" onClick={ () => {
                                        this.filterServiceCategories();
                                    } }>
                                        <FiSearch className="search-icon" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="service-details-grid d-grid">
                            { sideBarCategories.length > 0 && isSidebarDisplayed && <ServiceSidebar categories={ sideBarCategories } onCategorySelect={ this.onCategorySelect } /> }
                            { sideBarCategories.length > 0 && selectedCategory && <ServiceDtlsContent category={ selectedCategory } onSidebarToggle={ this.onSidebarToggle }
                                onMoveTomainCategory={ this.onMoveTomainCategory }
                                onMoveToParentCategory={ this.onMoveToParentCategory }
                                isSidebarDisplayed={ isSidebarDisplayed }
                                categories={ sideBarCategories }
                                onCategorySelect={ this.onCategorySelect }
                                onSubCategorySelect={ this.onSubCategorySelect } /> }
                            { sideBarCategories.length == 0 && <div className="no-categories-found">
                                <h1 style={ { textAlign: "center", } }>No Categories Found</h1>
                            </div> }

                        </div>
                    </div>
                </section>
                {/* <Divider />
                <div className="form-white-bg">
                    <CtaForm />
                </div> */}
                <Footer />
                <Modal isOpen={ isPopupDisplayed } backdrop="static" onClosed={() => {this.props.history.goBack();}}  keyboard={false} centered={true} toggle={ this.popupToggle } className="auth-model-popup">
                    <ModalHeader toggle={ this.popupToggle }>
                        SignUp/Login 
                    </ModalHeader>
                    <ModalBody>
                        <UserSignUpForm/>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
