import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Pricing from '../components/other/Pricing'
import CtaForm from '../components/other/CtaForm'
import Footer from './blocks/Footer';

export default class TermsOfUseClient extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Terms Of Use for Clients" />
                <div className="container plain-pricing-table">
                    <div className="privacy-policy-container">
                        <p><strong>TERMS OF USE FOR CLIENTS</strong></p>
                        <p><u><strong>1. Agreement:</strong></u></p>
                        <p>Please read these terms of use (Terms), a legal agreement between Servizkart, a brand owned by Stellular
                            Ventures Private Limited., a Company having its principal place of business at, H 1106, Maple Heights, Sushant
                            Lok 1, Gurgaon-122009, Gurgaon Haryana 122009, India (hereinafter "Company" or we or similar) of the first
                            part AND the Client (defined below) of the second part (hereinafter collectively referred to as you, yours
                            or Client). The Terms shall govern the use of and access to(the Website) and the SERVIZKART technology
                            platform accessible through desktops, mobile phones, smartphones, and tablets (the Application) which offers a
                            B2B marketplace connecting Client(s) (defined below) with Partners for outsourcing Services (defined below)
                            as may be introduced by the Company from time to time. The Company is the owner of the Website and Application
                            offered to the Client(s). The Website and the Application and all promotional, marketing, and other activities
                            associated therewith shall collectively be referred to as </p>
                        <p><u><strong>2. Definitions:</strong></u></p>
                        <p>Platform/ SERVIZKART Platform (Platform hereinafter).Please carefully go through these Terms and the
                            privacy policy available at(Privacy Policy) before you decide to access or use the Platform made available by
                            the Company.These Terms and the Privacy Policy together constitute a legal agreement (Agreement) between you
                            and the Company in connection with your access and use of the Platform.By clicking "sign up" or the 'I accept'
                            tab at the time of registration, or by entering into an agreement with the Company to offer Services as set out
                            in these Terms, or through the continued use or accessing the Platform, you agree to be subject to these Terms.
                            We request You to please read these Terms carefully and do not click Create an account, sign up or I
                            accept or continue the use of the Platform unless You agree with these Terms.</p>
                        <ol>
                            <li><strong>Bid</strong> means the proposal that the Partner submits in response to the RFQ (Request for
                                Quotation) shared by the Client on the Platform, every Bid submitted by the Partner shall be routed only
                                through the Platform.</li>

                            <li><strong>Client</strong> means anyone who signs up and registers as a customer by creating an Account
                                through the Platform and who posts Projects/Work.</li>

                            <li>"<strong>Client Information</strong>" means information regarding Clients which includes information
                                such as name and address, legal status, nature of business, company information, details of the Project,
                                etc.</li>

                            <li><strong>Confidential Information</strong> means all information and materials furnished by a party
                                which: </li>

                            <li>if in written format is marked as confidential, or</li>
                            <li>if disclosed verbally is noted as confidential at the time of disclosure, or</li>
                            <li>in the absence of either (a) or (b) is information which a reasonable party would deem to be non-public
                                information and confidential.</li>

                        </ol>
                        <p>Confidential Information includes, but not limited to the Client Information; such as details about the Project,
                            such as specific requirements, specifications, description of services mentioned in the Project; all types of
                            data, information, ideas, processes, procedures, software specifications, technical processes, product designs,
                            financial information, business plans, projections, marketing data and other similar information provided by one
                            party to the other; documentation, the existence and contents of this Agreement or agreements between
                            SERVIZKART, Client and Partner, whether such is transmitted in writing, orally, visually, or on magnetic media,
                            and includes all proprietary information, customer and prospect lists, trade secrets, or proposed trade names,
                            know-how, concepts, drawings, flow charts, diagrams and other intellectual property relating to the subject
                            matter of this Agreement.</p>
                        <ol>

                            <li> <strong>Effective Date</strong> means the date on which You accept these Terms by clicking Log in,
                                'Sign Up' or 'I Accept' or entering into an agreement with the Company.</li>

                            <li><strong>Nodal Account</strong> means bank account maintained by SERVIZKART to hold the payments payable by
                                the Client to the Partner for their Services in a Project, and from which account the Partner shall be paid
                                for the Service upon completion of the Project or as mutually agreed upon by the Client and Partner.</li>

                            <li><strong>Project or Work</strong> means the assignment for which the Client wishes to engage the Services
                                offered on the Platform through the Partners who have registered therein and which may include details such
                                as project type , category, and sub-category, duration, project value, key deliverables, milestones & payment
                                details, location (and whether the project can be completed remotely), the scope of work, a project
                                description, which is provided by a Client to the Partners so that they can Bid for the Project.</li>

                            <li><strong>Service</strong> means services offered on the Platform through Partners who specialize in
                                relevant fields required for the RFQ posted by the Clients such as Finance, Legal, Supply Chain, Real
                                Estate, Architect, Software Development, mobile, and web development, software development, IT services,
                                BPOs, KPOs, and such other services as the Company may offer from time to time.Such services are in the
                                form of Business Solutions, Instant Services or Over the Counter Services, Subscription based service fee,
                                Servizkart Buddy Fee or any other fee which may be introduced by the Company from time to time.</li>
                            <li><strong>Partner</strong> could include a complete agency itself that specializes in offering services for
                                which the Project/Work is posted or duly qualified and experienced professionals working in that agency
                                depending upon the nature and scope of the Project/Work.The Client could either hire an agency or resources
                                from the agency depending upon the requirements of the Project/Work.Such Partner can also be an independent
                                subject matter experts depending on the work nature.</li>
                            <li><strong>Partner Information</strong> means information relating to name, address, documents such as
                                certificates/licenses/registrations to verify the legal status, expertise in a specific field, copies of
                                certificates/degrees/qualification/certifications in the area of specialization and expertise, professional
                                memberships and such other information/documents that the Company may require to be submitted from time and
                                time or as the case may be, etc.</li>

                            <li><strong>Project/Work Agreement</strong> means an Agreement entered into by the Partner and Client before
                                the Partner starts to offer Services to the Client and which may be either provided by the Company or
                                furnished by the Client or Partner as per the situation.</li>

                            <li>All other capitalized terms will bear the meaning ascribed to them in the Other Terms.</li>

                        </ol>
                        <p><u><strong>3. Registration as a Client: </strong></u></p>
                        <p>
                            <li>To access and use the features available on the Platform offered by the Company and for posting proposals
                                for various Projects/Works, inviting Bids from Partners and using other Services available on the Platform
                                it is mandatory for you to accept the Terms and then create an account ("Account") by registering on the
                                Website and/or Application.You represent, warrant, and covenant that you will provide accurate and complete
                                registration information (including, but not limited to your name, company name, the user name ("User
                                Name"), corporate e-mail address, phone number, and a password you will use to access the Service) and such
                                other Client Information as may be required by the Company from time to time and to keep your registration
                                information accurate and up-to-date.Please note that failure to do so shall constitute a breach of the
                                Terms, which may result in immediate termination of your Account.</li>
                        </p>
                        <p>
                            <li>To be able to register yourself as a Client on the Website and/or Application, You represent and warrant to
                                the Company that: </li>
                        </p>
                        <p>
                            <ol>
                                <li>You are major and have the authority to enter into this Agreement; </li>
                                <li>this Agreement is binding and enforceable against you; </li>
                                <li>If you are using the Website and/or Application on behalf of any other person/s (including individuals) or
                                    entity (including bodies corporate and others), you further represent and warrant that you are authorized to
                                    accept these Terms on such entity's/person's behalf, and that such entity agrees to indemnify the Company
                                    for violation of these Terms; </li>
                                <li>you authorize and/or are authorized to transfer Fees for Services requested through the use of the Website
                                    and/or Application; </li>
                                <li>You may be required to provide your complete name, age proof, identity proof; details of your bank account,
                                    Permanent Account Number (PAN), GSTIN, residence/office proof, etc.and</li>
                                <li>Most importantly that all the information provided at the time of registration is true and verified.</li>
                            </ol>
                        </p>
                        <p>4<u><strong>.RFQ, Bid, and Project/Work Agreement</strong></u></p>
                        <ol>
                            <li>The Client will post an RFQ on the Platform providing required details of the Work.</li>

                            <li>The Company will use its unique algorithm to match the requirements of the Client as mentioned in the Work
                                with the skill sets and services mentioned by the Partners and request them to offer their Bid.</li>

                            <li>The Partners will then submit their Bid including their technical qualifications, their commercial proposal,
                                and submit the same with the Company.</li>

                            <li>The Company will then use the algorithm to process the Bids based on several variables and then provide the
                                Client with the most suitable Bids to choose from.</li>

                            <li>The client will then select the Partner they wish want to engage for the Project/Work and initiate
                                discussion and may enter into a Project/Work Agreement with the Partner.</li>

                        </ol>
                        <p><u><strong>5. Registration Fees, Project/Work Fees, Commission, Cancellation, and Refund: </strong></u></p>
                        <ol>
                            <li><strong>Registration Fees: </strong>The Company, at this stage, shall provide the Client access to register
                                on the Platform free of cost.The Company, however, reserves the right to charge you a certain fee at a
                                later date for registering on the Website and/or Application when you sign up and register as a Client or
                                for continued use of the Platform (Registration Fees).The Registration Fees payable may be fixed or may
                                vary depending upon the plans that may be introduced by the Company.</li>

                            <li><strong>Subscription Based Model: </strong>The Company may introduce the Subscription Scheme for its
                                customers.The Customers to pay the subscription fee in advance to avail the benefits.</li>

                            <li><strong>Instant Payment Modes</strong>: The Customers will have the option to avail the services as Business
                                Solutions where payment is milestone bases which is already covered and Instant Payment for OTC (Over the
                                Counter) Services where customer will have to pay in advance.</li>

                            <li><strong>Servizkart Buddy Services: </strong>The customers will have the option to avail the SERVIZKART Buddy
                                Services as per the Scheme which can be changed from time to time and can be offered as free of cost or
                                otherwise.</li>

                            <li><strong>Project/Work Fees: </strong>Once the Client approves the Bid and/or sign a Work Agreement with the
                                Partner and mutually agree upon the terms of engagement such as milestones and delivery dates, the payment
                                terms, etc.the said terms shall be uploaded on the Platform by the Partner.The Partner shall then raise an
                                Invoice (inclusive of GST) for the milestones periodically as agreed upon and approved by the Client.The
                                Partner shall upload the Invoice on the Platform.The Client shall then pay the required amount for the
                                Services that the Partner has rendered either in total or as per the amount and timeline decided for each
                                milestone (Project/Work Fees) by transferring the Project/Work Fees to the Companys Nodal Account.The
                                Project/Work Fees shall be paid to the Partner once the Project/Work or the milestone has been completed by
                                the Partner and approved by the Client.The Client shall have fourteen days to approve the request for
                                Project/Work Fees.If the Client approves the payment of the Project/Work Fees, then the payment will get
                                released from the Companys Nodal Account to the Partners account within the next seven working days.If the
                                Client doesnt approve the request within fourteen days then on the 15th day the Project/Work Fees will get
                                released from the Companys Nodal Account to the Partners bank account after deducting Commission payable
                                to the Company.If the Client disputes the payment of the Project/Work Fees then the same will not be
                                released and will be subject to the dispute resolution process adopted by the Partner and the Client.</li>

                            <li><strong>Cancellation: </strong>Once a Project/Work has commenced and You as the Client have paid the
                                Project/Work Fees in entirety or part thereof, the Project/Work cannot be cancelled.In exceptional
                                scenarios, where the Client is requesting for a cancellation/refund after having confirmed the Partner to
                                proceed with the Project/Work and having paid the Project/Work Fees then the Company may at its discretion
                                and upon alignment with the Partner choose to refund the Project/Work Fees paid by the Client after
                                deducting 10% of the Project/Work Fees as cancellation fees.The cancellation fees which may be collected
                                are paid to the Partner.The Project/Work can be cancelled if the Project/Work Fees has not been paid and
                                the work on the Project/Work has not commenced.<br /><br />
                                If the Partner requests for cancellation, then the money is refunded to the Client without any deductions and
                                the Company shall also attempt to provide the Client with a replacement Partner.The Company may take action if
                                they believe that the Partner is cancelling too many Projects/Works or cancelling the Project/Work(s) without
                                justified reasons., If the Client is not satisfied with the Service offered by the Partner as the same was
                                unsatisfactory, deficient, incomplete, or the Project/Work was delayed due to the Partners negligence then the
                                Company has the discretion to cancel the Partners account and/or refund the Consideration amount to the Client
                                and levy a penalty upon the Partner for loss of business which shall not be less than the Commission that the
                                Company would have secured on successful completion of the Project/Work.</li>

                        </ol>
                        <p>
                            <u><strong>6. Obligations of the Client</strong></u></p>
                        <ol type='a'>
                            <li>You agree that the Client Information provided by you regarding the Project/Works and details of the
                                company/entity and Your legal status, area of specialization, Project/Work requirements, etc.are true and
                                verified.As part of the registration process and at any time thereafter, You may be required to provide Us
                                with various information such as Company incorporation or registration details, and registration details
                                with the concerned authorities, and other information to prove that You are qualified to receive the
                                Services.We may verify such information or may ask You for additional information.We may also make
                                inquiries from third parties to verify the authenticity of Your information.You authorize Us to make such
                                inquiries from such third parties, and You agree to hold them and Us harmless from any claim or liability
                                arising from the request for or disclosure of such information.</li>
                            <li>You agree and undertake that you will post genuine Projects/Works on the Platform.</li>


                            <li>You also agree and undertake that You are responsible for all risks associated with submitting your
                                proposals and entering into the Partner Agreements.</li>

                            <li>You agree that when the Partner wins the Bid and completes the Project/Work as per the timelines mentioned
                                in the Bid or Project/Work Agreement then you will honor the payment of the Project/Work Fees as per the milestones and timelines mentioned therein.</li>

                            <li>You undertake that you will not bypass the Company to avoid payment of Commission to the Company by directly
                                or indirectly contacting the Partner without the prior approval of the Company.In case the Company realizes
                                that You are involved in any of the above activities, Company holds the sole discretion to blacklist You,
                                terminate your account, and charge a penalty.The Company has the right to initiate appropriate legal
                                actions to address such a breach and have the right to claim damages including a penalty amount depending
                                upon the losses caused to the Company as a result of the bypass.</li>
                            <li>All communications with the Partner including the Bid, details of the Project/Work, Project/Work Agreement,
                                the progress of the Assignment, and any other communication pertaining to the Project/Work or Services being
                                offered shall be communicated through the Website and/or Application.</li>

                            <li>You are solely responsible for ensuring that Your use of the Website and/or Application complies with
                                applicable law and is always in accordance with these Terms.</li>

                            <li>You shall keep Your information updated and will inform Us immediately should any portion of Your
                                information be revoked, is cancelled, or expires.</li>

                            <li>You agree that We may terminate Your access to or use of the Website and/or Application at any time if We
                                are unable at any time to determine or verify the Client Information provided by you at the time of or
                                post-registration.We reserve the right to carry out re-verification of the Client Information provided by
                                You as and when required, and the above rights and commitments will extend to re-verification as well.</li>

                        </ol>
                        <p><u><strong>7. The role of the Company: </strong></u></p>
                        <ol>
                            <li>Our role is that of an 'intermediary' as defined under the Information Technology Act, 2000 as amended from
                                time to time and the rules thereunder.Being an intermediary, we merely provide a marketplace to connect
                                Partners with Clients and thus have no responsibility and/or liability in respect of the content and
                                transactions being conducted on the Platform including any interactions amongst and between the Partners and
                                Client except where the Company has provided any services explicably mentioned as part of any Scheme being
                                introduced from time to time.</li>
                            <li>The Company may help the Client in selecting the best Bids submitted by Partners concerning the posting of a
                                Project/Work by the Client and selecting the most suitable partner for the Project/Work, but such help does
                                not create any liability on the Company as to the matters incidental thereto in relation to the Services
                                provided by the Partner and payments made by the parties.Company is not responsible for the dealings
                                between Client and Partner including but not limited to losses arising out of non-delivery, poor quality of
                                delivery, partial delivery, excess delivery or late delivery of agreed deliverable, partial payment,
                                deductions on payment, delayed payment, non-payment, withdrawal of Project/Work or, change of Project/Work.
                            </li>
                            <li>The company does not direct, has no control over, makes no representations; and does not guarantee the
                                quality, safety, or credibility of the Partner, the truth or accuracy of Bids submitted by the Partner, the
                                ability of the Partner to render the Services as per your satisfaction, etc.Company is not responsible if
                                the Partner decides not to continue a Project/Work post signing the Project/Work Agreement or transferring
                                the Project/Work Fees, except actions as specifically mentioned by the Company.</li>
                            <li>The company is not a party to the dealings between Clients and Partners, including Bids, Projects/Works,
                                pitches, performance of Partners, etc.Clients and Partners are independent contractors.The company is not
                                responsible for and disclaims any and all liability related to the actions of Clients and Partners except
                                where the Client has used SERVIZKART Buddy Services by paying an additional subscription fee.</li>
                            <li>The company is not liable for the Clients' statutory compliance or obligations such as payment of GST, TDS,
                                etc.</li>
                        </ol>
                        <p><u><strong>8. Grant of Rights: </strong></u></p>
                        <ol>

                            <li>This Website and/or Application is owned and operated by the Company.All the content featured or displayed
                                on this Website and/or Application, including, but not limited to, text, graphics, data, images(photographic
                                and moving), workflow, algorithms, illustrations, pitches, client lists, a summary of proposals, other
                                information incidental thereto and selection and arrangement thereof (the "SERVIZKART Content" excluding the
                                Client Information and Partner Information), is owned by the Company.</li>
                            <li>The Company owns all rights, title, and interest, including all intellectual property rights such as
                                copyright, trademarks, trade secrets, patent and other proprietary rights in and to the Website, the
                                SERVIZKART Content, and the Application, the present or future modifications/upgradations thereof and
                                standard enhancements thereto.</li>

                            <li>The Company subject to the terms of the Agreement, grants You and You accept a non-exclusive, personal,
                                non-transferable, limited right to have access to and use the Website and/or Application, SERVIZKART
                                Content, and Services offered therein for the duration you are registered with Us as a Client.</li>

                            <li>This license is non-transferable and does not permit any resale or commercial use of this Platform or
                                SERVIZKART Contents except as permitted under the Terms; any downloading or copying of account information
                                for the benefit of anyone other than Your use; or any use of data mining, robots, or similar data gathering
                                and extraction tools.</li>

                            <li>The Platform or any portion thereof (including but not limited to any copyrighted material, trademarks, or
                                other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited,
                                distributed, or otherwise exploited for any commercial purpose without express written consent of the
                                Company.Except as expressly permitted by these Terms, any, exploitation, copying, making derivative works,
                                transmitting, posting, linking, deep linking, redistribution, sale, decompilation, translation, or
                                disassembly of the Platform embodied in the Website and/or Application is strictly prohibited.Any
                                unauthorized use of the Platform shall terminate the permission or revoke the license granted by the
                                Company.</li>
                            <li>The Terms do not and shall not transfer any ownership or proprietary interest in the Website and/or
                                Application, SERVIZKART Content from the Company to You, except as may be otherwise expressly provided in
                                these Terms or as may be agreed to by and between Company and You.</li>

                            <li>The Company hereby states that the Partner Information provided on the Website and/or Application is
                                proprietary to and owned by the Partner and is provided to you under license by the Client solely to submit
                                your Bid and/or render your Services.</li>

                            <li>You are solely responsible for your conduct and any Client Information that you submit, post, or display on
                                or via the Platform.We shall have no liability for your conduct in relation to your use of the Platform.
                                Breach of these Terms may result in legal consequences prescribed under applicable laws.</li>
                            <li>We provide you the facility to create, share and post content i.e.Client Information and claim no ownership
                                rights over the Client Information created by you.We take no responsibility and assume no liability for any
                                Client Information that you create, store, share, post, or send through the Platform.You shall be solely
                                responsible for the Client Information you post/enter/publish/share and you agree that we are only acting as
                                a passive conduit for your online distribution and publication of your Client Information.</li>
                            <li>You agree that You are the owner of all rights, including all Intellectual Property Rights, title, and
                                interest in and to the Client Information that You post, share, publish on the Platform.</li>

                            <li>You hereby grant Us and Partners a perpetual, non-revocable, worldwide, royalty-free license to make use of
                                the Client Information including the right to copy, distribute, display, reproduce, modify, adapt, the
                                Client Information, and create derivate works of as the case may be.</li>

                            <li>You affirm, represent, and warrant that the Client Information shared by you will not violate any law or
                                infringe any rights of any third party, including but not limited to any Intellectual Property Rights or
                                privacy rights.</li>
                        </ol>
                        <p><u><strong>9. Use of the Website and/or Application: </strong></u></p>
                        <ol>

                            <li>You may use the Platform to create your account and access the various features and seek the Services
                                available therein.</li>

                            <li>You may use the Platform for sending notifications and updates regarding projects/Works you would like to
                                post.</li>

                            <li>Once you have approved the Bid and finalized the Partner then You may use the Platform to sign the Partner
                                Agreement.</li>

                            <li>You may use the Platform to communicate with the Partner with respect to the Project/Works, analyze and
                                review the progress of the Project/Work, get alerts from Partner, keep track of the Project/Work schedule,
                                etc.</li>

                            <li>You may use the Platform to transfer the Project/Work Fees into the Companys Nodal Account for Services
                                offered to the Clients in relation to the Project/Work.</li>

                            <li>You will not use the Platform or any feature provided therein for any purposes not intended under the Terms.
                            </li>

                            <li>You will not permit any third party to have access to or use the Platform.</li>

                            <li>You shall not</li>
                            <li>use the Website and/or Application for time-sharing, rental, or service bureau purposes; </li>
                            <li>make the Website and/or Application, in whole or in part, available to any other person, entity, or
                                business; </li>
                            <li>modify the contents of the Website and/or Application or use such content for any commercial purpose, or any
                                public display, performance, sale, or rental other than envisaged in the Agreement; </li>
                            <li>copy, reverse engineer, decompile or disassemble the software embedded in the Website and/or Application; or
                            </li>
                            <li>modify the software or combine the software in the Website and/or Application with any other software or
                                services not provided or approved by Us.</li>

                            <li>You will not intentionally use the Website and/or Application in any way that is unlawful or harms Us, our
                                directors, employees, affiliates, distributors, partners, and/or any Partner and/or data or content on the
                                Website and/or Application.</li>

                            <li>You undertake that </li>
                            <li>You will not delete or modify any content on the Website/Application including but not limited to, legal
                                notices, disclaimers, or proprietary notices such as copyright or trademark symbols, logos, that You do not
                                own or have express permission to modify; </li>
                            <li>You will not decompile, reverse engineer, or disassemble the software embedded in the Website and/or
                                Application, or You will not remove any copyright, trademark registration, or other proprietary notices from
                                the Website and/or Application.You further agree not to access or use this Website or Application in any
                                manner that may be harmful to its operation or its content; </li>
                            <li>You will not use the Website and/or Application and/or services in any unlawful way, or harms the Company or
                                any other person or entity, as determined in the Company's sole discretion;</li>
                            <li>You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming",
                                "spamming", "flooding", "trolling", "phishing" and "grieving" as those terms are commonly understood and
                                used on the Internet and</li>
                            <li>You will not host, display, upload, modify, publish, transmit, update or share any information that belongs
                                to another person and to which the user does not have any right to; is grossly harmful, harassing,
                                blasphemous defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy,
                                hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or
                                gambling, or otherwise unlawful in any manner whatever; harm minors in any way; infringes any patent,
                                trademark, copyright or other proprietary rights(s), violates any law for the time being in force; deceives
                                or misleads the addressee about the origin of such messages or communicates any information which is grossly
                                offensive or menacing in nature; impersonate another person; contains software viruses or any other computer
                                code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;
                                threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign
                                states, or public order or causes incitement to the commission of any cognizable offence or prevents
                                investigation of any offence or is insulting any other nation.</li>

                            <li>You will immediately notify Us, without any unreasonable delay, of any breach or suspected breach of the
                                security of the Website and/or Application of which You become aware, or any unauthorized use or disclosure
                                of information within or obtained from the Website and/or Application, and You will take such action to
                                mitigate the breach or suspected breach as We may direct, and will cooperate with Us in investigating and
                                mitigating such breach.</li>

                        </ol>

                        <p><u><strong>10. Confidential Information of Company and Partners: </strong></u></p>
                        <p>
                            <li>You will treat all information received from Company and the Partners on the Website and/or Application as
                                confidential.You may not disclose such Confidential Information to any other person, and You may not use
                                any Confidential Information except as provided herein.Except as otherwise provided in Terms, You may not,
                                at any time, during or after the applicability of these Terms, directly or indirectly, divulge or disclose
                                Confidential Information for any purpose or use Confidential Information for Your benefit or the purposes or
                                benefit of any other person.You agree to hold all Confidential Information in strict confidence and to take
                                all measures necessary to prevent unauthorized copying, use, or disclosure of Confidential Information, and
                                to keep the Confidential Information from being disclosed into the public domain or into the possession of
                                persons not bound to maintain confidentiality.You will disclose Confidential Information only to your
                                employees, agents, or contractors who need to use it for the purposes permitted under the Terms only.You
                                will inform all such recipients of the confidential nature of Confidential Information and will instruct
                                them to deal with Confidential Information in accordance with these Terms.</li>
                            <li>You will promptly notify Company in writing of any improper disclosure, misappropriation, or misuse of the
                                Confidential Information by any person, which may come to Your attention.</li>
                        </p>
                        <p>
                            <li>You agree that We or the Partners will suffer irreparable harm if You fail to comply with the obligations
                                outlined in this Section, and You further agree that monetary damages will be inadequate to compensate Us or
                                the Partners for any such breach.Accordingly, You agree that We and the Partners will, in addition to any
                                other remedies available to Us and Partners at law or in equity, be entitled to seek injunctive relief to
                                enforce the provisions hereof, immediately and without the necessity of posting a bond.</li>
                            <li>This Section will survive the termination or expiration of these Terms of Agreement for any reason.</li>
                        </p>
                        <p><u><strong>11. Disclaimer and Exclusion of Warranties: </strong></u></p>
                        <ol>
                            <li>You agree that Our role is limited to</li>
                            <li>providing a platform for the Clients to connect with Partners,</li>
                            <li>providing a platform for Clients to receive information and updates regarding various Projects/Works posted
                                by Partners,</li>
                            <li>allowing the Clients to directly post Project/Works for which the Partners can bid</li>
                            <li>allowing the Partner and the Client to sign the Project/Work Agreement upon winning the Bid if required,
                            </li>
                            <li>enable the Partners and Clients to be updated about and keep track of the Project/Works,</li>
                            <li>providing a platform to Clients to communicate with Partners on all aspects of the Project/Work, and</li>
                            <li>providing a platform for facilitating collection and disbursal of Platform Fees.Accordingly, the Company is
                                merely an intermediary providing online marketplace service and is only a Platform connecting Client with
                                Partners who shall offer Services to the Client on the execution of the Partner Agreement.The Partner
                                Agreement for availing the Service shall be a contract solely between You and the Partner.At no time shall
                                SERVIZKART have any obligations or liabilities in respect of such contract unless provided otherwise
                                specified by the Company.</li>
                            <li>We are not responsible for the accuracy or completeness of information available from or through the
                                Platform.You assume full risk and responsibility for the use of the information You obtain from or through
                                the Platform, and You agree that we would neither be responsible nor liable for any claim, loss, or
                                liability arising from the use of the information.</li>
                            <li>Any interactions between You and Partners or any third parties on the Platform or outside the Platform are
                                strictly between You and the Partners or third parties.You shall not hold SERVIZKART responsible for any
                                interactions and associated issues.</li>

                            <li>The website, the application, the platform and the services offered and the information contained therein
                                are provided on an "as is" and "as available" basis without any warranty of any kind, expressed or implied,
                                including but not limited to the implied warranties of merchantability, fitness for a particular purpose,
                                and non-infringement.You are solely responsible for any and all acts or omissions taken or made in reliance
                                on the website, application, or the information therein, including inaccurate or incomplete information.You
                                further agree that neither we nor any of our affiliates will be liable for any harm, which lawyers and
                                courts often call direct, indirect, remote, punitive, incidental, special, consequential, or exemplary
                                damages, including, but not limited to, damages for loss of profits, goodwill, data or other intangible
                                losses, arising out of or in connection with the platform, even if we have been advised of the possibility
                                of such damages, resulting from: </li>

                            <li>The use or the inability to use the platform; </li>
                            <li>Any products, data, information including ServizKart information or partner information obtained or services
                                offered or messages received or transactions entered into, through or from the platform; </li>
                            <li>Unauthorized access to or alteration of your transmissions or data; </li>
                            <li>Statements or conduct of anyone on the service; </li>
                            <li>Malicious or criminal behaviour, or false or fraudulent transactions or</li>
                            <li>Technical or other operational lapses on the platform or</li>
                            <li>Any other matter relating to the service offered on the platform.</li>

                            <li>You acknowledge that partners have access to the website and application.Such partners have committed to
                                comply with terms set out with them and our policies and procedures concerning the use of the application
                                and website; however, the actions of such partners are beyond our control.Accordingly, we do not assume any
                                liability for or relating to any impairment of the privacy, security, confidentiality, integrity,
                                availability, or restricted use of any information on the website and/or application resulting from any
                                partner's actions or failures to act.</li>
                            <li>We expressly disclaim any liability for the consequences to you arising because of your use of the website,
                                application, or receiving the services.We expressly disclaim any liability for any incorrect information
                                provided to you by any partner and for any failure of the partner to complete the project/work after
                                receiving payment.</li>
                            <li>While it is our objective to make the website and application accessible at all times, the websites and/or
                                applications may be unavailable from time to time for any reason including, without limitation, routine
                                maintenance.In addition, various portions of the website or application may operate slowly from time to
                                time.You understand and acknowledge that due to circumstances both within and outside our control, access
                                to the website and/or the application may be interrupted, suspended, or terminated from time to time.In
                                particular, and not in limitation of the foregoing, Company shall not be liable in any way for any loss of
                                business or any damages arising from any such interruption, suspension, or termination of the website and/or
                                application.</li>
                            <li>We do not warrant that your use of the website, application, and services under these terms will not violate
                                any law or regulation applicable to you.</li>

                        </ol>
                        <p><u><strong>12. Limitation of Liability: </strong></u></p>

                        <p>Notwithstanding the above, in the event company should have any liability to you or any third party for any loss,
                            harm, or damage, you and the company agree that such liability shall under no circumstances exceed 5% of the
                            value of any project/work fees received by the company from you in the preceding twelve months in connection
                            with use of the website and application or INR 500 whichever is lower.You and Company agree that the foregoing
                            limitation of liability is an agreed allocation of risk between you and the company.You acknowledge that
                            without your assent to this section, the company would not provide access to the website, application, and
                            services, to you.</p>
                        <p><u><strong>13. Indemnification: </strong></u></p>

                        <p>You agree to defend, indemnify and hold harmless the company, and its officers, managers, members, directors,
                            employees, successors, assigns, subsidiaries, affiliates, partners, suppliers, and agents, from and against any
                            and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not
                            limited to attorneys' fees) arising from your use of, access to, and participation in the website; your
                            violation of any provision of the terms of use, including the privacy policy; your violation of any third party
                            right, including without limitation any copyright, property, proprietary, intellectual property, or privacy
                            right; or any claim that your submitted content caused damage to a third party or infringed any third party
                            intellectual property.This defense and indemnification obligation will survive these terms of use and your use
                            of the website and/or application.If you have a dispute with one or more clients or partners, you forever
                            release the company (and its officers, managers, members, directors, employees, successors, assigns,
                            subsidiaries, affiliates, suppliers, agents, subsidiaries, and employees) from any and all claims, demands, and
                            damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way
                            connected with your use of the website and/or application and/or any submitted client information.</p>

                        <p><u><strong>14. Termination /Modification/Suspension: </strong></u></p>
                        <p>
                            <ol type='a'>
                                <li>The Company may at any time, terminate its legal agreement with you if you have breached any provision of
                                    the terms (or have acted in a manner which clearly shows that you do not intend to, or are unable to comply
                                    with the provisions of the terms).</li>

                                <li>You may terminate this agreement with the Company by deleting your Account from the Website or the
                                    Application.</li>

                                <li>We may update or change the Website and/or Application or the Terms and/ or levy platform fee for
                                    registering on this Application or Website (Platform Fee) from time to time and recommend that You
                                    review these Terms regularly.You understand and agree that Your continued use of the Website and/or
                                    Application after the Terms have been updated or changed constitutes Your acceptance of the revised
                                    Terms.</li>

                                <li>Notwithstanding anything to the contrary in these Terms, We have the right, on providing notice to You,
                                    immediately to terminate, suspend, or amend your use of the Website and/or Application without
                                    liability: </li>

                                <li>to comply with any order issued or proposed to be issued by any governmental agency; </li>
                                <li>to comply with any provision of law; or</li>
                                <li>if performance of any term of these Terms by either Party would cause it to violate the law.</li>

                                <li>We may suspend Services immediately pending Your cure of any breach of these Terms, or in the event, We
                                    determine in Our sole discretion that accesses to or use of the Website and/or Application by You may
                                    jeopardize the Website and/or Application or the confidentiality, privacy, security, integrity or
                                    availability of information within the Website and/or Application or that You have violated or may
                                    violate these Terms, or have jeopardized or may jeopardize the rights of any third party, or that any
                                    person is or may be making unauthorized use of the Website and/or Application with any User Name
                                    assigned to You.Our election to suspend the Services shall not waive or affect companys rights to
                                    terminate these Terms as applicable to You as permitted under these Terms.</li>

                                <li>Upon termination of your Account, your right to participate in the Website and/or Application,
                                    including, but not limited to, your right to post Project/Works or receive Bids from Partners to offer
                                    their Services and your right to pay any Project/Work Fees, shall automatically terminate.You
                                    acknowledge and agree that your right to post proposals for Project/Works or seek Bids hereunder is
                                    conditional upon your proper use of the Website and/or Application, your adherence to the Terms of Use,
                                    the continuous activation of your Account, and your permitted participation in the Website and/or
                                    Application.In the event of termination, your Account will be terminated, you may not be granted access
                                    to your Account or any files or other data contained in your Account.Notwithstanding the foregoing,
                                    residual data may remain in the Company system.Upon Termination, the following shall occur: all
                                    licenses granted to you hereunder will immediately terminate; and you shall promptly destroy all copies
                                    of Company, Intellectual Property, Website and/or Application, and other content in your possession or
                                    control.You further acknowledge and agree that Company shall not be liable to you or any third party
                                    for any termination of your access to the Website/Application.Upon Termination, Company retains the
                                    right to use any data collected from your use of the Website or Application for internal analysis and
                                    archival purposes, and all related licenses you have granted the Company hereunder shall remain in
                                    effect for the foregoing purpose.You agree to indemnify and hold the Company, and its officers,
                                    managers, members, affiliates, successor, assigns, directors, agents, suppliers, and employees harmless
                                    from any claim or demand, including reasonable attorneys' fees and court costs, made by any third party
                                    due to or arising out of the Termination of Terms of Use.</li>
                            </ol>
                        </p>
                        <p><u><strong>15. Governing Law and Jurisdiction</strong></u>: </p>
                        <p>The interpretation of this Agreement and the resolution of any disputes arising under this Agreement shall be
                            governed by the laws of India and the courts at Gurugram, Haryana, subject to clause 16 below, shall have
                            jurisdiction.</p>
                        <p><u><strong>16. Arbitration: </strong></u></p>
                        <p>
                            <li>Any dispute, claim or controversy arising out of or relating to this Agreement or the breach, termination,
                                enforcement, interpretation, or validity thereof, including the determination of the scope or applicability
                                of this Agreement to arbitrate, or to Your use of the Website and/or Application or its features or the
                                information to which it gives access, shall be determined by Arbitration in India, and referred to the sole
                                arbitrator to be appointed by the company (i.e.SERVIZKART) in accordance with the Arbitration and
                                Conciliation Act 1996 or any modifications or amendments thereof for the time being in force.The venue of
                                such arbitration shall be Gurugram, Haryana India.The governing law of the Agreement shall be the
                                substantive law of India.All proceedings of such arbitration, including, without limitation, any awards,
                                shall be in the English language.The award shall be final and binding on the Parties.</li>
                            <li>The Parties shall have the right to apply to a court of competent jurisdiction to obtain interim injunctive
                                relief in respect of any dispute, pending resolution of such dispute in accordance with the Agreement.</li>
                        </p>
                        <p><u><strong>17. Disputes between Clients and Partners: </strong></u></p>
                        <p>
                            <li>Subject to the provisions regarding disputes between You and Partners in connection with the offering and
                                receiving Services, Briefs, Bid, Project/Work, including payment of Project/Work Fees and performance of any
                                Service, and any other terms, conditions, warranties, or representations associated with such transactions
                                or dealings, are solely between you and the Partner.You should do reasonable due diligence you deem
                                necessary before accepting the Bid submitted by a Partner or entering into any transaction with the Partner
                                or any third party.Please note that that deciding whether to receive Services from a Partner or accepting a
                                Bid for a Project/Work is your personal decision for which you alone are responsible.You understand that
                                the Company does not warrant and cannot make representations as to the suitability of any Partner, their
                                credibility, quality of their Service, You may decide to interact with on or through the Website and/or
                                Application, the accuracy of the information they post on the Website and/or Application.While the Company
                                may attempt to seek information about the background of a Partner you understand that Partners may register
                                themselves suo moto.Please also note that any so-called background check undertaken by Company is not
                                exhaustive, at the end of the day, You should make an informed decision on your own accord and keep in mind
                                the fact that Company only seeks to provide a platform wherein You and Partners have an opportunity to meet
                                each other.</li>
                            <li>Notwithstanding the foregoing, You agree that since Company only seeks to provide a platform wherein You and
                                Partners can be brought together and Company itself does not have any role in the execution or provision of
                                Services itself, Company shall not be responsible or liable for any loss or damage of any sort whatsoever
                                incurred as the result of any such transaction or dealings.</li>
                        </p>
                        <p>
                            <ol>
                                <li>If there is a dispute between Client and Partner, you acknowledge and agree that Company is under no
                                    obligation to become involved.However, the Company may offer assistance in the dispute resolution process,
                                    as may be ordered by the Court.</li>
                                <li>If a dispute arises between you and a Partner, you hereby release the Company, its officers, managers,
                                    members, directors, employees, attorneys, agents, and successors in rights from any claims, demands, and
                                    damages (actual and consequential) of every kind or nature, known or unknown, suspected or unsuspected,
                                    foreseeable or unforeseeable, disclosed or undisclosed, arising out of or in any way related to such
                                    disputes and/or use of the Website, Application or any Service offered by a Partner thereunder.</li>
                            </ol>
                        </p>
                        <p><u><strong>18. Copyright Infringement Take Down Procedure</strong></u>
                            The Company has high respect for intellectual property and expects the same from its users.The company may, in
                            appropriate circumstances and at its discretion, terminate the Account or prohibit access to the Website and/or
                            application to Clients who infringe upon the intellectual property rights of others.If you believe that your
                            work has been copied and posted on the Website and/or Application in a way that constitutes copyright
                            infringement and/or trademark infringement, please send the following information to us
                            at<strong>connect @SERVIZKART.com</strong><br />
                            <li>identification of the copyrighted and/or trademarked work claimed to have been infringed, or, if multiple
                                works at a single online site are covered by a single notification, a representative list of such works at
                                that site; </li>
                            <li>identification of the material that is claimed to be infringing or to be the subject of infringing activity
                                and that is to be removed or access to which is to be disabled at the Website and/or Application, and
                                information reasonably sufficient to permit Company to locate the material; </li>
                            <li>a written statement stating that you have a good faith belief that the disputed use is not authorized by the
                                copyright and/or trademark owner, its agent, or the law; </li>
                            <li>information reasonably sufficient to permit Company to contact you as the complaining party, such as an
                                address, telephone number, and, if available, an electronic mail address at which you may be contacted; </li>
                            <li>an electronic or physical signature of the person authorized to act on behalf of the owner of an exclusive
                                interest that is allegedly infringed; and</li>
                            <li>a statement by you, made under penalty of perjury, that the information in your report is accurate and that
                                you are the owner of the exclusive right or authorized to act on the behalf of the owner of the exclusive
                                right.A statement by you comprised of the foregoing points is referred to herein as the "Notice."</li>
                            Only the owner of the intellectual property rights is permitted to report potentially infringing items through
                            Company's reporting system set forth above. If you are not the intellectual property rights owner, you should
                            contact the intellectual property rights owner and they can choose whether to use the procedures outlined in
                            these Terms of Use.
                        </p>
                        <p><u><strong>19. Grievance officer: </strong></u></p>
                        <p>In accordance with Information Technology Act 2008 and rules made thereunder, the name and contact details of the
                            Grievance Officer is as follows: <br /><strong>Attention: </strong>Grievance Officer</p>
                        <p><strong>Email Id: </strong>asingh @SERVIZKART.com</p>
                        <p><u><strong>20. Miscellaneous Provisions: </strong></u></p>

                        <ol type='a'>
                            <li><strong>Severability: </strong>If any provision of this Agreement is held by a court of competent
                                jurisdiction or arbitral tribunal to be unenforceable under applicable law, then such provision shall be
                                excluded from the Agreement, and the remainder of the Agreement shall be interpreted as if such provision
                                were so excluded and shall be enforceable in accordance with its terms; provided however that, in such
                                event, the Agreement shall be interpreted to give effect, to the greatest extent consistent with and
                                permitted by applicable law, to the meaning and intention of the excluded provision as determined by such
                                court of competent jurisdiction or arbitral tribunal.</li>


                            <li><strong>Notices: </strong>All notices and other communications required or permitted hereunder to be given
                                to a Party shall be in writing, in the English language, and shall be sent by facsimile, e-mail, or mailed
                                by prepaid nationally-recognized courier, or otherwise delivered by hand or by messenger, addressed to such
                                Party's address as set forth above.</li>

                            <li><strong>Waiver: </strong>No term of the Agreement shall be deemed waived and no breach excused unless such
                                waiver or consent shall be in writing and signed by the Party claimed to have waived or consented.Any
                                consent by any Party to, or waiver of a breach by the other, whether expressed or implied, shall not
                                constitute consent to, waiver of, or excuse for any other different or subsequent breach.</li>

                            <li><strong>Force Majeure: </strong>This agreement is subject to force majeure.Neither party's failure to
                                perform shall be deemed a breach or default hereunder or shall give rise to the other party when failure is
                                attributable to or caused by war, revolution or other civil disorder, strikes or labour disturbance,
                                allocation prohibition or restriction on import or export orders or acts of any government instrumentality,
                                fires, floods, acts of god, or any cause of a similar or dissimilar nature beyond the reasonable control of
                                party whose performance is prevented or hindered hereby</li>

                            <li><strong>Assignment: </strong>You may not assign or sub-license, without the prior written consent of the
                                Company, the rights, duties, or obligations under this Agreement, in whole or in part, to any person or
                                entity.</li>

                            <li><strong>Conflict: </strong>In the event there is any conflict between the terms set out in these Terms, the
                                Privacy Policy, and any other policies applicable to specific pages of the Website, the following order of
                                prevalence shall apply: </li>
                            <li>the Privacy Policy</li>
                            <li>these Terms, and</li>
                            <li>any other policies applicable to specific pages of the Website.</li>

                            <li>The Terms contain the entire understanding of the Parties, and there are no other written or oral
                                understandings or promises between the Parties with respect to the subject matter of the Terms of Use other
                                than those contained or referenced in the Terms of Use.</li>
                        </ol>

                        <p>These Terms were last updated on: <strong>21<sup>s</sup><sup>t</sup>August, 2021</strong>
                            <strong>You may reach out to us at </strong><u><strong>connect @servizkart.com </strong></u><strong>for any query
                                or clarifications.</strong>
                        </p>
                    </div>

                </div>

                <Footer />
            </>
        )
    }
}
