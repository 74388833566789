import React from 'react'
import { Link } from 'react-router-dom'

export default function BreadcrumbSmall({ title }) {
    return (
        <section className="breadcrumb-wrapper small text-center">
            <div className="container">
                <div className="breadcrumb-content">
                    <ul className="breadcrumb small">
                        <li><Link to="/">Home</Link></li>
                        <li>{title}</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

