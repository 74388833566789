import React, { Component } from 'react'
import TeamMemberDetails from "./team/details";
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Footer from './blocks/Footer';
import * as apiClient from "../components/network/apiClient";
import queryString, { parse } from "query-string";


export default class AdvisoryMember extends Component {

    constructor(props) {
       // console.log(props);
        super(props);
        const parsed = queryString.parse(props.location.search);
        const  id  = (parsed.id) ? parsed.id: 0;
        this.state = {
            memberId: id,
            member: null,
        };
    }

    componentDidMount() {
        apiClient.getAdvisoryPanelByID(this.state.memberId).then(res => {
           // console.log(res);
            this.setState({ member: res });
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        return (
            < >
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Team Details" />
                {this.state.member && <TeamMemberDetails member={this.state.member} />}
                <Footer />

            </>
        )
    }
}
