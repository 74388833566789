import React, { Component } from 'react';
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import AdvisoryMemberDetail from '../components/team/advisory-member-detail';
import Divider from '../components/other/Divider';
import CtaForm from '../components/other/CtaForm';
import Footer from './blocks/Footer';
import * as apiClient from "../components/network/apiClient";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import queryString, { parse } from "query-string";
import { ToastContainer, toast } from 'react-toastify';

export default class VerifyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountVerified: false,
            invalidEmailorToken: false,
        };
    }

    verifyAccount = (email, token) => {
        apiClient.verifyAccount(email, token).then(res => {
           // console.log(res);
            if (res[0].ReturnType == 1) {
                this.setState({ accountVerified: true });
            } else {
                this.setState({ invalidEmailorToken: true });
            }

        }).catch(err => {
            console.log("Error : " + err);
        });
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        const email = (parsed.email) ? parsed.email : "";
        const token = (parsed.token) ? parsed.token : "";
        if (email == "" || token == "") {
            this.setState({
                invalidEmailorToken: true,
            });
        } else {
            this.verifyAccount(email, token);
        }
    }
    render() {
        const { accountVerified, fullName, email, mobile,
            subject, message, requirementSubmitted,
            invalidEmailorToken } = this.state;
        return (
            <div className="teamgrid-page">
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Profile Builder" />
                <div className="member-parent">
                    <h1 className="account-verify">Account Verification</h1>
                    { invalidEmailorToken && <div className="invalid-details">
                        The Email or token invalid.
                    </div> }
                    { !invalidEmailorToken && accountVerified && <div className="invalid-details">
                        Thanks for verifying the email. Please set the profile details below.
                        Please <a href="http://admin.servizkart.com/#/login">login</a>  on serviceKart and complete your profile.
                    </div> }
                    {/* {<div className="profile-form">
                        <form>
                            <div className="form-grid d-grid">
                                <div className="left">
                                    <label htmlFor="name">Your Name <sup>*</sup></label>
                                    <input type="text" id="name" placeholder="Full Name"
                                        value={fullName}
                                        onChange={(event) => {
                                            this.setState({ fullName: event.target.value });
                                        }}
                                        required />
                                </div>
                                <div className="right">
                                    <label htmlFor="email">Your Email <sup>*</sup></label>
                                    <input type="email" id="email" placeholder="Email Address"
                                        value={email}
                                        onChange={(event) => {
                                            this.setState({ email: event.target.value });
                                        }}
                                        required />
                                </div>
                                <div className="left">
                                    <label htmlFor="number">Your Number <sup>*</sup></label>
                                    <input type="text" id="number" placeholder="Your Phone"
                                        value={mobile}
                                        onChange={(event) => {
                                            this.setState({ mobile: event.target.value });
                                        }}
                                        required />
                                </div>
                                <div className="right">
                                    <label htmlFor="subject">Subject <sup>*</sup></label>
                                    <input type="text" id="subject" placeholder="Subject"
                                        value={subject}
                                        onChange={(event) => {
                                            this.setState({ subject: event.target.value });
                                        }}
                                        required />
                                </div>
                            </div>
                            <div className="form-textarea">
                                <label htmlFor="message">Your Message <sup>*</sup></label>
                                <textarea id="message"
                                    value={message}
                                    onChange={(event) => {
                                        this.setState({ message: event.target.value });
                                    }}
                                    placeholder="Write Message"></textarea>
                            </div>
                            <button type="submit" className="theme-button"
                                onClick={() => {
                                    this.addServiceRequirement();
                                }}
                            >Send Us Message <FiChevronRight className="icon" /></button>
                        </form>
                    </div>} */}

                </div>
                <Footer />
            </div>
        )
    }
}
