import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Pricing from '../components/other/Pricing'
import CtaForm from '../components/other/CtaForm'
import Footer from './blocks/Footer';

export default class PricingPage extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Our Pricing." />
                <div className="container plain-pricing-table">
                    {/* <Pricing /> */}
                    <div className="pricing-description">
                        <b>Subscription Based Model</b> – the SVPL will have a monthly or annual subscription and
                        certain features and discounts will be available. Online management
                        </div>
                    <div className="pricing-description">
                        <b>Retainer ship Model</b> – Offline pricing however payment etc through online model as
                        well and amount to reflect online.
                        </div>
                    <div className="pricing-description">
                        <b>Instant Payment Modes</b> – Standard rates for certain services which to be paid upfront
                        and service can be delivered with assigned TAT.
                        </div>
                    <div className="pricing-description">
                        <b>Service Provider Registration Fee and Annual Fee</b> – Option to activate as and when
                        decided
                        </div>
                    <div className="pricing-description">
                        <b>Commission on Service Delivery,</b> – Option of defining the commission as per categorywise. Service provider wallet to show the transaction and net amount to be paid.
                        </div>
                    <div className="pricing-description">
                        <b>Advertisement Fee</b> – Sale of space etc.
                        </div>
                    <div className="pricing-description">
                        <b>Lead bid </b>– In option 2 above – lead to be sold in option 2. The service provider to charge
                        the wallet to get the leads as per the pricing policy after getting certain leads free.
                        </div>
                </div>
                {/* <div className="form-white-bg">
                    <CtaForm />
                </div> */}
                <Footer />
            </>
        )
    }
}
