import React, { Component } from 'react'
// import ServiceCategory from './widgets/ServiceCategory'
// import ServiceContact from './widgets/ServiceContact'
// import ServiceBroucher from './widgets/ServiceBroucher'
// import ServiceSocial from './widgets/ServiceSocial'
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import * as apiClient from "../../components/network/apiClient";


export default class ServiceSidebar extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }
    render() {
        const { categories } = this.props;
        return (
            <aside className="service-sidebar">
                <div className="service-widgets widget_categories">
                    <ul>
                        {categories && categories.map((item, index) => {
                            return (
                                <li key={index} className={item.selected ? "cat-link selected" : "cat-link"}>
                                    <Link to="#" onClick={() => {
                                        this.props.onCategorySelect(item);
                                    }}>
                                        {item.serviceName} 
                                        <FiChevronRight className="icon" onClick={() => {
                                        }} />
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </aside>
        )
    }
}
export class ServiceSidebarIcon extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }
    render() {
        const { categories } = this.props;
        return (
            <aside className="service-sidebar service-sidebar-icon">
                <div className="service-widgets widget_categories">
                    <ul>
                        {categories && categories.map((item, index) => {
                            return (
                                <li key={index} className={item.selected ? "cat-link selected" : "cat-link"}>
                                    <Link to="#" onClick={() => {
                                        this.props.onCategorySelect(item);
                                    }}>
                                        <img src={item.logo} className="category-icon" />
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </aside>
        )
    }
}
