import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import { FiChevronRight } from 'react-icons/fi';
import * as apiClient from "../network/apiClient";
import { HashLink } from 'react-router-hash-link';

export default class owlcarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: [],
        };
    }
    getContentPageConfig = () => {
        apiClient.getContentPageConfig("TopBanner").then(res => {
            // console.log(res);
            let slides = res.array.map(item => {
                return {
                    title: "",
                    description: "Redefining Outsourcing  <br/>Across Locations, Industries, & Functions",
                    button1: 'Get In Touch',
                    button2: 'Learn More',
                    bg: 'bg-1',
                    col: '9 offset-2 text-center'
                }
            })
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    componentDidMount() {
        //this.getContentPageConfig();
    }
    sliders = {
        items: [
            
            {
                title: 'SERVIZKART',
                description: 'Redefining Outsourcing  <br/><span>Across Locations, Industries & Functions</span>',
                button1: 'Get In Touch',
                button2: 'Learn More',
                bg: 'bg-4',
                col: '9 offset-2 text-center'
            },
            {
                title: 'SERVIZKART',
                description: 'Redefining Outsourcing  <br/><span>Across Locations, Industries & Functions</span>',
                button1: 'Get In Touch',
                button2: 'Learn More',
                bg: 'bg-12',
                col: '9 offset-2 text-center'
            },
            {
                title: 'SERVIZKART',
                description: 'Redefining Outsourcing  <br/><span>Across Locations, Industries & Functions</span>',
                button1: 'Get In Touch',
                button2: 'Learn More',
                bg: 'bg-5',
                col: '9 offset-2 text-center'
            },
            {
                title: 'SERVIZKART',
                description: 'Redefining Outsourcing  <br/><span>Across Locations, Industries & Functions</span>',
                button1: 'Get In Touch',
                button2: 'Learn More',
                bg: 'bg-13',
                col: '9 offset-2 text-center'
            },
            {
                title: 'SERVIZKART',
                description: 'Redefining Outsourcing  <br/><span>Across Locations, Industries & Functions</span>',
                button1: 'Get In Touch',
                button2: 'Learn More',
                bg: 'bg-14',
                col: '9 offset-2 text-center'
            }
        ]
    }
    render() {
        return (
            <section className="hero-slider-wrapper">
                <OwlCarousel className="owl-theme" loop={ true } autoplay={ true } animateOut='fadeOut' margin={ 0 } dots={ false } nav={ false } items={ 1 } >
                    { this.sliders.items.map((item, index) => {
                        return <div key={ index } className={ 'hero-slider-item ' + item.bg }>
                            <div className="container">
                                <div className="row">
                                    <div className={ 'col-' + item.col }>
                                        <div className="hero-slider-content">
                                            <h1>{ item.title }</h1>
                                            <p dangerouslySetInnerHTML={ { __html: item.description } }></p>
                                            <div className="hero-slider-btns">
                                                {/* <a href="/contact#contactform" className="theme-button">{ item.button1 } <FiChevronRight className="icon" /></a> */}
                                                 <HashLink to="/contact#contactform" className="theme-button">{item.button2} <FiChevronRight className="icon" /></HashLink> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) }

                </OwlCarousel>
            </section >
        )
    }
}
