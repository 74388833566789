import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FooterLogo from '../../assets/images/logo_secondary.png';
import { FiPhone, FiChevronRight, FiHeart } from 'react-icons/fi'
import { FaEnvelope, FaMapMarkerAlt, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import ScrollTopBtn from '../layouts/ScrollTopBtn'

export default class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {
            aboutUs : "",
        };
    }

    
    render() {
        const {aboutUs} = this.state;
        return (
            <>
                <ScrollTopBtn />
                <footer className="footer-wrapper">
                    <div className="container">
                        <div className="footer-widgets-grid">
                            <div className="footer-widget footer-left">
                                <Link to="/">
                                    <img src={FooterLogo} alt="Footer Logo" />
                                </Link>
                                <p className="desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis consectetur auctor elit.
                            </p>
                                {/* <ul className="footer-address">
                                    <li>
                                        <a href="tel:+1224234948"><span className="icon"><FiPhone /></span> +122 423 4948</a>
                                    </li>
                                    <li>
                                        <a href="mailto:support@Servizkart.com"><span className="icon"><FaEnvelope /></span> Support@Servizkart.com</a>
                                    </li>
                                    <li>
                                        <a href="https://maps.google.com"><span className="icon"><FaMapMarkerAlt /></span> H 1106, Maple Heights, Sushant Lok 1, Gurgaon</a>
                                    </li>
                                </ul> */}
                                {/* <div className="social">
                                    <h3>Follow & Connect On.</h3>
                                    <ul className="footer-social-connect">
                                        <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                        <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                        <li><a href="https://instagram.com"><FaInstagram /></a></li>
                                        <li><a href="https://linkedin.com"><FaLinkedinIn /></a></li>
                                        <li><a href="https://youtube.com"><FaYoutube /></a></li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="footer-widget footer-help-links">
                                <h2>Need Help?</h2>
                                <ul className="help-links">
                                    <li> <Link to="/about">About Us</Link></li>
                                    <li><a href="/">Our Advisors</a></li>
                                    <li><a href="/">Jobs</a></li>
                                    <li><a href="/">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="footer-widget footer-address">
                                <h2>Contact Us</h2>
                                <ul className="footer-address">
                                    <li>
                                        <a href="tel:+1224234948"><span className="icon"><FiPhone /></span> +122 423 4948</a>
                                    </li>
                                    <li>
                                        <a href="mailto:support@Servizkart.com"><span className="icon"><FaEnvelope /></span> Support@Servizkart.com</a>
                                    </li>
                                    <li>
                                        <a href="https://maps.google.com"><span className="icon"><FaMapMarkerAlt /></span> H 1106, Maple Heights, Sushant Lok 1, Gurgaon</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="social">
                                <h3>Follow & Connect On.</h3>
                                <ul className="footer-social-connect">
                                    <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                    <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                    <li><a href="https://instagram.com"><FaInstagram /></a></li>
                                    <li><a href="https://linkedin.com"><FaLinkedinIn /></a></li>
                                    <li><a href="https://youtube.com"><FaYoutube /></a></li>
                                </ul>
                            </div>


                            {/* <div className="footer-widget footer-working-hours">
                                <h2>Working Hours.</h2>
                                <ul className="working-hours">
                                    <li><strong>Monday:</strong> 9am-4pm</li>
                                    <li><strong>Tuesday:</strong> 9am-4pm</li>
                                    <li><strong>Wednesday:</strong> 9am-4pm</li>
                                    <li><strong>Thursday:</strong> 9am-4pm</li>
                                    <li><strong>Friday:</strong> 9am-4pm</li>
                                    <li className="off-day">Sat-Sun: <strong>Off</strong></li>
                                </ul>
                            </div>
                            <div className="footer-widget footer-get-in-touch">
                                <h2>Get In Touch.</h2>
                                <form className="get-in-touch">
                                    <input type="text" name="text" placeholder="Name" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <textarea placeholder="Write Message"></textarea>
                                    <button className="theme-button" value="Send Us Message"> Send Us Message <FiChevronRight className="icon" /></button>
                                </form>
                            </div> */}
                        </div>
                        <div className="copyright-wrap text-center">
                            <p>&copy; Copyright Servizkart 2021. Made by <FiHeart className="heartbeat" /> by <a href="#">SCDC</a>.</p>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}
