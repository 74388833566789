import React from 'react';


const CountBlock = ({ title, value, icon }) => {
    return (
        <div className="main">
            {icon && icon != "" && <img className="img" src={icon} />}
            <div className="card1">
                <div className="title" >
                {title}
                </div>
                <h2>
                    {value}
                </h2>
            </div>
        </div>
    );
};

export default CountBlock;
