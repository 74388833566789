import React, { Component } from 'react'
import { FiTarget, FiUserCheck } from 'react-icons/fi'
import { TiLightbulb } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import business from '../../assets/images/business.png'
import handshake from '../../assets/images/handshake.jpg'
import member1 from '../../assets/images/member1.png'
import vision from '../../assets/images/vision.jpg'
import Aboutus from "../../pages/blocks/about_us"
import amrendra from '../../assets/images/amrendra.jpg';
import amit from '../../assets/images/amit.jpg';
import munish from '../../assets/images/munish.png';
import arun from '../../assets/images/arun.jpg';
import praveen from '../../assets/images/praveen.png';
import TeamMembersRow from "./TeamMember";

import "./team.css";

let membersRow1 = [
    {
        name: "Amrendra Singh",
        designation: "Founder and CEO",
        details: "<p>With a corporate experience of 20 years in the Indian as well as Asian Market, Amrendra in his tenure of corporate leadership has worked with some of the leading global organizations in publishing, media, BPO/KPO, FMCG etc. As a senior leader, he has been keen to understand the nuances of each business. Amrendra is an inspiring leader who loves to be involved in each step of a process that is being led by him. His inquisitiveness made him explore each domain under his leadership like Finance, Legal, Operations, Business Development Strategy, Human Resources, Information Technology and Customer Excellence. Amrendra believes in the process of evolving and creating opportunities to bring better services to his clients and the brand. </p> <p>Amrendra is an alumni of UCLA Anderson School of Management, USA and Indian School of Business, Hyderabad</p>",
        image: amrendra
    },
    {
        name: "Munish Arora",
        designation: "Founder",
        details: "<p>Munish brings over 25 years of experience of designing and developing small and large IT applications. His key interest is in technology. He loves understanding the nitty-gritties of the system and developing a robust system (/s). He has experience ranging from bootstrapping a product to scaling a full-blown enterprise platform.</p>            <p> Furthermore, he enjoyed initial years of his career with leading corporates, working on various technologies / domains, and gaining knowledge.Now a days, he also provides consultancy / training to leading corporates like Infosys, Cognizant, Accenture, KPMG, E & Y, DCN(Decision Control Network, Army / Airforce / Navy) to name a few.</p>    <p>Munish is an Engineer with post - graduation, MBA, in IT.</p>",
        image: munish
    },
    {
        name: "Amit Singh",
        designation: "Founder",
        details: "<p>Amit has over 15 years of exposure and hands on experience in dealing with nitty-gritty of valuation of businesses/ equity for private ventures as well as for public enterprises for purposes like Transactions - Sale or Purchase, Compliances - FEMA, Transfer Pricing or requirement of RBI, Indirect  Transfers, Valuation of financial instruments/ derivatives like SOP & ESOPs, preference shares, debentures etc., valuation of real properties and intangible assets like Brand, copyright, customer contract, customer relation etc. He has served several reputed multinational corporations and offshore consulting firms.</p><p> After spending his formative days with a leading firm of chartered Accountants and multinational companies, his valuation skill were sharpened with India's biggest valuation team at one of the world’s Big4 consulting firm Ernst & Young. Amit is also a visiting faculty at School of Finance, Indian Institute of Corporate Affairs, run by Ministry of Corporate Affairs, Government of India and at International Business Valuers Association, an IBBI recognised Registered Valuers Organisation (RVO).</p><p>Amit is B.Com, FCA, LLB, Registered Valuer(IBBI) and Certified Valuer(ICAI)</p>",
        image: amit
    }

];

let membersRow2 = [

];


let membersUnused = [
    {
        name: "Munish Arora",
        designation: "Founder",
        details: "<p>Munish brings over 25 years of experience of designing and developing small and large IT applications. His key interest is in technology. He loves understanding the nitty-gritties of the system and developing a robust system (/s). He has experience ranging from bootstrapping a product to scaling a full-blown enterprise platform.</p>            <p> Furthermore, he enjoyed initial years of his career with leading corporates, working on various technologies / domains, and gaining knowledge.Now a days, he also provides consultancy / training to leading corporates like Infosys, Cognizant, Accenture, KPMG, E & Y, DCN(Decision Control Network, Army / Airforce / Navy) to name a few.</p>    <p>Munish is an Engineer with post - graduation, MBA, in IT.</p>",
        image: munish
    },
    {
        name: "Arun Khanna",
        designation: "Supply Chain Leader",
        details: "<p>Arun is highly effective process & people-oriented supply chain professional having more than 34 years of leadership experience in various industries. He has managed end to end supply chain operations for Indian and Multinational companies, setting up new projects, in India and abroad, in manufacturing, distribution and service businesses.</p><p>He comes with extensive experience in Operational & Business Excellence, Design & Implementation of Customer Focused Supply Chain Processes for start - up operations, setting up of IT driven tools / processes to achieve organization goals of Growth, Profitability & Sustainability and Supply Chain Management Consultancy & Education.</p><p>Arun graduated from GBPUAT, Pant Nager with B Tech(Hons) in Mech Engg in 1985. He completed his Post Graduate Diploma in Industrial Engineering(PGDIE) from NITIE - Mumbai in 1987. He is also Certified Supply Chain Professional(CSCP) and trainer from APICS, USA.</p>",
        image: arun
    },
    {
        name: "Praveen Sinha",
        designation: "HR Leader",
        details: "<p>Praveen Sinha is an HR professional with comprehensive strategic HR and change management experience which spans over 35 years. Has proven expertise in HR transformation, managing complex HR issues in multi-layered organizations and developing HR processes & systems. He worked with some of the renowned industry names like Escorts, Raymond’s, Tata etc. in various leadership positions. </p><p>He is a Post Graduate in Personnel Management & Industrial Relations from XISS Ranchi and was awarded Silver Medal of NIPM for scoring the highest marks in the paper on Personnel Management.</p><p>He was conferred Fellowship of the Indian Institute of Business Management, Patna, Bihar.He has ICF accredited coaching certification from Erickson coaching International.</p>",
        image: praveen
    }
];

export default class Team extends Component {
    constructor() {
        super()
    }
    render() {
        return (
            <section className="team-wrapper">
                <h3 className="management-team-caption">Thought Leadership</h3>
                <div className="team-members abourCardDivision">
                    <TeamMembersRow members={ membersRow1 } />
                </div>
            </section>
        )
    }
}


