import React, { Component } from 'react'
import Navbar from "../../../components/common/Navbar";
import BreadcrumbSmall from '../../../components/common/BreadcrumbSmall';
import ServiceProvider from './LoginForm';
import Footer from '../../blocks/Footer';

export default class ServiceProviderLoginPage extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <BreadcrumbSmall title="Login" />
                <ServiceProvider {...this.props}/>
                {/* <Footer /> */}
            </>
        )
    }
}
