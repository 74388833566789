import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiDownload } from 'react-icons/fi'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import SectionsTitle from '../../../components/common/SectionsTitle';

export default class ServicePricing extends Component {
    constructor(props) {
        super(props);
        console.log(props);
    }
    componentDidMount() {

    }
    render() {
        return (
            <>
                <div className="pricing-wrapper category-pricing">
                   <span className='pricing-details' dangerouslySetInnerHTML={{__html:this.props.category.priceDetail}}></span>
                    {/* <div className="pricing-description">
                        <b>Subscription Based Model</b> – the SVPL will have a monthly or annual subscription and
                        certain features and discounts will be available. Online management
                    </div>
                    <div className="pricing-description">
                        <b>Retainer ship Model</b> – Offline pricing however payment etc through online model as
                        well and amount to reflect online.
                    </div>
                    <div className="pricing-description">
                        <b>Instant Payment Modes</b> – Standard rates for certain services which to be paid upfront
                        and service can be delivered with assigned TAT.
                    </div>
                    <div className="pricing-description">
                        <b>Service Provider Registration Fee and Annual Fee</b> – Option to activate as and when
                        decided
                    </div>
                    <div className="pricing-description">
                        <b>Commission on Service Delivery,</b> – Option of defining the commission as per categorywise. Service provider wallet to show the transaction and net amount to be paid.
                    </div>
                    <div className="pricing-description">
                        <b>Advertisement Fee</b> – Sale of space etc.
                    </div>
                    <div className="pricing-description">
                        <b>Lead bid </b>– In option 2 above – lead to be sold in option 2. The service provider to charge
                        the wallet to get the leads as per the pricing policy after getting certain leads free.
                    </div> */}
                    <div className="text-center">
                        <SectionsTitle title="Service price" subtitle="" />
                    </div>
                    <div className="pricing-plan-grid">
                        {/* <div className="pricing-plan-box">
                            <h2 className="title">Basic</h2>
                            <h1 className="price"> <sup>INR</sup>1000</h1>
                            <h3 className="mo"></h3>
                        </div>
                        <div className="pricing-plan-box">
                            <h2 className="title">Popular</h2>
                            <h1 className="price"> <sup>INR</sup>2000</h1>
                            <h3 className="mo"></h3>
                        </div> */}
                        <div className="pricing-plan-box">
                            {/* <h2 className="title">Premium</h2> */}
                            <h1 className="price"> <sup>INR</sup>{this.props.category.price}</h1>
                            {/* <h3 className="mo"></h3> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
