import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FiDownload } from 'react-icons/fi'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import * as apiClient from "../../../components/network/apiClient";
import videochat from '../../../assets/images/videochat.jpg';
import livecall from '../../../assets/images/livecall.png';
import livechat from '../../../assets/images/livechat.png';

export default class ServiceBuddy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
        };
    }
    getServiceBuddy = () => {
        apiClient.getServiceBuddies().then(res => {
           // console.log(res);
            this.setState({ userList: res });
        }).catch(err => {
            console.log(err);
        })
    }
    componentDidMount() {
        this.getServiceBuddy();
    }
    render() {
        const { userList } = this.state;
        return (
            <>
                <div className="meet-buddy-list">
                    <div className="list list-row block">
                        <div className="list-item" data-id="19">
                            <div><a href="#" data-abc="true"><span className="w-48 avatar gd-primary"><img src={ livecall } alt="." /></span></a></div>
                            <div className="flex user-details"> <a href="#" className="item-author text-color" data-abc="true">Live Audio/Video Call</a>
                            </div>
                        </div>

                        <div className="list-item" data-id="19" onClick={ () => {
                            var Tawk_API = Tawk_API || {};
                            Tawk_API.maximize();
                        } }>
                            <div><span className="w-48 avatar gd-primary"><img src={ livechat } alt="." /></span></div>
                            <div className="flex user-details">
                                <div className="item-author text-color">Live Chat</div>
                            </div>
                        </div>
                        {/* {userList && userList.map((item, index) => {
                            return (
                                <div key={item.id} className="list-item" data-id="19">
                                    <div><a href="#" data-abc="true"><span className="w-48 avatar gd-primary"><img src="https://img.icons8.com/color/48/000000/administrator-male.png" alt="." /></span></a></div>
                                    <div className="flex user-details"> <a href="#" className="item-author text-color" data-abc="true">{item.FirstName} {item.LastName}</a>
                                        <div className="item-except text-muted text-sm h-1x">{item.Designation}</div>
                                    </div>
                                    <div className="no-wrap">
                                        <div className="item-date text-sm d-md-block user-status">Online</div>
                                    </div>
                                </div>
                            )
                        })} */}
                        {/* <div className="list-item" data-id="19">
                            <div><a href="#" data-abc="true"><span className="w-48 avatar gd-warning">S</span></a></div>
                            <div className="flex user-details"> <a href="#" className="item-author text-color" data-abc="true">Sam Kuran</a>
                                <div className="item-except text-muted text-sm h-1x">Service Advisor</div>
                            </div>
                            <div className="no-wrap">
                                <div className="item-date text-sm d-md-block user-status">Online</div>
                            </div>
                        </div>
                        <div className="list-item" data-id="7">
                            <div><a href="#" data-abc="true"><span className="w-48 avatar gd-primary"><img src="https://img.icons8.com/color/48/000000/administrator-male.png" alt="." /></span></a></div>
                            <div className="flex user-details"> <a href="#" className="item-author text-color" data-abc="true">Kinley Adolf</a>
                                <div className="item-except text-muted text-sm h-1x">Service Consultant</div>
                            </div>
                            <div className="no-wrap">
                                <div className="item-date  text-sm d-md-block user-status">Online</div>
                            </div>
                        </div>
                        <div className="list-item" data-id="7">
                            <div><a href="#" data-abc="true"><span className="w-48 avatar gd-primary"><img src="https://img.icons8.com/color/48/000000/guest-male.png" alt="." /></span></a></div>
                            <div className="flex user-details"> <a href="#" className="item-author text-color" data-abc="true">Kinley Adolf</a>
                                <div className="item-except text-muted text-sm h-1x">Service Consultant</div>
                            </div>
                            <div className="no-wrap">
                                <div className="item-date  text-sm d-md-block user-status">Online</div>
                            </div>
                        </div>

                        <div className="list-item" data-id="19">
                            <div><a href="#" data-abc="true"><span className="w-48 avatar gd-warning">S</span></a></div>
                            <div className="flex user-details"> <a href="#" className="item-author text-color" data-abc="true">Sam Kuran</a>
                                <div className="item-except text-muted text-sm h-1x">Service Advisor</div>
                            </div>
                            <div className="no-wrap">
                                <div className="item-date  text-sm d-md-block user-status">Online</div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </>
        )
    }
}
