import React, { Component } from 'react'
import Navbar from "../../../components/common/Navbar";
import BreadcrumbSmall from '../../../components/common/BreadcrumbSmall';
import individual from "../../../assets/images/individual.png";
import companyimg from "../../../assets/images/company.png";

export default class ServiceProviderAuthPage extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <BreadcrumbSmall title="Sign Up" />
                <div className="user-type-selection">
                    <div className="individual">
                        <img src={ individual } className="signup-icon" />
                        <div className="signup-caption">Existing partner</div>
                        <div className="signup-subcaption">
                            Create your profile and navigate our services and partner network for all your professional needs.
                            Connect with us for all your requirement and find out a competent Verified Service Partner.
                        </div>
                        <a href="http://admin.servizkart.com/" target="_blank" className="signup-btn theme-button" >Login</a>
                    </div>
                    <div className="company">
                        <img src={ companyimg } className="signup-icon" />
                        <div className="signup-caption">New to Servizkart</div>
                        <div className="signup-subcaption">
                            Create your Company profile in few minutes. Connect with us for all your requirement or navigate our business solutions /over the counter services and find out a competent Verified Service Partner.
                        </div>
                        <div className="signup-btn theme-button" onClick={ () => {
                            this.props.history.push("/service-provider-sign-up");
                        } }>SIGN UP</div>
                    </div>
                </div>
            </>
        )
    }
}
