import React, { Component } from 'react'
import TeamDetailsContent from '../components/team/TeamDetailsContent';
import TeamMemberDetails from "./team/details";
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Footer from './blocks/Footer';


export default class TeamDetails extends Component {
    render() {
        return (
            < >
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Team Details" />
                <TeamMemberDetails />
                <Footer />

            </>
        )
    }
}
