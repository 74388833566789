import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import img1 from '../../assets/images/img3.jpg'
import img2 from '../../assets/images/img4.jpg'
import slider1 from '../../assets/images/img12.jpg'
import { FiCheckCircle } from 'react-icons/fi'
import { LineChart } from 'react-chartkick'
import 'chart.js'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SectionsTitle from '../../components/common/SectionsTitle';
import { Link } from 'react-router-dom';
import { FiShoppingCart } from 'react-icons/fi';
import chat from '../../assets/images/chat.jpg';
import moredetails from '../../assets/images/moredetails.jpg';
import pricepackage from '../../assets/images/pricepackages.jpg';
import serviceproviders from '../../assets/images/serviceproviders.jpg';
import sharerequirement from '../../assets/images/sharerequirement.jpg';
import videochat from '../../assets/images/videochat.jpg';
import { FiChevronRight, FiChevronLeft, FiChevronDown, FiList, FiMenu } from 'react-icons/fi'
import ServiceRequirements from "./widgets/ServiceRequirements";
import ServiceBuddy from "./widgets/ServiceBuddy";
import ServiceProviders from "./widgets/ServiceProviders";
import ServicePricing from "./widgets/ServicePricing";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTooltip from 'react-tooltip';


export default class ServiceDtlsContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPriceDisplayed: false,
            isShareRequirementDisplayed: false,
            isServiceBuddyAvailable: false,
            isAvailableServiceProviders: false,
            isPopupDisplayed: false,
            popupTitle: "More Details",
            iConClicked: "",
            iConCategory: null,
        }
    }
    ToggleAllTabsExcept = (property, value) => {
        value = !value;
        this.setState({
            isPriceDisplayed: false,
            isShareRequirementDisplayed: false,
            isServiceBuddyAvailable: false,
            isAvailableServiceProviders: false,
        }, () => {
            this.setState({
                [property]: value
            })
        });
    }
    displayPopupForCategory = (item, poptptitle, iconClicked) => {
        this.setState({
            iConCategory: item,
            iConClicked: iconClicked,
            popupTitle: poptptitle
        }, () => {
            this.popupToggle();
        });
    }
    popupToggle = () => {
        const { isPopupDisplayed } = this.state;
        let c = !isPopupDisplayed;
        this.setState({ isPopupDisplayed: c });
    }
    componentDidMount() {

    }
    render() {
        const { category, categories, isSidebarDisplayed } = this.props;
        const { isPriceDisplayed, isShareRequirementDisplayed, isServiceBuddyAvailable,
            isAvailableServiceProviders, isPopupDisplayed, popupTitle, iConClicked, iConCategory } = this.state;
        return (
            <>
                <div className="service-content-wrap">
                    { isSidebarDisplayed && <FiMenu className="icon list-toggle" onClick={ this.props.onSidebarToggle } /> }
                    { !isSidebarDisplayed && <div className="services-icons display-desktop">
                        <FiMenu className="icon list-toggle" onClick={ this.props.onSidebarToggle } />
                        { categories && categories.map((item, index) => {
                            return (
                                <div key={ index } onClick={ () => {
                                    this.props.onCategorySelect(item);
                                } } data-tip={ item.serviceName }
                                    className={ item.selected ? "cat-link-icon selected" : "cat-link-icon" }>
                                    { item.icon && <span data-tip={ item.serviceName } dangerouslySetInnerHTML={ {
                                        __html: item.icon
                                    } }></span> }
                                    {/* {item.serviceName} */ }
                                </div>
                            )
                        }) }
                    </div> }
                    { !isSidebarDisplayed && <ReactTooltip place="bottom" type="dark" /> }
                    { <div className="services-icons display-onmobile">
                        <FiMenu className="icon list-toggle" onClick={ this.props.onSidebarToggle } />
                        { categories && categories.map((item, index) => {
                            return (
                                <div key={ index } onClick={ () => {
                                    this.props.onCategorySelect(item);
                                } } data-tip={ item.serviceName }
                                    className={ item.selected ? "cat-link-icon selected" : "cat-link-icon" }>
                                    { item.icon && <span dangerouslySetInnerHTML={ {
                                        __html: item.icon
                                    } }></span> }
                                    {/* {item.serviceName} */ }
                                </div>
                            )
                        }) }
                    </div> }
                    <div className="service-content">
                        <div className="service-details-top">
                            <div className="category-image-parent">
                                <img src={ category.logo } className="category-image" />
                            </div>
                            <div className="main-category-detail">
                                <h1 className="category-name">{ category.serviceName }</h1>
                                <div dangerouslySetInnerHTML={ { __html: category.serviceDetails } }></div>
                            </div>
                        </div>
                        <div className="service-category-actions">
                            <div className={ isShareRequirementDisplayed ? "share-requirements btn selected" : "share-requirements btn" } onClick={ () => {
                                this.ToggleAllTabsExcept("isShareRequirementDisplayed", isShareRequirementDisplayed);
                            } }>
                                <img src={ sharerequirement } className="icon" /> <span>Share Requirements
                                    { !isShareRequirementDisplayed && <FiChevronRight className="icon" /> }
                                    { isShareRequirementDisplayed && <FiChevronDown className="icon" /> }
                                </span>
                            </div>
                            <div className={ isServiceBuddyAvailable ? "meet-buddy btn selected" : "meet-buddy btn" } onClick={ () => {
                                this.ToggleAllTabsExcept("isServiceBuddyAvailable", isServiceBuddyAvailable);
                            } }>
                                <img src={ videochat } className="icon" /> <span>Meet Our Service Buddy
                                    { !isServiceBuddyAvailable && <FiChevronRight className="icon" /> }
                                    { isServiceBuddyAvailable && <FiChevronDown className="icon" /> }
                                </span>
                            </div>
                            <div className={ isAvailableServiceProviders ? "available-service-providers btn selected" : "available-service-providers btn" } onClick={ () => {
                                this.ToggleAllTabsExcept("isAvailableServiceProviders", isAvailableServiceProviders);
                            } }>
                                <img src={ serviceproviders } className="icon" /> <span>Available Service Providers
                                    { !isAvailableServiceProviders && <FiChevronRight className="icon" /> }
                                    { isAvailableServiceProviders && <FiChevronDown className="icon" /> }
                                </span>
                            </div>
                            <div className={isPriceDisplayed ? "available-service-providers btn selected" : "available-service-providers btn"} onClick={() => {
                                this.ToggleAllTabsExcept("isPriceDisplayed", isPriceDisplayed);
                            }}>
                                <img src={pricepackage} className="icon" /> <span>Price Packages
                                {!isPriceDisplayed && <FiChevronRight className="icon" />}
                                    {isPriceDisplayed && <FiChevronDown className="icon" />}
                                </span>
                            </div>
                        </div>

                        { isPriceDisplayed && <ServicePricing category={ category } /> }
                        { isShareRequirementDisplayed && <ServiceRequirements category={ category } /> }
                        { isServiceBuddyAvailable && <ServiceBuddy category={ category } /> }
                        { isAvailableServiceProviders && <ServiceProviders category={ category } /> }

                        <h1 className="sub-category-name">Sub Categories <FiChevronDown className="icon" /></h1>
                        <div className="service-sub-categories">
                            { category.children && category.children.map((item, index) => {
                                return (
                                    <div key={ index } className="sub-category-block" >
                                        <div className="sub-category-name">{ item.serviceName } </div>
                                        <div className="category-image-parent">
                                            <img src={ item.logo } className="category-image" onClick={ () => {
                                                this.props.onSubCategorySelect(item);
                                            } } />
                                        </div>
                                        <div className="category-icons">
                                            <img src={ moredetails } className="icon" title="More Details" onClick={ () => { this.displayPopupForCategory(item, "More details", "MORE-DETAILS"); } } />
                                            <img src={ sharerequirement } className="icon" title="Share Requirments" onClick={ () => { this.displayPopupForCategory(item, "Share Requirements", "SHARE-REQ"); } } />
                                            <img src={ videochat } className="icon" title="Meet our service buddy" onClick={ () => { this.displayPopupForCategory(item, "Meet our service buddy", "MEET-BUDDY"); } } />
                                            <img src={ serviceproviders } className="icon" title="Service providers" onClick={ () => { this.displayPopupForCategory(item, "Service providers", "SERVICE-PROVIDERS"); } } />
                                            {/* <img src={pricepackage} className="icon" title="Price package" onClick={() => { this.displayPopupForCategory(item, "Price package", "PRICE-PACKAGE"); }} /> */ }
                                        </div>
                                    </div>
                                )
                            }) }
                        </div>
                        <div className="back-to-main-category-parent">
                            {category && category.parentId != 0 && <div className="back-to-parent-category" onClick={ this.props.onMoveToParentCategory }>
                                <FiChevronLeft className="icon" /> Back To Parent
                            </div> }
                            {<div className="back-to-main-category" onClick={ this.props.onMoveTomainCategory }>
                                <FiChevronLeft className="icon" /> Back To main category
                            </div>}
                        </div>
                    </div>
                </div>
                <Modal isOpen={ isPopupDisplayed } toggle={ this.popupToggle } className="my-model-popup">
                    <ModalHeader toggle={ this.popupToggle }>
                        { iConCategory && iConCategory.serviceName } - { popupTitle }
                    </ModalHeader>
                    <ModalBody>
                        { iConClicked == "PRICE-PACKAGE" && <ServicePricing category={ iConCategory } /> }
                        { iConClicked == "SHARE-REQ" && <ServiceRequirements category={ iConCategory } /> }
                        { iConClicked == "MEET-BUDDY" && <ServiceBuddy category={ iConCategory } /> }
                        { iConClicked == "SERVICE-PROVIDERS" && <ServiceProviders category={ iConCategory } /> }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.popupToggle }>Close</Button>
                    </ModalFooter>
                </Modal>
                <ReactTooltip place="bottom" type="dark" />
            </>
        )
    }
}
