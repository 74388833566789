import React, { Component } from 'react'
import img from '../../assets/images/team-8.jpg'
import { FiPhone, FiLink } from 'react-icons/fi';
import { FaEnvelope, FaQuoteLeft } from 'react-icons/fa';

export default class TeamMemberDetails extends Component {
    expertise = {
        items: [
            {
                list: "Business transformation"
            },
            {
                list: "Restructuring and turnaround"
            },
            {
                list: "Integration"
            },
            {
                list: "Growth strategy"
            }
        ]
    }
    educations = {
        items: [
            {
                list: "MBA, Rotterdam School of Management, Erasmus University"
            },
            {
                list: "BS, Engineering, Technical University of Denmark"
            }
        ]
    }
    render() {
        const { member } = this.props;
        return (
            <section className="team-details-wrapper">
                <div className="container">
                    <div className="team-details-grid-top d-grid">
                        <div className="team-details-img">
                            <img src={member.image} alt="Team Details" className="member-detail-image" />
                        </div>
                        <div className="team-details-content">
                            <h1 className="name">{member.name}</h1>
                            {/* <span className="skills">Founder & CEO</span> */}
                            <p className="desc">{member.description}</p>
                            <ul className="team-details-contact">
                                <li><a href={"tel:+" + member.phonenumber}><span className="icon"><FiPhone /></span> {member.phonenumber}</a></li>
                                <li><a href={"mailto:" + member.emailAddress}><span className="icon"><FaEnvelope /></span> {member.emailAddress}</a></li>
                                <li><a href={member.webSite} target="_blank"><span className="icon"><FiLink /></span> {member.webSite}</a></li>
                            </ul>
                            <div className="team-expertise-education-grid d-grid">
                                <div className="left">
                                    <h2>Areas Of Expertise</h2>
                                    <ul className="team-details-expertise lists" dangerouslySetInnerHTML={{ __html: member.areaofExpertise }}>
                                    </ul>
                                </div>
                                <div className="right">
                                    <h2>Education</h2>
                                    <ul className="team-details-education lists"  dangerouslySetInnerHTML={{ __html: member.education }}>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="team-details-grid-bottom d-grid">
                        <div className="left">
                            <h3>A Little Description About Me.</h3>
                            <span dangerouslySetInnerHTML={{ __html: member.moredescription }}></span>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
