import React, { Component } from 'react'
import { FiTarget, FiUserCheck } from 'react-icons/fi'
import { TiLightbulb } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import business from '../../assets/images/business.png'
import handshake from '../../assets/images/handshake.jpg'
import member1 from '../../assets/images/member1.png'
import vision from '../../assets/images/vision.jpg'
import Aboutus from "../../pages/blocks/about_us"
import amrendra from '../../assets/images/amrendra.jpg';
import about_us1 from "../../assets/images/about_us1.png";
import about_us2 from "../../assets/images/about_us2.png";
import about_us3 from "../../assets/images/about_us3.png";
import about_us4 from "../../assets/images/about_us7.jpg";
import about_us5 from "../../assets/images/about_us5.png";
import about_us6 from "../../assets/images/about_us6.png";
import about_us8 from "../../assets/images/about_us8.jpg";
import hands from "../../assets/images/hands.png";
import lady_working from "../../assets/images/lady_working.png";
import men_problem from "../../assets/images/men_problem.png";
import ContactForm from '../contact/ContactForm';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Team from "./Team";

export default class About extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isPopupDisplayed: false,
        }
        this.openModal = this.openModal.bind(this)
    }

    popupToggle = () => {
        const { isPopupDisplayed } = this.state;
        let c = !isPopupDisplayed;
        this.setState({ isPopupDisplayed: c });
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    aboutstate = {
        supports: [
            {
                icon: <FiUserCheck />,
                title: '24/7 full Online Support'
            },
            {
                icon: <FiTarget />,
                title: '10 Years of Experience'
            },
            {
                icon: <TiLightbulb />,
                title: 'Creative Finance Idea'
            }
        ]
    }
    aboutrightimg = {
        images: [
            {
                img: business
            },
            {
                img: handshake
            },
            {
                img: vision
            }
        ]
    }
    render() {
        const { isPopupDisplayed } = this.state;
        return (
            <>
                <section className="about-wrapper">
                    <div className="container">
                        <div className="about-grid-wrap">
                            <div className="about-left-full">
                                <div className="about-image-text">
                                    <div className="image">
                                        <img src={ about_us1 } />
                                    </div>
                                    <div className="image-details">
                                        <p>SERVIZKART is a new-age marketplace that makes it easier for corporate and individuals, to search for the most competent service partners with one-click search.</p>
                                        {/* <p>
                                        While managing a task is never easy, outsourcing can be the key to cost-effective and efficient time-management and SERVIZKART is your roadmap to business excellence!.
                                    </p>
                                    <p>
                                        ServizKart understands the various issues faced by businesses while looking for functional service providers. Despite spending a good amount of time searching for service partners, small firms has to deal with numerous spammers and telemarketers.
                                    </p> */}
                                    </div>
                                </div>

                                <div className="about-image-text reverse">
                                    <div className="image">
                                        <img src={ about_us4 } />
                                    </div>
                                    <div className="image-details">
                                        <p>
                                            SERVIZKART aims to redefine the process of business outsourcing in India and make it easier to find best service partner across locations, industries and functions in a cost-effective and time-saving manner.
                                        </p>
                                        {/* <p>
                                        We collaborate with corporate and service providers to help them gain and grow their business together.
                                    </p> */}
                                        <p className="register-link" onClick={() => {
                                            this.popupToggle();
                                        }}>Register your query at SERVIZKART and navigate our verified service partner list.</p>
                                    </div>
                                </div>

                                <div className="about-image-text">
                                    <div className="image">
                                        <img src={ about_us5 } />
                                    </div>
                                    <div className="image-details">
                                        <p>
                                            While managing a task is never easy, outsourcing can be the key to cost-effective and efficient time-management and SERVIZKART is your roadmap to business excellence!
                                        </p>
                                        {/* <p>
                                        Designed keeping in mind the dynamic requirements, the Artificial Intelligence of SERVIZKART works on the basic principle of finding what's best for you. SERVIZKART offer a user-friendly interface to individuals, start-ups, small and mid-scale industries and MNC's with an opportunity to outsource their requirements to the most suitable service partner on board.
                                    </p>
                                    <p>
                                        The team at SERVIZKART takes immense effort in curating our service partner list. Ensuring authenticity, the vendors registered with SERVIZKART are thoroughly verified for their KYC and Place of Business.
                                    </p> */}
                                    </div>
                                </div>

                                <div className="about-image-text reverse">
                                    <div className="image">
                                        <img src={ about_us8 } />
                                    </div>
                                    <div className="image-details">
                                        <p>
                                            ServizKart understands the various issues faced by businesses while looking for functional service providers. Despite spending a good amount of time searching for service partners, small firms has to deal with numerous spammers and telemarketers.
                                        </p>
                                        {/* <p>
                                        SERVIZKART verification can help avoid conflict of interest and possible business fraud.
                                    </p>
                                    <p>
                                        Each service partner comes with client testimonials to support their work and is known to be the best in their respective fields. One can choose from more than a hundred <Link to="/services" title="Search the services" alt="Search the services">services</Link> listed at SERVIZKART like Accounting & Ancillary Services, HR & Payroll Services, Corporate Legal Services, Warehousing & Logistics, IT & Software support, Sales & Marketing and many more...
                                    </p> */}
                                    </div>
                                </div>
                                <div className="about-image-text">
                                    <div className="image">
                                        <img src={ about_us2 } />
                                    </div>
                                    <div className="image-details">
                                        <p>
                                            We collaborate with corporate and service providers to help them gain and grow their business together.
                                        </p>
                                        <p>
                                            Designed keeping in mind the dynamic requirements, the Artificial Intelligence of SERVIZKART works on the basic principle of finding what's best for you.
                                        </p>
                                        {/* <p>
                                        SERVIZKART verification can help avoid conflict of interest and possible business fraud.
                                    </p>
                                    <p>
                                        Each service partner comes with client testimonials to support their work and is known to be the best in their respective fields. One can choose from more than a hundred <Link to="/services" title="Search the services" alt="Search the services">services</Link> listed at SERVIZKART like Accounting & Ancillary Services, HR & Payroll Services, Corporate Legal Services, Warehousing & Logistics, IT & Software support, Sales & Marketing and many more...
                                    </p> */}
                                    </div>
                                </div>
                                <div className="about-full-width-table">
                                    <div className="full-image">
                                        <img src={ about_us6 } />
                                    </div>
                                    <div className="three-colmuns">
                                        <div className="column">
                                            SERVIZKART offer a user-friendly interface to individuals, start-ups, small and mid-scale industries and MNC's with an opportunity to outsource their requirements to the most suitable service partner on board
                                        </div>
                                        <div className="column">
                                            The team at SERVIZKART takes immense effort in curating our service partner list. Ensuring authenticity, the vendors registered with SERVIZKART are thoroughly verified for their KYC and Place of Business. SERVIZKART verification can help avoid conflict of interest and possible business fraud
                                        </div>
                                        <div className="column">
                                            Each service partner comes with client testimonials to support their work and is known to be the best in their respective fields. One can choose from more than a hundred services listed at SERVIZKART
                                        </div>
                                    </div>
                                </div>

                                <p>
                                    With an excellent turnaround time, our value-added services like <Link to="/servizebuddy_program">SERVIZKARTBUDDY</Link> Program can help one save lots of individual efforts. <Link to="/servizebuddy_program">"SERVIZKARTBUDDY"</Link> is highly-skilled outsourcing and procurement professional who will be coordinating the service partner search for you. From understanding your requirement, searching and short listing, the <Link to="/servizebuddy_program">SERVIZKARTBUDDY</Link> will be part of your entire journey with SERVIZKART.
                                </p>

                            </div>
                        </div>
                        <div className="about-right">
                            {/* <div className="about-images">
                                {this.aboutrightimg.images.map((img, index) => {
                                    return <img key={index} className={'about_img_' + index} src={img.img} alt={'About Image ' + index} />
                                })}
                            </div> */}

                            <div className="about-iconbox-grid">
                                <div className="about-iconbox">
                                    <Link to="/contact">
                                        <span className="about-iconbox-icon">
                                            <FiUserCheck />
                                        </span>
                                    </Link>
                                    <h3 className="about-iconbox-title">
                                        <Link to="/contact">Raise your query</Link>
                                    </h3>
                                </div>

                                <div className="about-iconbox">
                                    <Link to="/service-provider-sign-up">
                                        <span className="about-iconbox-icon">
                                            <FiTarget />
                                        </span>
                                    </Link>
                                    <h3 className="about-iconbox-title">
                                        <Link to="/service-provider-sign-up">Register as Partner</Link>
                                    </h3>
                                </div>

                                <div className="about-iconbox">
                                    <Link to="/services">
                                        <span className="about-iconbox-icon">
                                            <TiLightbulb />
                                        </span>
                                    </Link>
                                    <h3 className="about-iconbox-title">
                                        <Link to="/services">Go to services</Link>
                                    </h3>
                                </div>

                                <div className="about-iconbox">
                                    <Link to="/contact#contactform" target="_blank">
                                        <span className="about-iconbox-icon">
                                            <FiUserCheck />
                                        </span>
                                    </Link>
                                    <h3 className="about-iconbox-title">
                                        <Link to="/contact#contactform" target="_blank">Contact Servizkart Buddy</Link>
                                    </h3>
                                </div>

                            </div>
                        </div>

                        <Aboutus />
                        <Team />

                    </div>
                </section>
                <Modal isOpen={ isPopupDisplayed } toggle={ this.popupToggle } className="my-model-popup">
                    <ModalHeader toggle={ this.popupToggle }>
                        Register your query
                    </ModalHeader>
                    <ModalBody>
                        <ContactForm />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.popupToggle }>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}


