import React, { Component } from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import FooterLogo from '../../assets/images/logo_secondary.png';
import ScrollTopBtn from '../../components/layouts/ScrollTopBtn';
import * as apiClient from "../../components/network/apiClient";
import StartupIndia from '../../assets/images/startup_india.png';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutUs: "",
            fbLink: "",
            twLink: "",
            instaLink: "",
            linkedinLink: "",
            youtubeLink: "",
            directPhoneNumber: "+918368265052",
            directEmail: "info@servizkart.com/ help@servizkart.com",
        };
    }

    getFooterAbountUs = () => {
        apiClient.getContentPageConfig("FooterAboutUs").then(res => {
            if (res.length > 0) {
                this.setState({ aboutUs: res[0].propertyName });
            }
        });
    }
    getFooterLinks = async () => {
        let fb = await apiClient.getContentPageConfig("FacebookLink");
        let tw = await apiClient.getContentPageConfig("TwitterLink");
        let insta = await apiClient.getContentPageConfig("InstagramLink");
        let linkiedin = await apiClient.getContentPageConfig("LinkedinLink");
        let youtube = await apiClient.getContentPageConfig("YoutubeLink");
        let phone = await apiClient.getContentPageConfig("DirectPhoneNumbers");
        let email = await apiClient.getContentPageConfig("DirectEmails");

        this.setState({
            fbLink: fb[0].propertyName,
            twLink: tw[0].propertyName,
            instaLink: insta[0].propertyName,
            linkedinLink: linkiedin[0].propertyName,
            youtubeLink: youtube[0].propertyName,
            directPhoneNumber: phone[0].propertyName,
            directEmail: email[0].propertyName,
        });
    }
    componentDidMount() {
        this.getFooterAbountUs();
        this.getFooterLinks();
    }
    render() {
        const { aboutUs, fbLink, twLink, instaLink, linkedinLink, youtubeLink, directPhoneNumber, directEmail } = this.state;
        return (
            <>
                <ScrollTopBtn />
                <footer className="footer-wrapper">
                    <div className="container">
                        <div className="footer-widgets-grid">
                            <div className="footer-widget footer-left">
                                <Link to="/">
                                    <img src={ FooterLogo } alt="Footer Logo" className="footer-logo" />
                                </Link>
                                <p className="desc">{ aboutUs }</p>
                                <div className='footer-left-blocks'>
                                <p className="desc">Servizkart is a registered start-up with Startup India, Ministry of Commerce & Industry, Govt. of India.</p>
                                <img src={ StartupIndia } alt="Footer Certificate" className="footer-certificate" />
                                </div>
                                
                                {/* <ul className="footer-address">
                                    <li>
                                        <a href="tel:+1224234948"><span className="icon"><FiPhone /></span> +122 423 4948</a>
                                    </li>
                                    <li>
                                        <a href="mailto:support@Servizkart.com"><span className="icon"><FaEnvelope /></span> Support@Servizkart.com</a>
                                    </li>
                                    <li>
                                        <a href="https://maps.google.com"><span className="icon"><FaMapMarkerAlt /></span> H 1106, Maple Heights, Sushant Lok 1, Gurgaon</a>
                                    </li>
                                </ul> */}
                                {/* <div className="social">
                                    <h3>Follow & Connect On.</h3>
                                    <ul className="footer-social-connect">
                                        <li><a href="https://facebook.com"><FaFacebookF /></a></li>
                                        <li><a href="https://twitter.com"><FaTwitter /></a></li>
                                        <li><a href="https://instagram.com"><FaInstagram /></a></li>
                                        <li><a href="https://linkedin.com"><FaLinkedinIn /></a></li>
                                        <li><a href="https://youtube.com"><FaYoutube /></a></li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="footer-widget footer-help-links">
                                <h2>Useful Links</h2>
                                <ul className="help-links">
                                    <li> <Link to="/about">About Us</Link></li>
                                    <li><Link to="/advisory-panel"> Advisory Panel </Link></li>
                                    <li><Link to="/preferred-partners">Serviz Preferred</Link></li>
                                    <li><Link to="/app-partners">Application Partners</Link></li>
                                    <li> <Link to="/services">Services</Link></li>
                                    <li> <Link to="/career">Careers</Link></li>
                                </ul>
                            </div>
                            <div className="footer-widget footer-address">
                                <h2>For Enquiries</h2>
                                <ul className="footer-address">
                                    {/* <li>
                                        <a href="tel:+1224234948"><span className="icon"><FiPhone /></span> { directPhoneNumber }</a>
                                    </li>
                                    <li>
                                        <a href="mailto:info@servizkart.com"><span className="icon"><FaEnvelope /></span> { directEmail }</a>
                                    </li>
                                    <li>
                                        <a href="https://maps.google.com"><span className="icon"><FaMapMarkerAlt /></span> H 1106, Maple Heights, Sushant Lok 1, Gurgaon</a>
                                    </li> */}
                                    <li>
                                        <div className="title">
                                            Service Providers:
                                        </div>
                                        <div className="detail">
                                            <a href="mailto:partnership@servizkart.com">partnership@servizkart.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="title">
                                            Customer:
                                        </div>
                                        <div className="detail">
                                            <a href="mailto:connect@servizkart.com">connect@servizkart.com</a>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="title">
                                            Phone:
                                        </div>
                                        <div className="detail">
                                            <a href="tel:+918368265052">+91-8368265052</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="social">
                                <h3>Connect with us</h3>
                                <ul className="footer-social-connect">
                                    <li><a target="_blank" href={ fbLink }><FaFacebookF /></a></li>
                                    <li><a target="_blank" href={ twLink }><FaTwitter /></a></li>
                                    <li><a target="_blank" href={ linkedinLink }><FaLinkedinIn /></a></li>
                                    <li><a target="_blank" href={ youtubeLink }><FaYoutube /></a></li>
                                </ul>
                                <h3 className="our-offices">Our Offices:</h3>
                                <div className="office-address">
                                    Office No. 4, Golf Course Road,<br />
                                    Opposite HDFC Bank, Sector 54, Gurgaon
                                </div>
                            </div>


                            {/* <div className="footer-widget footer-working-hours">
                                <h2>Working Hours.</h2>
                                <ul className="working-hours">
                                    <li><strong>Monday:</strong> 9am-4pm</li>
                                    <li><strong>Tuesday:</strong> 9am-4pm</li>
                                    <li><strong>Wednesday:</strong> 9am-4pm</li>
                                    <li><strong>Thursday:</strong> 9am-4pm</li>
                                    <li><strong>Friday:</strong> 9am-4pm</li>
                                    <li className="off-day">Sat-Sun: <strong>Off</strong></li>
                                </ul>
                            </div>
                            <div className="footer-widget footer-get-in-touch">
                                <h2>Get In Touch.</h2>
                                <form className="get-in-touch">
                                    <input type="text" name="text" placeholder="Name" />
                                    <input type="email" name="email" placeholder="Email" />
                                    <textarea placeholder="Write Message"></textarea>
                                    <button className="theme-button" value="Send Us Message"> Send Us Message <FiChevronRight className="icon" /></button>
                                </form>
                            </div> */}
                        </div>
                        <div className="copyright-wrap bottom-menu-wrap text-center">
                            <ul className="bottom-menu">
                                <li className="sidenav__item">
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li className="sidenav__item">
                                    <Link to="/terms-of-use-partner">Terms of Use for Service Partners</Link>
                                </li>
                                <li className="sidenav__item">
                                    <Link to="/terms-of-use-client">Terms of Use for Clients</Link>
                                </li>

                                <li className="sidenav__item">
                                    <Link to="/contact">  Contact Us </Link>
                                </li>
                                <li className="sidenav__item">
                                    <Link to="/faq">  FAQ </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="copyright-wrap text-center">
                            <p>&copy; Copyright 2021 Stellular Ventures Private Limited. All Rights Reserved.</p>
                            <p style={ { textAlign: "justify", fontSize: '10px' } }>Unless otherwise indicated, all materials on these pages are copyrighted by Stellular Ventures Private Limited. No part of these pages, either text or image may be used for any purpose.</p>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}
