import React from "react"
import mapMarker from './../../assets/images/map-marker.png'

const { compose, withProps } = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    BicyclingLayer,
    Marker
} = require("react-google-maps");
const Maps = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCWOmGRPdn4UKa7-Qwan7MX9WQDUa7Qvcg",
        loadingElement: <div style={ { height: `100%` } } />,
        containerElement: <div style={ { height: `375px` } } />,
        mapElement: <div style={ { height: `100%` } } />,
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap
        defaultZoom={ 13 }
        defaultCenter={ { lat: 28.452360, lng: 77.081555 } }
        defaultOptions={ {
            disableDefaultUI: true, // disable default map UI
            draggable: true, // make map draggable
            keyboardShortcuts: false, // disable keyboard shortcuts
            scaleControl: true, // allow scale controle
            scrollwheel: true, // allow scroll wheel
            styles: [
                {
                    "featureType": "road",
                    "stylers": [
                        { "color": "#ffffff" }
                    ]
                }, {
                    "featureType": "water",
                    "stylers": [
                        { "color": "#e9e9e9" }
                    ]
                }, {
                    "featureType": "landscape",
                    "stylers": [
                        { "color": "#f5f5f5" }
                    ]
                }, {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        { "color": "transparent" }
                    ]
                }, {
                    "featureType": "poi",
                    "stylers": [
                        { "color": "#fefefe" }
                    ]
                }, {
                    "elementType": "labels.text",
                    "stylers": [
                        { "saturation": 1 },
                        { "weight": 0.1 },
                        { "color": "#737980" }
                    ]
                }
            ],
            icon: mapMarker
        } }
    >
        <Marker
            icon={ {
                url: mapMarker
            } }
            animation={ 1 }

            position={ {
                lat: 28.452360, // latitude to position the marker
                lng: 77.081555 // longitude to position the marker
            } }
        />
        <BicyclingLayer autoUpdate />
    </GoogleMap>
);

export default Maps