
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from "../components/common/Navbar";
import SectionsTitle from '../components/common/SectionsTitle';
import Service from '../components/services/Services';
import Slider from '../components/sliders/slider';
import Footer from './blocks/Footer';
import HomeTestimonial from "./blocks/home-testimonial";
import HomeAboutus from "./blocks/home_about_us";
import { FiChevronRight } from 'react-icons/fi';
export default class Home extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Slider />
                <section className="tabs-wrapper about-us-wrapper">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-12">
                                <SectionsTitle title="About Us" />
                            </div>
                        </div>

                        <div className="tabs-content-wrap">
                            <div className="about-us-content">
                                <b>SERVIZKART</b> is a new-age marketplace that makes it easier for corporate and individuals, to search for the most competent service partners with one-click search.
                            </div>
                            <div className="about-us-content">
                                ServizKart understands the various issues faced by businesses while looking for functional service providers.
                                Despite spending a good amount of time searching for service partners, small firms has to deal with numerous spammers and telemarketers.
                                SERVIZKART aims to redefine the process of business outsourcing in India and make it easier to find best service partner across locations, industries and functions in a cost-effective and time-saving manner.
                                We collaborate with corporate and service providers to help them gain and grow their business together.
                            </div>

                            <div className="about-us-content">
                                <Link to="/contact#contactform" target="_blank"> Register your query at SERVIZKART and navigate our verified service partner list.</Link>
                            </div>
                            <Link to="/about" className="home-read-more theme-button">Read More<FiChevronRight className="icon" /></Link>
                        </div>
                        {/* <div className="row text-center">
                            <div className="col-12">
                                <SectionsTitle title="Our Values" />
                            </div>
                        </div>
                        <div className="values-list-home">
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th>Value for Corporates</th>
                                        <th>Value for Serviz partners</th>
                                        <th>Value for Individuals</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Verified Service Partners </td>
                                        <td>Expand Your Business In A Cost-Effective Manner.</td>
                                        <td>One-Click Search Saves Time</td>
                                    </tr>
                                    <tr>
                                        <td>Save Time & Individual Efforts On Search </td>
                                        <td>Work On Hot Leads From The Best Of The Industries</td>
                                        <td>Best Price Gauranteed </td>
                                    </tr>
                                    <tr>
                                        <td>Best Quotes Gauranteed</td>
                                        <td>Save Lead Generation Cost, Time & Efforts </td>
                                        <td>User-Friendly Experience </td>
                                    </tr>
                                    <tr>
                                        <td>Smooth Contracting Experience </td>
                                        <td>Real-Time Assistance</td>
                                        <td>Quick Turnaround Time</td>
                                    </tr>
                                    <tr>
                                        <td>Real-Time Conversations & Feedbacks</td>
                                        <td>Verified Businesses Only </td>
                                        <td>Verified Service Partners </td>
                                    </tr>
                                    <tr>
                                        <td>Safe Payment Policy - Gauranteed!</td>
                                        <td>Timely Payments - Assured! </td>
                                        <td>Verified Businesses Only </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                    </div>
                </section>
                <HomeAboutus />
                <div className="h-service">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 service-title-home">
                                <SectionsTitle title="All Business Solutions and Professional Services are performed by our exclusive network of verified Service Partners" subtitle="Our Services" />
                            </div>
                            {/* <div className="col-4">
                                <div className="service-button text-right">
                                    <Link to="/service-details" className="theme-button">All Services <FiChevronRight className="icon" /></Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <Service { ...this.props } />
                </div>
                {/* <Divider /> */ }
                {/* <HomeTrust /> */ }
                {/* <Divider /> */ }
                {/* <About /> */ }
                {/* <HomeAboutus /> */ }
                {/* <CtaForm /> */ }
                {/* <NumberSpeak /> */ }
                <section className="testimonial-wrapper ">
                    <div className="text-center">
                        <SectionsTitle title="What Our Clients Say" />
                    </div>
                    <HomeTestimonial />
                </section>
                {/* <Cta /> */ }
                {/* <Team /> */ }
                {/* <Divider /> */ }
                {/* <ChartArea /> */ }
                {/* <Clients /> */ }
                {/* <Pricing /> */ }

                {/* <Divider /> */ }
                {/* <div className="h-blog-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-8">
                                <SectionsTitle title="Our Recent News." subtitle="Latest From The Blog" />
                            </div>
                            <div className="col-4 text-right">
                                <Link to="/blog-grid" className="theme-button">View All Posts <FiChevronRight className="icon" /></Link>
                            </div>
                        </div>
                    </div>
                    <Blog />
                </div> */}

                <Footer />
            </>
        )
    }
}

