import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import Pricing from '../components/other/Pricing'
import CtaForm from '../components/other/CtaForm'
import Footer from './blocks/Footer';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Privacy Policy" />
                <div className="container plain-pricing-table">
                    <div className="privacy-policy-container">
                        <div>
                            THIS PRIVACY POLICY (“Policy”) has been drafted in accordance with the Information Technology Act, 2000 and the
                            Information Technology (Reasonable security practices and procedures and sensitive personal data or information)
                            Rules, 2021.
                        </div>
                        <div> At SERVIZKART, a brand owned by Stellular Services Private Limited, (hereinafter “Company”, “we”, “our” or
                            “us”),
                            your privacy is of great importance to us. We understand that you entrust us with certain Personal Information
                            (<em>as defined below</em>) to help us provide various services. In exchange for your trust, you expect and
                            deserve
                            our commitment to treat your information with respect and in accordance with the terms of this privacy policy
                            ("<strong>Privacy Policy</strong>"). The Company values your privacy.</div>

                        <div> In this Privacy Policy ("Policy"), we describe the information that we collect about you, how we use and
                            disclose
                            that information, when you use and access the https://www.servizkart.com (the “Website”) and the “SERVIZKART”
                            technology platform  accessible through desktops, mobile phones, smart phones and tablets (the “Application”)
                            (Website and Application collectively referred to as “SERVIZKART Platform”/ “Platform”). The words “you” or
                            “your” as used herein, refer to all individuals, including Clients and Partners and/or entities accessing or
                            using the Website, Application, Platform or Services offered therein for any reason. The Company is the owner of
                            the Servizkart Platform.<br />
                            If you have any questions or comments about the Privacy Policy, please contact us
                            at <u>connect@Servizkart.com</u>. This Policy is incorporated into and is subject to the Company’s Terms of Use
                            with Clients and Partners, which can be accessed at <u>www.Servizkart.com</u>. Your use of the Website,
                            Application, Servizkart Platform and/or Services offered thereunder and any personal information you provide on
                            therein remains subject to the terms of the Privacy Policy and Terms of Use.<br />
                            BY ACCESSING OR USING THE WEBSITE, THE APPLICATION, SERVIZKART PLATFORM. AND /OR SERVICES PROVIDED THEREIN, YOU
                            ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THIS PRIVACY POLICY AND OUR TERMS OF USE. IF
                            YOU DO NOT AGREE TO THE TERMS AND/OR PRIVACY POLICY DO NOT USE THE SERVICES.</div>

                        <div>
                            <strong>YOUR PRIVACY - OUR COMMITMENT</strong><br />
                            We recognize the importance of your personal privacy. Please read the following policy to understand how your
                            Personal Information will be treated as you make full use of our Services through the Website and/or
                            Application.
                            Except as disclosed in this Privacy Policy or as otherwise authorized by you, we will never share your Personal
                            Information with third parties for their marketing purposes. Your trust and confidence are our highest
                            priority.<br />
                            <div>
                                <div>
                                    <strong>CONSENT</strong>
                                </div>
                                <div>By using our Services you understand and accept this Privacy Policy along with any changes as may be
                                    made
                                    from
                                    time
                                    to time and thereby expressly consent to our collection, storage, processing, usage, disclosure and
                                    transfer
                                    of
                                    Your
                                    Information (as defined below) in accordance with this Privacy Policy. If you are not agreeable to this,
                                    please
                                    do
                                    not use the Platform or the Services. You acknowledge that You are providing Your Information out of
                                    Your
                                    free
                                    will.<br />
                                    You have the option not to provide us the Information sought to be collected. If You do not provide us
                                    Your
                                    Information or if You withdraw the consent at any point in time, we will not be able to offer the
                                    Services
                                    available
                                    on the Platform. Furthermore, certain information about you, may automatically be tracked by us based
                                    upon
                                    your
                                    behaviour on our website.
                                </div>
                                <div><strong>DEFINITIONS</strong></div>
                            </div>
                        </div>
                        <div>As used in these Terms, the following terms shall have the meaning set forth below:</div>
                        <div>
                            <div>“<strong>Bid</strong>” shall mean the proposal that the Partner submits in response to the <strong>RFQ
                            </strong>(Request for Quotation) posted by the Client on the Servizkart Platform. As per the Terms of Use,
                                every
                                Bid submitted by the Partner shall be routed only through the Servizkart Platform. </div>
                        </div>
                        <div>
                            <li>“<strong>Confidential Information</strong>” shall mean all information and materials furnished by a party
                                which:
                                (a) if in written format is marked as confidential, or (b) if disclosed verbally is noted as confidential at
                                time of disclosure, or (c) in the absence of either (a) or (b) is information which a reasonable party would
                                deem to be non-public information and confidential. Confidential Information shall include, but not be
                                limited
                                to the Client Information; such as details about the Project, such as specific requirements, specifications,
                                description of services mentioned in the Project; all types of data, information, ideas, processes,
                                procedures,
                                software specifications, technical processes, product designs, financial information, business plans,
                                projections, marketing data and other similar information provided by one party to the other; documentation,
                                the
                                existence and contents of this Agreement or agreements between Servizkart, Client and Partner, whether such
                                is
                                transmitted in writing, orally, visually, (e.g. video terminal display) or on magnetic media, and shall
                                include
                                all proprietary information, customer and prospect lists, trade secrets, or proposed trade names, know-how,
                                concepts, drawings, flow charts, diagrams and other intellectual property relating to the subject matter of
                                this
                                Agreement.</li>
                            <li>“<strong>Client</strong>” means anyone who signs up and registers as a customer by creating an Account
                                through
                                the Servizkart Platform and who posts RFQ.</li>
                        </div>
                        <div>
                            "<strong>Client Information" </strong>means information regarding Clients which includes information such as
                            name and address, legal status, nature of business, company information and related documents, details of
                            the
                            assignment, etc.
                        </div>
                        <div>
                            “<strong>Effective Date</strong>” means the Date on which You accept these Terms by clicking ‘Log in’, 'Sign
                            Up'
                            or 'I Accept' or entering into an agreement with the Company.
                        </div>
                        <div>
                            “<strong>Nodal Account</strong>” means such bank account maintained by Servizkart so as to hold the payments
                            payable by the Client to the Partner for their Services in a Project, and from which account the Partner
                            shall
                            be paid for the Service upon completion of the Project or as per a timeline mutually agreed upon by the
                            Client
                            and Partner.
                        </div>
                        <div>
                            “<strong>Project</strong>” shall mean the assignment for which the Client wishes to engage the Services
                            offered
                            on the Servizkart Platform through the Partners who have registered therein and which may include details
                            such
                            as service type, category and sub-category, duration, value, key deliverables, milestones & payment details,
                            location (and whether the assignment can be completed remotely), scope of work, work description, which is
                            provided by a Client to the Partners so that they can Bid for the Project.
                        </div>
                        <div>
                            <li>“<strong>Partner</strong>” could include a complete agency itself which specializes in offering Services for
                                which the RFQ is posted or duly qualified and experienced professionals working in that agency depending
                                upon
                                the nature and scope of the Work. The Client could either hire an agency or resources from the agency
                                depending
                                upon the requirements of the Work.</li>
                            <li>“<strong>Partner Information</strong>” means information pertaining to name, address, documents such as
                                certificates/licenses/registrations to verify the legal status, expertise in a specific field, copies of
                                certificates/degrees/qualification/certifications in the area of specialization and expertise, professional
                                memberships and such other information/documents that the Company may require to be submitted from time and
                                time
                                or as the case may be etc.</li>
                        </div>
                        <div>
                            <li>“<strong>Work Agreement</strong>” means an Agreement entered into by the Partner and Client before the
                                Partner
                                starts to offer Services to the Client and which may be either provided by the Company or furnished by the
                                Client or Partner as the case may be.</li>
                        </div>
                        <div>
                            <li>All other capitalized terms shall have the meaning ascribed to them in the Other Terms.</li>
                        </div>
                        <div> <br />
                            <li><strong>INFORMATION WE COLLECT</strong></li>
                        </div>
                        <div>
                            <li><strong>Information Provided to Us:</strong></li>
                        </div>
                        <div><strong>Personal Information:</strong></div>
                        <div>
                            <li><strong>Client: </strong>We receive and store Personal Information (i.e. includes information that whether
                                on
                                its own or in combination with other information may be used to readily identify or contact you such as:
                                name,
                                address, email address, phone number, and Non-Personal information such as legal status, nature of business,
                                company information provided by you when you set up an account upon registration (Account) and use the
                                Website
                                and/or Application. The Information provided by you, for the purposes of this Privacy Policy, may include,
                                but
                                are not limited to: (i) first and last name; (ii) your appearance/ photographs; (iii) a home or other
                                physical
                                address; (iv) an email address or other contact information including mobile / landline numbers; (v) office
                                address and company ownership details; (vi) information pertaining to your use of our Services through the
                                Website and/or Application including information such as information pertaining to details of the Project
                                such
                                as specifications, milestones, the time lines, budget etc., the Bids received, which could be used to
                                personally
                                identify you (vii) demographic information (e.g., gender, age, political preference, education, race or
                                ethnic
                                origin, and other information relevant to user surveys and/or offers ) and (viii) the details that you post
                                in
                                your Profile, wants, or feedback, and any comments or discussions you post in any blog, chat room, or other
                                correspondence on the Website or Application that will enable people to personally identify you and (ix)
                                payment
                                information such as credit card information bank details, from you. You always have the option to not
                                provide
                                information by choosing not to use a particular service or feature. Importantly, we only collect Personal
                                Information about you that we consider necessary for achieving this purpose and communicating with you about
                                the
                                Services being offered.</li>
                            <li><strong>"Service" </strong>means services offered on the Platform through Partners who specialize in
                                relevant
                                fields required for the RFQ posted by the Clients such as Finance, Legal, Supply Chain, Real Estate,
                                Architect,
                                Software Development, mobile, and web development, software development, IT services, BPOs, KPOs, and such
                                other
                                services as the Company may offer from time to time. Such services are in the form of Business Solutions,
                                Instant Services or Over the Counter Services, Subscription based service fee, Servizkart Buddy Fee or any
                                other
                                fee which may be introduced by the Company from time to time.</li>
                        </div>
                        <div>
                            <li><strong>Partners: </strong>We receive and store Personal Information, (i.e. any information that may be used
                                to
                                identify you) and Non-Personal information provided by Partners when you set up an account upon registration
                                (Account) and use the Website and Platform. Your Personal Information, for the purposes of this Privacy
                                Policy,
                                includes, but is not limited to: (i) your name and address; (ii) your business name and address; (iii)
                                information pertaining to your qualification, experience, specialization, consultation fees, timeline for
                                completing the work, your education, professional memberships, (iv) details of the Bid submitted and
                                communication with the Client regarding the Work, (v)your other contact information (including mobile /
                                landline
                                numbers), whether at work or at home; (vi) documents such as relevant registration/permissions required for
                                offering your services; (vii) username for accessing Account on our Website or Application.</li>
                            <br />
                            <strong>By continuing to use our Website and/or Application you are deemed to have read the Privacy Policy and
                                understood the purpose for which your Personal or Non-Personal Information is being collected and how the
                                same
                                shall be used and granting your express consent to such purpose and use. If, at a later date, you wish to
                                withdraw this consent, please send us an email
                                at </strong><u><strong>connect@Servizkart.com</strong></u>
                        </div>
                        <div>
                            <strong>Non-Personal Information</strong>
                        </div>
                        <div>

                            <li><strong>Information Collected Automatically</strong>: When you use the Website and/or Application, we
                                automatically receive and record information on our server logs from your browser or mobile, including but
                                not
                                limited to your hardware model, operating system version, unique device identifiers, mobile network
                                information
                                including location, URL that you just came from (whether this URL is on our Website or not), which URL you
                                next
                                go to (whether this URL is on our Website or not), your computer browser information, IP address and cookie
                                information. We treat this data as Non-Personal Information, except where we are required to do otherwise
                                under
                                applicable law.</li>
                        </div>
                        <div>
                            <li><strong>Data Collection Devices</strong>: We use data collection devices such as "cookies" on certain pages
                                of
                                the Website and/or Application to help analyze our web page flow, measure promotional effectiveness, and
                                promote
                                trust and safety. "Cookies" are small files placed on the hard drive of your computer that assist us in
                                providing our Service. We offer certain features that are only available through the use of a "cookie". We
                                also
                                use cookies to allow you to enter your password less frequently during a session. Cookies can also help us
                                provide information that is targeted to your interests. Most cookies are "session cookies," meaning that
                                they
                                are automatically deleted from your hard drive at the end of a session. You are always free to decline our
                                cookies if your browser permits, although in that case you may not be able to use certain features on the
                                Website and/or Application and you may be required to re-enter your password more frequently during a
                                session.
                            </li>

                        </div>
                        <div>
                            <li><strong>USE OF INFORMATION COLLECTED</strong></li>
                            Personal Information about Clients and Partners is an integral part of our business. We neither rent nor sell
                            your
                            Personal Information to anyone. We may share your Personal Information only as described in this Privacy Policy
                            and
                            related documents.

                            <li><strong>Use of Personal Information:</strong></li>
                        </div>
                        <div>
                            We will use and store Personal Information to enable you to offer or receive services, including registering you
                            for
                            offering or receiving our services, verifying your identity and authority to offer or use our services, and to
                            otherwise enable you to use our Website, Application and our Services; to maintain, and improve our Services,
                            including, for example, to facilitate payments, provide Services you request (and send related information),
                            develop
                            new features, provide customer support to Clients, develop safety features, authenticate users, and send updates
                            and
                            administrative messages, to market the Service and for the internal operational and administrative purposes of
                            the
                            Service, to process billing and payment, including sharing with third party payment gateways in connection with
                            Website, Application and Services. If you send us personal correspondence, such as emails or letters, or if
                            other
                            users or third parties send us correspondence about your activities or postings on the Website or Application we
                            may
                            collect such information into a file specific to you. You agree that we may use Personal Information about you
                            to
                            improve our marketing and promotional efforts, to analyze Website and Application usage, improve the Website and
                            Application content and Service offerings, and customize the Website and Application content, layout, and
                            Services.
                            These uses improve the Website and Application and better tailor it to meet your needs, so as to provide you
                            with a
                            smooth, efficient, safe and customized experience while using the Website and/or Application.</div>

                        <div> You agree that we may use your Personal Information in the file we maintain about you, and other information
                            we
                            obtain from your current and past activities to: resolve disputes; troubleshoot problems; help promote safe
                            trading;
                            measure consumer interest in the Services provided by us; inform you about online and offline offers, services,
                            and
                            updates; customize your experience; detect and protect us against error, fraud and other criminal activity;
                            enforce
                            our Terms of Use; and as otherwise described to you at the time of collection.</div>
                        <div>

                            <li><strong>Aggregate Information: </strong>We will also use Personal Information and non-personally
                                identifiable
                                information and other information (including information from online and offline third party sources) to
                                create
                                aggregated data for analytical and other purposes. We use this information to do internal research on your
                                demographics, interests, and behavior to better understand, protect and serve You. This information is
                                compiled
                                and analyzed on an aggregated basis. Unless otherwise stated in this Privacy Policy, We only use this data
                                in
                                aggregate form.</li>

                        </div>
                        <div>
                            <li><strong>Non-personally identifiable information: </strong>We may freely use Non Personally Identifiable
                                Information in connection with the Services offered through the Website and Application, including: to
                                administer the Services being offered thereunder, to understand how well our Service is working, to market
                                the
                                Services and other products and services, to develop aggregate, statistical information on usage of the
                                Service
                                and to generally improve the Service.</li>
                        </div>
                        <div>
                            <li><strong>Email Communication/Text Messages</strong>: If you create an account and provide your email address
                                and
                                phone number, we will send you administrative and promotional emails and text messages. If you wish to opt
                                out
                                of promotional emails or text messages, you may do so by following the "unsubscribe" instructions in the
                                email,
                                "opt out" instructions in the text message or by editing your account settings. All Clients and Partners
                                will
                                receive administrative emails and/or text messages.</li>
                            <li><strong>OUR DISCLOSURE OF YOUR INFORMATION</strong></li>
                        </div>
                        <div> We may also use your Personal Information to deliver information to you that, in some cases, are targeted to
                            your
                            interests, such as new services, rewards and promotions. The following are some circumstances under which and
                            people
                            to whom we would share your Information with from time-to-time:</div>

                        <div>
                            <li><strong>Disclosure of Personal Information:</strong></li>
                        </div>
                        <div><strong> Clients</strong>: We do not disclose your email address, telephone number and mailing address to any
                            third
                            party for marketing purposes. Except as otherwise provided under this Privacy Policy, we will disclose your
                            Personal
                            Information to third parties only in the following limited circumstances:<br />
                            <li>if you use the Service through the Website and/or Application, we may disclose your
                                Information to the associated Partners who have listed their services on the Website and Application to
                                respond
                                to the proposals submitted by you pertaining to RFQ by posting Bids on the Website and /or Application. For
                                instance, we may share details of your Company name, address, details of the Work, specifications,
                                timelines,
                                deliverables, etc, and certain contact information (depending upon your location and applicable laws) to
                                enable
                                them to submit bids to the proposals and offer their services ;</li>
                            <li>to trusted third parties who work on behalf of or with us under confidentiality agreements. These entities
                                may
                                use your Personal Information for performing services, administering promotions, analyzing data and usage of
                                the
                                Service through the Website and Application, processing credit card payments, operating the Service or
                                providing
                                support and maintenance services for the same, or providing customer service.</li>
                            <li>when we have your consent to otherwise share the information.</li>

                        </div>

                        <div>
                            <li><strong>Partners: </strong>We do not disclose your email address, telephone number and mailing address to
                                any
                                third party for marketing purposes. Except as otherwise provided under this Privacy Policy, we will disclose
                                your Personal Information to third parties only in the following limited circumstances:</li>
                            <li>if you use the Service through the Website and/or Application, we may disclose your Information to Clients
                                in
                                connection with the Bids submitted by you in relation to the RFQ posted by them on the Website and/or
                                Application. For instance we may share details of your name, qualification, experience, specialization,
                                consultation fees, education, professional memberships, and certain contact information (depending upon your
                                location and applicable laws), etc.</li>
                            <li>to trusted third parties who work on behalf of or with us under confidentiality agreements. These entities
                                may
                                use your Personal Information for performing marketing services, administering promotions, analysing data
                                and
                                usage of the Service through the Website and Platform, processing credit card payments, operating the
                                Service or
                                providing support and maintenance services for the same, or providing customer service.</li>
                            <li>when we have your consent to otherwise share the information.</li>
                        </div>
                        <div>
                            <li> <strong>Non-Personally Identifiable Information</strong>: We may disclose other Non-Personally Identifiable
                                Information to third party advertisers and advertising agencies, partners, and other parties in order for us
                                and
                                such third parties to analyse (a) the performance of, to operate and improve the Services offered through
                                the
                                Website and Application, (b) the behaviour of Clients and to target offers to Clients depending upon their
                                requirements and (c) the behaviour and ratings of Partners. We may also disclose, use or publish this
                                information for promoting the Services offered under the Website and/or Application. These third parties are
                                subject to confidentiality agreements with us and other legal restrictions that prohibit their use of the
                                information we provide them for any other purpose except to facilitate the specific outsourced operation,
                                unless
                                you have explicitly agreed or given your prior permission to them for additional uses.</li>
                        </div>
                        <div>
                            <li><strong> Aggregate Information</strong>: Because the aggregate information we collect does not specifically
                                identify you, it may be shared with any number of parties. For example, we may disclose anonymous,
                                aggregated
                                information to third parties such as potential business partners, end users, Partners, advertisers, and
                                funding
                                sources, in order to describe our business and operations.</li>

                        </div>
                        <div>
                            <li><strong>Network Operators</strong>: Use of the Service provided through the Website and Application may
                                involve
                                use of the services of third party telecommunications carriers and Service providers (such as the services
                                of
                                the carrier that provides cell phone service to you). Such carriers and Service providers are not our
                                contractors, and any information that a carrier collects in connection with your use of the Service provided
                                through the Website and/or Application is not "Personal Information" and is not subject to this Privacy
                                Policy.
                                We are not responsible for the acts or omissions of telecommunications carriers or Service providers.</li>

                        </div>
                        <div>
                            <li><strong> Additional Disclosures</strong>: Notwithstanding anything to the contrary in this Privacy Policy,
                                We
                                reserve the right to use or disclose Personal Information and any other information we collect in connection
                                with the Service offered through the Website and/or Application (a) to any successor to our business,
                                including
                                as a result of any merger, acquisition, asset sale or similar transaction, (b) to any corporate affiliate of
                                ours whose privacy practices are substantially similar to ours, (c) to any law enforcement, judicial
                                authority,
                                governmental or regulatory authority, to the extent required by law or legal process, or (d) if in our
                                reasonable discretion, such use or disclosure is necessary to enforce or protect our legal rights or to
                                protect
                                third parties.</li>

                        </div>
                        <div>
                            <li><strong>STORING YOUR INFORMATION</strong></li>
                            Our retention periods for personal data are based on business needs and legal requirements. We
                            retain personal data for as long as is necessary for the processing purpose(s) for which the information was
                            collected, and any other permissible, related purpose. For example, we may retain certain correspondence and
                            transaction details until the time limit for claims arising from the transaction has expired, or to comply with
                            regulatory requirements regarding the retention of such data. When personal data is no longer needed, we either
                            irreversibly anonymize the data (and we may further retain and use the anonymized information) or securely
                            destroy
                            the same.
                            <li><strong>ACCESS OR CHANGE YOUR PERSONAL INFORMATION</strong></li>
                            You may review, correct, update, change or delete your information at any time by contacting us
                            at <u>connect@Servizkart.com</u>. To protect your privacy and security, we will verify your identity before
                            granting
                            access or making changes to your Personal Information.<br />
                            Even after you remove information from the Platform, copies of that information may remain viewable elsewhere,
                            to
                            the extent it has been shared with others or it was otherwise distributed pursuant to your privacy settings.
                            Removed
                            and deleted information may remain on backup media for up to ninety (90) days prior to being deleted from our
                            servers or for a period as required by law.
                            <li><strong>UNSUBSCRIBING</strong></li>
                            Our aim is to provide convenience to our customers and hence, we provide you with the opportunity to opt-out or
                            unsubscribe from receiving any non-essential conversations or emails from our end on behalf of our partners
                            after
                            you have set up your accounts. The customers can also remove their contact details by removing their
                            subscription.

                            <li><strong>ADVERTISEMENT</strong></li>
                            We may use third party advertising to showcase advertisements on our website. Hence, these third parties/
                            companies
                            may use information about your visits to our and other websites in order to provide advertisements about the
                            goods
                            and services that may interest you. It is to be noted that information such as name, address, email address, or
                            telephone number would not be included in the information that the third party may use.
                            <li><strong>OTHER WEBSITES</strong></li>
                            Our Website and/or Application may contain links to other websites. Please note that when you click on one of
                            these
                            links, you are entering another website/application over which we have no control and will bear no
                            responsibility.
                            Often these websites/applications require you to enter your Personal Information. We encourage you to read the
                            privacy statements on all such websites/application as their policies may differ from ours. You agree that we
                            shall
                            not be liable for any breach of your privacy of Personal Information or loss incurred by your use of these
                            websites
                            /application. We are not responsible for the privacy policies and/or practices on other sites. This Privacy
                            Policy
                            only governs information collected by us.
                            <li><strong>ACKNOWLEDGEMENTS</strong></li>
                            You hereby agree and acknowledge that the application for registration and copies of all documents submitted by
                            you
                            are and shall remain the sole and exclusive property of Company, and Company shall not be obliged to return the
                            same
                            to you upon your request. Any unauthorized access to the Platform is a breach of these terms and a violation of
                            the
                            law. You agree not to access the Platform by any means other than through the interface that is provided by
                            Company.

                            <li><strong>AMENDMENTS TO THE PRIVACY POLICY</strong></li>
                            We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy
                            Policy in effect at the time such information is used. If we decide to change our Privacy Policy, we will post
                            those
                            changes on this page so that you are always aware of what information we collect, how we use it, and under what
                            circumstances we disclose it. Clients and Partners are bound by any changes to the Privacy Policy when they
                            continue
                            to use the Website(s) and/or Application after such changes have been first posted.
                        </div>
                        <div>
                            <li><strong>CONTACTING US</strong></li>
                            If you have any questions about this Privacy Policy or the privacy practices of this Website or Application you
                            can
                            contact us at <u>connect@Servizkart.com </u>We will make every effort to resolve your concerns.<br />
                            <br />
                            <li><strong>SECURITY OF PERSONAL INFORMATION</strong></li>
                            We take reasonable security measures and procedures, and as specified by applicable law, to maintain
                            appropriate
                            physical, technical and administrative security to help prevent loss, misuse, or unauthorized access, disclosure
                            or
                            modification of Personal Information. While we take these reasonable efforts to safeguard your personal
                            information,
                            you acknowledge and agree that no system or transmission of data over the Internet or any other public network
                            can
                            be guaranteed to be 100% secure. You are aware of all security risks, including possible third-party
                            interception of
                            any of your transactions and statements of account on the internet and the content of your transactions or
                            statements of account becoming known to third parties. You should take steps to protect against unauthorized
                            access
                            to your password, phone, and computer by, among other things, signing off after using a shared computer,
                            choosing a
                            robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are
                            not
                            responsible for the unauthorized use of your information or for any lost, stolen, compromised passwords, or for
                            any
                            activity on your Account via unauthorized password activity. You agree and undertake that you shall not hold
                            Company
                            liable therefore in any way. The use and storage of any information, including without limitation, password,
                            account
                            information, transaction activity and any other information available on your internet access device or computer
                            or
                            mobile phone is at your own risk and responsibility. You furthermore acknowledge and accept that Company may not
                            encrypt all content or communications shared on the Platform.
                            <li><strong>GRIEVANCE OFFICER</strong></li>

                        </div>
                        <div> In accordance with Information Technology Act 2000 and rules made there under, in case of grievances you can
                            contact:</div>
                        <div>Attention: Grievance Officer</div>
                        <div>Email: asingh@Servizkart.com<br />
                            In the event you wish to make a complaint regarding any violation of the provisions of the Privacy
                            Policy, you may send a written complaint to the Grievance Officer, who shall redress the complaint within one
                            (1)
                            month.
                        </div>
                        <div> <br />
                            <li><strong>GOVERNING LAW</strong></li>
                            This Policy shall be governed by and construed in accordance with the laws of the Republic of India. The courts
                            at
                            Gurgaon, India shall have exclusive jurisdiction in relation to any disputes arising out of or in connection
                            with
                            this Policy.<br />
                            <strong>Last updated on 1<sup>st</sup>August, 2021.</strong>
                        </div>
                        <div> Copyright © 2021 Servizkart a brand owned by Stellular Ventures Private Limited. All Rights Reserved<br />
                            Please reach out to us at <u>connect@servizkart.com </u>for any query, clarification or feedback, we will be
                            happy
                            to assist.</div>
                    </div>

                </div>

                <Footer />
            </>
        )
    }
}
