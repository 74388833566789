import React, { Component } from 'react'
import Navbar from "../../../components/common/Navbar";
import BreadcrumbSmall from '../../../components/common/BreadcrumbSmall';
import UserSignUpForm from './SignUpForm';
import Footer from '../../blocks/Footer';

export default class UserSignUpPage extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <BreadcrumbSmall title="Sign Up" />
                <UserSignUpForm />
                {/* <Footer /> */}
            </>
        )
    }
}
