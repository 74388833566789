import React from 'react';

export default function JobProgress({ data }) {
    return (
        <table className="detail-table">
            <thead>
                <tr>
                    <th>Job No</th>
                    <th>Job Name   </th>
                    <th>Job Category  </th>
                    <th>Status </th>
                    <th>Partner</th>
                    <th>Target Date</th>
                    <th>Completion Date</th>
                </tr>
            </thead>
            { data && data.length > 0 && data.map((row, index) => (
                <tr key={ index }>
                    <td component="th" scope="row">
                        { row.name }
                    </td>
                    <td>{ row.alert_mobile_no }</td>
                    <td >{ row.session }</td>
                </tr>
            )) }
        </table>
    );
}
