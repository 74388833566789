import React, { Component } from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaGooglePlusG } from 'react-icons/fa'
import ContactSidebar from './ContactSidebar'
import Maps from '../contact/Maps'
import SubscribeForm from '../other/SubscribeForm'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import * as apiClient from "../../components/network/apiClient";
import { ToastContainer, toast } from 'react-toastify';
import ben_1 from "../../assets/images/ben_1.png";
import ben_2 from "../../assets/images/ben_2.png";
import ben_3 from "../../assets/images/ben_3.png";
import ben_4 from "../../assets/images/ben_4.png";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class CareerDetails extends Component {
    constructor(props) {
        super(props);
       // console.log(props);
        this.state = {
            fullName: "",
            email: "",
            mobile: "",
            position:"",
            notice:"",
            linkin:"",
            subject: "Appy for Position",
            resume: "",
            message: "",
            requirementSubmitted: false,
            isPopupDisplayed: false,
        };
    }
    addServiceRequirement = () => {
        const { fullName, email, mobile, message,resume, position, notice, linkin } = this.state;

        let final_resume_url = "";
        if(resume){
            let resume_file = apiClient.UploadFile("resume", resume);
            final_resume_url = resume_file.newUrl;
        }

        if (fullName == "") {
            toast.error("Name is required");
            return;
        }

        if (email == "") {
            toast.error("Email address is required");
            return;
        }
        if (mobile == "") {
            toast.error("Mobile number is required");
            return;
        }


        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(email)) {
            toast.error("Email address is not valid");
            return;
        }

        const pattern = /^[0-9 '.-]+$/;
        if (!pattern.test(mobile)) {
            toast.error("Only numbers are allowed in mobile");
            return;
        }

        if (mobile.length < 10) {
            toast.error("mobile number is not valid");
            return;
        }
        
        let data = {
            Name: fullName,
            EmailID: email,
            Mobile: mobile,
            Remarks: message,
            ResumeUrl: final_resume_url,
            Position: position,
            NoticePeriod: notice,
            LinkedIn: linkin
        };
        apiClient.submitCareerRequirment(data).then(res => {
            this.setState({ requirementSubmitted: true , isPopupDisplayed: false});
        }).catch(err => {
            console.log(err);
        })
    }
    popupToggle = () => {
        const { isPopupDisplayed } = this.state;
        let c = !isPopupDisplayed;
        this.setState({ isPopupDisplayed: c });
    }

    componentDidMount() {

    }
    render() {
        const { isPopupDisplayed, fullName, email, mobile, subject, message, requirementSubmitted , position, notice, linkin, resume} = this.state;
        return (
            <>
                <section className="contact-form-wrapper mgtp20">
                    <div className="container">
                        {<div className="contact-form-grid career-page ">
                            <div className="full-width">
                                <SectionsTitle title="Explore a career at Servizkart" />
                                <p>Join our team to help reshape the outsourcing and make business solutions that can reach to millions businesses and individuals.</p>

                                <p>There are jobs and then there are careers. The chance to do the kind of work that adds up to something meaningful in various functions. The opportunity to challenge yourself and learn new skills.</p>

                                {/* <strong>Come Join Us</strong>
                            <div>Fill the form or write to us at <a href="mailto:careers@servizkart.com">careers@servizkart.com</a></div> */}
                            </div>
                            <div className="ben-heading">Benefits at Servizkart</div>
                            <div className="benifits-container">
                                <div className="benifit-parent">
                                    <div class="image-container">
                                        <img src={ ben_1 } className="ben-image" />
                                        <div class="overlay ben1">
                                        </div>
                                    </div>
                                    <div className="ben-caption">Work From Anywhere</div>
                                </div>
                                <div className="benifit-parent">
                                    <div class="image-container">
                                        <img src={ ben_2 } className="ben-image" />
                                        <div class="overlay ben2">
                                        </div>
                                    </div>
                                    <div className="ben-caption">Flexible Hour Working</div>
                                </div>
                                <div className="benifit-parent">
                                    <div class="image-container">
                                        <img src={ ben_3 } className="ben-image" />
                                        <div class="overlay ben3">
                                        </div>
                                    </div>
                                    <div className="ben-caption">Women Empowerment -<br /> Opportunity for Qualified<br /> Homemakers</div>
                                </div>
                                <div className="benifit-parent">
                                    <div class="image-container">
                                        <img src={ ben_4 } className="ben-image" />
                                        <div class="overlay ben4">
                                        </div>
                                    </div>
                                    <div className="ben-caption">Senior Citizen Support -<br />  Opportunity for Qualified Retired <br />Professionals/Experts</div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="full-width">
                                    <div className="ben-heading-left">Come Join us</div>
                                    <div><span
                                        onClick={ () => {
                                            this.popupToggle();
                                        } }
                                        className="theme-button apply-here">Apply Here</span> or write to us at <a href="mailto:careers@servizkart.com">careers@servizkart.com</a></div>
                                </div>
                                { requirementSubmitted && <div className="inner-heading-left">Thanks for submitting the request. We will contact you shortly.</div> }
                            </div>
                            <div className="full-width career-openings">
                                <div className="ben-heading">Current Openings:</div>
                                <table id="openings">
                                    <tr>
                                        <th>Job Title</th>
                                        <th>Function</th>
                                        <th>Location</th>
                                        <th>Required Experience</th>
                                    </tr>
                                    <tr>
                                        <td>Servizkart Buddy</td>
                                        <td>Sales, Relationship</td>
                                        <td>Work from Anywhere, Flexi Working</td>
                                        <td>Domain Specific from 0-5 years</td>
                                    </tr>
                                    <tr>
                                        <td>Manager </td>
                                        <td>Partner Relations</td>
                                        <td>Work from Anywhere, Flexi Working</td>
                                        <td>B2B Customer Service Experience 2-5 Years</td>
                                    </tr>

                                    <tr>
                                        <td>Associate </td>
                                        <td>Partner Relations</td>
                                        <td>Work from Anywhere, Flexi Working</td>
                                        <td>B2B Customer Service Experience 0-2 Years</td>
                                    </tr>
                                    <tr>
                                        <td>Manager </td>
                                        <td>Client  Relations</td>
                                        <td>Work from Anywhere, Flexi Working</td>
                                        <td>B2B Customer Service Experience 2-5 Years</td>
                                    </tr>

                                    <tr>
                                        <td>Associate </td>
                                        <td>Client  Relations</td>
                                        <td>Work from Anywhere, Flexi Working</td>
                                        <td>B2B Customer Service Experience 0-2 Years</td>
                                    </tr>

                                    <tr>
                                        <td>Assistant Manager </td>
                                        <td>Marketing</td>
                                        <td>Work from Anywhere, Flexi Working</td>
                                        <td>Social Media Handling and Offline Promotion 2-3 Years’ Experience</td>
                                    </tr>

                                    <tr>
                                        <td>Service Category Advisors </td>
                                        <td>Freelance</td>
                                        <td>Work from Anywhere, Flexi Working</td>
                                        <td>Domain Specific – Subject Matter Experts</td>
                                    </tr>
                                </table>
                                <div className="career-bottom-heading">
                                    We are excited to know about your keen interest to pursue a career opportunity with Servizkart. Our current openings have been listed above. You can also submit your resume in for our Database. As we evaluate your profile, we shall get in touch with you. Thank you once again and wish you all the best.
                                    <br />
                                    For Job Description and other information, write to us at <a href="mailto:careers@servizkart.com">careers@servizkart.com</a>

                                </div>
                            </div>
                        </div> }
                    </div>
                </section>

                <Modal isOpen={ isPopupDisplayed } toggle={ this.popupToggle } className="my-model-popup">
                    <ModalHeader toggle={ this.popupToggle }>
                        Apply Here
                    </ModalHeader>
                    <ModalBody>
                        <div className="contact-form-wrapper">
                            <div className="contact-form-grid">
                                <div className="right">
                                    <form >
                                        <div className="form-grid d-grid">
                                            <div className="left">
                                                {/* <label htmlFor="name">Your Name <sup>*</sup></label> */ }
                                                <input type="text" id="name" placeholder="Full Name (*)"
                                                    value={ fullName }
                                                    onChange={ (event) => {
                                                        this.setState({ fullName: event.target.value });
                                                    } }
                                                    required />
                                            </div>
                                            <div className="right">
                                                {/* <label htmlFor="email">Your Email <sup>*</sup></label> */ }
                                                <input type="email" id="email" placeholder="Email Address (*)"
                                                    value={ email }
                                                    onChange={ (event) => {
                                                        this.setState({ email: event.target.value });
                                                    } }
                                                    required />
                                            </div>
                                            <div className="left">
                                                {/* <label htmlFor="number">Your Number <sup>*</sup></label> */ }
                                                <input type="text" id="number" placeholder="Your Phone(*)"
                                                    value={ mobile }
                                                    onChange={ (event) => {
                                                        this.setState({ mobile: event.target.value });
                                                    } }
                                                    required />
                                            </div>
                                            <div className="right">
                                                {/* <label htmlFor="subject">Position Applying for <sup>*</sup></label> */ }
                                                <input type="text" id="position" placeholder="Position Applying for (*)"
                                                    value={ position }
                                                    onChange={ (event) => {
                                                        this.setState({ position: event.target.value });
                                                    } }
                                                    required />
                                            </div>
                                            <div className="left">
                                                {/* <label htmlFor="number">Available Start Date <sup>*</sup></label> */ }
                                                <input type="text" id="notice" placeholder="Notice Period (Days) (*)"
                                                    value={ notice }
                                                    onChange={ (event) => {
                                                        this.setState({ notice: event.target.value });
                                                    } }
                                                    required />
                                            </div>
                                            <div className="right">
                                                {/* <label htmlFor="subject">Link to linked in profile <sup>*</sup></label> */ }
                                                <input type="text" id="linkin" placeholder="Link to linked in profile (*)"
                                                    value={ linkin }
                                                    onChange={ (event) => {
                                                        this.setState({ linkin: event.target.value });
                                                    } }
                                                    required />
                                            </div>
                                        </div>
                                        <div className="form-textarea">
                                            {/* <label htmlFor="message">About You <sup>*</sup></label> */ }
                                            <textarea id="message"
                                                value={ message }
                                                onChange={ (event) => {
                                                    this.setState({ message: event.target.value });
                                                } }
                                                placeholder="Tell us about you and why do you want to join Servizkart"></textarea>
                                        </div>
                                        <div className="form-fileinput">
                                            { <label htmlFor="message">Upload your resume</label>  }
                                            <input type="file"
                                                onChange={ (event) => {
                                                    this.setState({ resume: event.target.files[0] });
                                                } }
                                                placeholder="Upload your resume."/>
                                        </div>
                                        <button type="button" className="theme-button"
                                            onClick={ () => {
                                                this.addServiceRequirement();
                                            } }
                                        >Submit your Candidature <FiChevronRight className="icon" /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.popupToggle }>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
