import React, { Component } from 'react';
import { FiCheckCircle, FiChevronDown, FiChevronRight } from 'react-icons/fi';
import serviceproviders from '../assets/images/serviceproviders.jpg';
import sharerequirement from '../assets/images/sharerequirement.jpg';
import videochat from '../assets/images/videochat.jpg';
import Breadcrumb from '../components/common/Breadcrumb';
import Navbar from "../components/common/Navbar";
import Footer from './blocks/Footer';
import ServiceBuddy from "./services/widgets/ServiceBuddy";
import ServicePricing from "./services/widgets/ServicePricing";
import ServiceProviders from "./services/widgets/ServiceProviders";
import ServiceRequirements from "./services/widgets/ServiceRequirements";
import about_us1 from "../assets/images/sk_buddy1.jpg";
import about_us4 from "../assets/images/sk_buddy2.jpg";
import save_cost from "../assets/images/save_cost.jpg";
import single_contact from "../assets/images/single_contact.jpg";
import easy_to_manage from "../assets/images/easy_to_manage.jpg";
import secure_payment from "../assets/images/secure_payment.jpg";
import professionalguidance from "../assets/images/professionalguidance.jpg";
import projectcompletion from "../assets/images/projectcompletion.jpg";

export default class Servicebuddy_Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPriceDisplayed: false,
            isShareRequirementDisplayed: false,
            isServiceBuddyAvailable: false,
            isAvailableServiceProviders: false,
            isPopupDisplayed: false,
            popupTitle: "More Details",
            iConClicked: "",
            iConCategory: null,
            category: {
                parentId: -1,
                id: -1,
            },
        }
    }
    ToggleAllTabsExcept = (property, value) => {
        value = !value;
        this.setState({
            isPriceDisplayed: false,
            isShareRequirementDisplayed: false,
            isServiceBuddyAvailable: false,
            isAvailableServiceProviders: false,
        }, () => {
            this.setState({
                [property]: value
            })
        });
    }
    render() {
        const { category, isPriceDisplayed, isShareRequirementDisplayed, isServiceBuddyAvailable,
            isAvailableServiceProviders, } = this.state;
        return (
            <main className="about-page">
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="SERVIZKART Buddy Program" />
                <section className="about-wrapper">
                    <div className="container">
                        <div className="about-grid-wrap">
                            <div className="about-left-full">
                                <div className="about-image-text">
                                    <div className="image">
                                        <img src={ about_us1 } />
                                    </div>
                                    <div className="image-details">
                                        <p>
                                            The team at SERVIZKART understands the challenges faced by business houses and individuals while outsourcing their projects to third party service partners since we all have been there once. While effective outsourcing can help companies and individuals reinvent their business strategies as well as their lives, we all know it comes with a huge cost and effort.
                                        </p>
                                        <p>
                                            The biggest investment being time, SERVIZKART ensures you meet your service partner requirements in the most efficient way.
                                        </p>
                                        <p>
                                            SERVIZKART BUDDY PROGRAM available to all our corporate clients as well as service partners, is a collaborative effort to make service partner search easier, smooth and cost-effective!
                                        </p>
                                    </div>
                                </div>

                                <div className="about-image-text reverse">
                                    <div className="image">
                                        <img src={ about_us4 } />
                                    </div>
                                    <div className="image-details">
                                        <p>
                                            <b>Who is a SERVIZKART BUDDY?</b> <br />
                                            A SERVIZKART BUDDY is your best friend at SERVIZKART. A trained procurement and outsourcing specialist by SERVIZKART, a SERVIZKARTBUDDY has a great business insight, turnaround time, negotiation skills and attention to detail that can help you choose the most efficient service partners from the list of our verified service partners.
                                        </p>
                                        <p>
                                            With a premium subscription, A SERVIZKART BUDDY becomes one point contact for you. From initiating the work to project completion, the SERVIZKART BUDDY will work as an intermediary between the service partner and client for ease of communication and timely completion of work. He/she will work on your query and help you narrow down the search to find a suitable vendor for your requirement.
                                        </p>

                                    </div>
                                </div>


                                <p className="about-left-desc buddy-benefits">
                                    <b>SERVIZKART BUDDY Benefits</b>
                                    <ul className="servize-buddy-program">
                                        <li><div className="sbicon"><img src={ save_cost } /></div> Cost & Time saving</li>
                                        <li><div className="sbicon"><img src={ professionalguidance } /></div>  Professional guidance throughout</li>
                                        <li><div className="sbicon"><img src={ single_contact } /></div>  One point of contact for all your queries</li>
                                        <li><div className="sbicon"><img src={ easy_to_manage } /></div>  Easy to manage multiple functions, projects and clients</li>
                                        <li><div className="sbicon"><img src={ projectcompletion } /></div>  Assured project completion within the TAT</li>
                                        <li><div className="sbicon"><img src={ secure_payment } /></div>  Payment guarantee by SERVIZKART</li>
                                    </ul>
                                </p>

                                <div className="service-category-actions">
                                    <div className={ isShareRequirementDisplayed ? "share-requirements btn selected" : "share-requirements btn" } onClick={ () => {
                                        this.ToggleAllTabsExcept("isShareRequirementDisplayed", isShareRequirementDisplayed);
                                    } }>
                                        <img src={ sharerequirement } className="icon" /> <span>Share Requirements
                                            { !isShareRequirementDisplayed && <FiChevronRight className="icon" /> }
                                            { isShareRequirementDisplayed && <FiChevronDown className="icon" /> }
                                        </span>
                                    </div>
                                    <div className={ isServiceBuddyAvailable ? "meet-buddy btn selected" : "meet-buddy btn" } onClick={ () => {
                                        this.ToggleAllTabsExcept("isServiceBuddyAvailable", isServiceBuddyAvailable);
                                    } }>
                                        <img src={ videochat } className="icon" /> <span>Meet Our Service Buddy
                                            { !isServiceBuddyAvailable && <FiChevronRight className="icon" /> }
                                            { isServiceBuddyAvailable && <FiChevronDown className="icon" /> }
                                        </span>
                                    </div>
                                    <div className={ isAvailableServiceProviders ? "available-service-providers btn selected" : "available-service-providers btn" } onClick={ () => {
                                        this.ToggleAllTabsExcept("isAvailableServiceProviders", isAvailableServiceProviders);
                                    } }>
                                        <img src={ serviceproviders } className="icon" /> <span>Contact Us
                                            { !isAvailableServiceProviders && <FiChevronRight className="icon" /> }
                                            { isAvailableServiceProviders && <FiChevronDown className="icon" /> }
                                        </span>
                                    </div>

                                    {/* <div className={isPriceDisplayed ? "available-service-providers btn selected" : "available-service-providers btn"} onClick={() => {
                                        this.ToggleAllTabsExcept("isPriceDisplayed", isPriceDisplayed);
                                    }}>
                                        <img src={pricepackage} className="icon" /> <span>Price Packages
                                        {!isPriceDisplayed && <FiChevronRight className="icon" />}
                                            {isPriceDisplayed && <FiChevronDown className="icon" />}
                                        </span>
                                    </div> */}
                                </div>

                                { isPriceDisplayed && <ServicePricing category={ category } /> }
                                { isShareRequirementDisplayed && <ServiceRequirements category={ category } /> }
                                { isServiceBuddyAvailable && <ServiceBuddy category={ category } /> }
                                { isAvailableServiceProviders && <ServiceProviders category={ category } /> }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        )
    }
}
