import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { TiStarFullOutline, TiStarHalfOutline } from 'react-icons/ti'
import { IoIosQuote } from 'react-icons/io';
import * as apiClient from "../../components/network/apiClient";

import '../../assets/css/owl.carousel.css';
import '../../assets/css/owl.theme.default.min.css';
import img1 from '../../assets/images/testi-img1.jpg'

import img4 from '../../assets/images/testi-img1.jpg'
import img5 from '../../assets/images/testi-img2.jpg'
import img6 from '../../assets/images/testi-img3.jpg'
import img7 from '../../assets/images/testi-img4.jpg'


export default class HomeTestimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliders: {
                items: []
            }
        }
    }
    imagestates = {
        items: [
            {
                img: img4
            },
            {
                img: img5
            },
            {
                img: img6
            },
            {
                img: img7
            },
            {
                img: img4
            },
            {
                img: img5
            },
            {
                img: img6
            },
            {
                img: img7
            }
        ]
    }
    state = {
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 2
            }
        }
    }
    getTestimonials = () => {
        apiClient.getTestimonials().then(res => {

            if (res.length > 0) {
                this.setState({
                    sliders: {
                        items: res
                    }
                });
            }
        });
    }
    componentDidMount() {
        this.getTestimonials();
    }
    render() {
        const { sliders } = this.state;
        return (
            <>
                {/* {this.imagestates.items.map((item, index) => {
                    return <img key={index} className="shape_img" src={item.img} alt="Testimonial" />
                })} */}
                { sliders.items.length > 0 && <div className="container">
                    <OwlCarousel
                        className="owl-theme"
                        loop={ true }
                        autoplay={ true }
                        margin={ 10 }
                        dots={ true }
                        nav={ false }
                        responsiveClass={ true }
                        responsive={ {
                            0: {
                                items: 1,
                            },
                            600: {
                                items: 1,
                            },
                            1000: {
                                items: 3,
                            },
                        } }
                    >
                        { sliders.items.map((item, index) => {

                            return <div className="testimonial-items" key={ index }>
                                { item.details && <p className="desc">{ item.details.substring(0, 140) }..<IoIosQuote className="quote_icon" /></p> }
                                <div className="testimonial-bottom-flex">
                                    <img src={ item.image } alt="Testimonial" />
                                    <div className="designation">
                                        <h3 className="name">{ item.name }</h3>
                                        <p className="city">{ item.address }</p>
                                        <ul className="stars">
                                            <TiStarFullOutline />
                                            <TiStarFullOutline />
                                            <TiStarFullOutline />
                                            <TiStarFullOutline />
                                            <TiStarHalfOutline />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }) }

                    </OwlCarousel>
                </div> }
            </>
        )
    }
}
