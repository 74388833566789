import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown, FiShoppingCart, FiSearch, FiChevronRight } from "react-icons/fi";
import logo from "../../assets/images/logo.png";
import logo_white from "../../assets/images/logo_white.png";
import logo_dark from "../../assets/images/logo_dark.png";
import logo_light_small from "../../assets/images/logo_light_small.png";

import logo_secondary from "../../assets/images/logo_secondary.png";
import { useHistory } from "react-router-dom";

import HeaderBar from "../common/HeaderBar";
import { ProductConsumer } from '../../productcontext'
import $ from 'jquery'
import MenuCart from "./menucart/MenuCart";

export default function Navbar() {
    let history = useHistory();
    const [searchOpen, setsearchOpen] = useState(false)
    const [navOpen, setnavOpen] = useState(false)

    /*====  side-widget-menu  =====*/
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass('active');
        $(this).toggleClass("active");
        $('html, body').animate({ scrollTop: 0 }, 0);
    });
    $(document).on('click', '.side-menu-wrap .side-menu-ul .sidenav__item.active', function () {
        $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass('active');
        $('html, body').animate({ scrollTop: 0 }, 0);
    });
    $(document).on('click', '.bottom-menu li a', function () {
        $('html, body').animate({ scrollTop: 0 }, 0);
    });

    $(document).on('click', '.help-links li a', function () {
        $('html, body').animate({ scrollTop: 0 }, 0);
    });
    $(document).on('click', '.header-menu-right a', function () {
        $('html, body').animate({ scrollTop: 0 }, 0);
    });
    $(document).on('click', '.home-read-more', function () {
        $('html, body').animate({ scrollTop: 0 }, 0);
    });
    



    $(window).on('scroll', function () {

        // Header Menu Fixed
        if ($(window).scrollTop() > 30) {
            $('body').addClass('header-menu-fixed');
            $('.header-menu-wrapper').addClass('d-none');
            $('.scroll-d-block').addClass('d-block').removeClass('d-none');
        } else {
            $('body').removeClass('header-menu-fixed');
            $('.header-menu-wrapper').removeClass('d-none');
            $('.scroll-d-block').removeClass('d-block').addClass('d-none');
        }
    });

    return (
        <>
            <HeaderBar history={ history } />

            <div className="header-menu-wrapper">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <Link to="/">
                                <img src={ logo_light_small } alt="Logo" className="main-header-logo" />
                            </Link>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    <li>
                                        <Link to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/services">
                                            Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/servizebuddy_program">Servizkart Buddy</Link>
                                    </li>
                                    {/*                                     
                                    <li>
                                        <Link to="/preferred-partners">Serviz Preferred</Link>
                                    </li>
                                    <li>
                                        <Link to="/app-partners">Application Partners</Link>
                                    </li> */}
                                    {/* <li>
                                        <Link to="/advisory-panel">
                                            Advisory Panel
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/contact">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/faq">
                                            FAQ
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            <div className="header-menu-right-flex">
                                {/* <div className="header-menu-cart">
                                    <ul>
                                        <li>
                                            <Link to="#" className="search-button" onClick={() => setsearchOpen(!searchOpen)}>
                                                <FiSearch className="search-icon" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                                {/* <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div> */}
                                <div className="side-menu-open" onClick={ () => setnavOpen(!navOpen) }>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-menu-wrapper d-none scroll-d-block">
                <div className="header-menu-container container">
                    <div className="header-menu-flex">
                        <div className="header-menu-left">
                            <a href="/">
                                <img src={ logo_light_small } alt="Logo" className="main-header-logo" />
                            </a>
                        </div>
                        <div className="header-menu-right">
                            <nav>
                                <ul>
                                    <li>
                                        <Link to="/">
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/services">
                                            Services
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/servizebuddy_program">Servizkart Buddy</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/advisory-panel">
                                            Advisory Panel
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/contact">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/faq">
                                            FAQ
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            <div className="header-menu-right-flex">
                                {/* <div className="header-menu-cart">
                                    <ul>

                                        <li>
                                            <Link to="#" className="search-button" onClick={() => setsearchOpen(!searchOpen)}>
                                                <FiSearch className="search-icon" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                                {/* <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div> */}
                                <div className="side-menu-open" onClick={ () => setnavOpen(!navOpen) }>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                    <span className="menu__bar"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={ navOpen ? 'side-nav-container active' : 'side-nav-container' }>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={ () => setnavOpen(!navOpen) }></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li className="sidenav__item">
                            <Link to="/">  Home </Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/services"> Services  </Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/preferred-partners">Serviz Preferred</Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/app-partners">Application Partners</Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/advisory-panel"> Advisory Panel </Link>
                        </li>
                        <li className="sidenav__item">
                            <Link to="/contact"> Contact Us </Link>
                        </li>
                        <li>
                            <Link to="/faq">
                                FAQ
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>

        </>
    );
}



