import React, { Component } from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiPhoneCall } from 'react-icons/fi'
import { MdMailOutline } from 'react-icons/md'
import * as apiClient from "../../components/network/apiClient";
import { ToastContainer, toast } from 'react-toastify';


export default class CtaForm extends Component {
    constructor(props) {
        super(props);
       // console.log(props);
        this.state = {
            fullName: "",
            email: "",
            mobile: "",
            subject: "",
            message: "",
            requirementSubmitted: false,
        };
    }
    addServiceRequirement = () => {
        const { fullName, email, mobile, subject, message, } = this.state;

        if (fullName == "") {
            toast.error("Name is required");
            return;
        }

        if (email == "") {
            toast.error("Email address is required");
            return;
        }
        // if (mobile == "") {
        //     toast.error("Mobile number is required");
        //     return;
        // }

        if (subject == "") {
            toast.error("Subject is required");
            return;
        }

        if (message == "") {
            toast.error("Message is required");
            return;
        }



        const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailPattern.test(email)) {
            toast.error("Email address is not valid");
            return;
        }

        // const pattern = /^[0-9 '.-]+$/;
        // if (!pattern.test(mobile)) {
        //     toast.error("Only numbers are allowed in mobile");
        //     return;
        // }

        // if (mobile.length < 10) {
        //     toast.error("mobile number is not valid");
        //     return;
        // }


        // if (mobile.length < 10) {
        //     toast.error("mobile number is not valid");
        //     return;
        // }



        let data = {
            Name: fullName,
            EmailID: email,
            Mobile: mobile,
            Subject: subject,
            Remarks: message,
            EmailType: "CNT",
            ParentServiceCategoryID: -1,
            ServiceCategoryID: -1,
        };
        apiClient.submitServiceRequirment(data).then(res => {
          //  console.log(res);
            this.setState({ requirementSubmitted: true });
        }).catch(err => {
            console.log(err);
        })
    }
    componentDidMount() {

    }
    render() {
        const { fullName, email, mobile, subject, message, requirementSubmitted } = this.state;
        return (
            <section className="ctaform-wrapper">
                <div className="container">
                    <div className="ctaform-grid">
                        <div className="ctaform-left">
                            <SectionsTitle title="Get Advice From Our Professionals." subtitle="Call To Action" />
                            <div className="ctaform-contact-info-grid text-center">
                                <div className="item">
                                    <FiPhoneCall className="icon" />
                                    <h2 className="item-title">Direct Line Numbers</h2>
                                    <p>+91 (0) 123 456 7890 / +91 (0) 593 458 2394</p>
                                </div>
                                <div className="item">
                                    <MdMailOutline className="icon" />
                                    <h2 className="item-title">Our Email</h2>
                                    <p>info@servizkart.com/ help@servizkart.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="ctaform-right">
                            {requirementSubmitted && <div className="inner-heading">Thanks for submitting the request <br />We will contact you shortly.</div>}
                            {!requirementSubmitted && <form>
                                <div className="form-grid">
                                    <input type="text" name="name"
                                        value={fullName}
                                        onChange={(event) => {
                                            this.setState({ fullName: event.target.value });
                                        }}
                                        placeholder="Name" />
                                    <input type="EMAIL" name="email"
                                        value={email}
                                        onChange={(event) => {
                                            this.setState({ email: event.target.value });
                                        }}
                                        placeholder="Email" />
                                </div>
                                <input type="text" name="text"
                                    value={subject}
                                    onChange={(event) => {
                                        this.setState({ subject: event.target.value });
                                    }}
                                    placeholder="subject" />
                                <textarea id="textarea"
                                    value={message}
                                    onChange={(event) => {
                                        this.setState({ message: event.target.value });
                                    }}
                                    placeholder="Message" ></textarea>
                                <button type="button" value="Send Us Message"
                                    onClick={() => {
                                        this.addServiceRequirement();
                                    }}
                                    className="theme-button">Send Us Message</button>
                            </form>}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
