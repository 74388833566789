import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import BreadcrumbSmall from '../components/common/BreadcrumbSmall'
import CareerDetails from '../components/contact/CareerDetails'
import Footer from './blocks/Footer'

export default class CareerPage extends Component {
    render() {
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <BreadcrumbSmall title="Career" />
                <CareerDetails />
                <Footer />
            </>
        )
    }
}
