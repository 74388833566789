import React from "react";
import { Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Home from './pages/Home';
import HomeV2 from './pages/HomeV2';
import ShopHomePage from './pages/ShopHomePage';
import Shop from './pages/Shop';
import ProductDetailsPage from "./pages/ProductDetailsPage";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import About_Page from './pages/About_Page'
import TeamGrid from './pages/TeamGrid'
import TeamDetails from './pages/TeamDetails';
import AdvisoryPanel from "./pages/AdvisoryPanel";
import TestimonialPage from './pages/TestimonialPage';
import FAQ from './pages/FAQ';
import OurPricing from './pages/PricingPage';
import UserSignUpPage from './pages/auth/user/SignUpPage';
import UserLoginPage from './pages/auth/user/LoginPage';
import UserLoginPageOTP from './pages/auth/user/LoginPageOTP';
import ServiceProviderSignUpPage from './pages/auth/service-provider/SignUpPage';
import ServiceProviderLoginPage from './pages/auth/service-provider/LoginPage';
import ServiceProviderAuthPage from './pages/auth/service-provider/Auth';
import ForgotPassword from './pages/ForgotPassword';
import ServicePage from './pages/ServicePage';
import ServiceDetailsPage from './pages/ServiceDetailsPage';
import Portfolio from './pages/PortfolioPage';
import PortfolioDetailsPage from './pages/PortfolioDetailsPage';
import BlogFullPage from './pages/BlogFullPage';
import BlogGridPage from './pages/BlogGridPage';
import BlogDetailsPage from './pages/BlogDetailsPage';
import ContactPage from './pages/ContactPage';
import CareerPage from './pages/CareerPage';
import ProfilePage from './pages/Profile';
import DashboaardPage from './pages/dashboard/Dashboard.js';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Industry from "./pages/Industry";
import AdvisoryMember from "./pages/AdvisoryMember";
import VerifyAccount from "./pages/VerifyAccount";
import PreferredPartners from "./pages/PreferredPartners";
import Servicebuddy_Page from "./pages/Servicebuddy_program.js";
import TermsOfUseClient from "./pages/TermsOfUseClient";
import TermsOfUsePartner from "./pages/TermsOfUsePartner";
import AppPartners from "./pages/AppPartners";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import TeamDetails from "./pages/team/details";
import Error from './pages/Error';
import "./assets/css/style.css";
import "./App.css";
import "./Anim.css";

const history = createBrowserHistory();

function App(props) {
  //console.log(history);
  return (
    <React.Fragment>
      <Route>
        <Switch>
          <Route exact path="/" component={ Home } history={ history } />
          <Route path="/home-v2" component={ HomeV2 } history={ history } />
          <Route path="/shop-home" component={ ShopHomePage } history={ history } />
          <Route path="/shop" component={ Shop } history={ history } />
          <Route path="/product-details" component={ ProductDetailsPage } history={ history } />
          <Route path="/cart" component={ CartPage } history={ history } />
          <Route path="/checkout" component={ CheckoutPage } history={ history } />
          <Route path="/about" component={ About_Page } history={ history } />
          <Route path="/team-grid" component={ TeamGrid } history={ history } />
          <Route path="/advisory-panel" component={ AdvisoryPanel } history={ history } />
          <Route path="/advisory-member" component={ AdvisoryMember } history={ history } />
          <Route path="/preferred-partners" component={ PreferredPartners } history={ history } />
          <Route path="/app-partners" component={ AppPartners } history={ history } />
          <Route path="/team-details" component={ TeamDetails } history={ history } />
          <Route path="/testimonial" component={ TestimonialPage } history={ history } />
          <Route path="/industry" component={ Industry } history={ history } />
          <Route path="/faq" component={ FAQ } history={ history } />
          <Route path="/our-pricing" component={ OurPricing } history={ history } />
          <Route path="/sign-up" component={ UserSignUpPage } history={ history } />
          <Route path="/login" component={ UserLoginPage } { ...props } history={ history } />
          <Route path="/loginotp" component={ UserLoginPageOTP } { ...props } history={ history } />
          <Route path="/service-provider-sign-up" component={ ServiceProviderSignUpPage } history={ history } />
          <Route path="/service-provider-login" component={ ServiceProviderLoginPage } { ...props } history={ history } />
          <Route path="/service-provider-auth" component={ ServiceProviderAuthPage } { ...props } history={ history } />
          <Route path="/forgot-password" component={ ForgotPassword } history={ history } />
          <Route path="/services" component={ ServiceDetailsPage } history={ history } />
          <Route path="/service-details" component={ ServiceDetailsPage } history={ history } />
          <Route path="/portfolio-grid" component={ Portfolio } history={ history } />
          <Route path="/portfolio-details" component={ PortfolioDetailsPage } history={ history } />
          <Route path="/blog-full-width" component={ BlogFullPage } history={ history } />
          <Route path="/blog-grid" component={ BlogGridPage } history={ history } />
          <Route path="/blog-details" component={ BlogDetailsPage } history={ history } />
          <Route path="/contact" component={ ContactPage } history={ history } />
          <Route path="/career" component={ CareerPage } history={ history } />
          <Route path="/profile" component={ ProfilePage } history={ history } />
          <Route path="/dashboard" component={ DashboaardPage } history={ history } />
          <Route path="/verify" component={ VerifyAccount } history={ history } />
          <Route path="/terms-of-use-client" component={ TermsOfUseClient } history={ history } />
          <Route path="/terms-of-use-partner" component={ TermsOfUsePartner } history={ history } />
          <Route path="/privacy-policy" component={ PrivacyPolicy } history={ history } />
          <Route path="/servizebuddy_program" component={ Servicebuddy_Page } history={ history } />
          
          <Route component={ Error } />
        </Switch>
      </Route>
      <ToastContainer position="top-center" autoClose={ 1000 } hideProgressBar={ true } />
    </React.Fragment>
  );
}

export default App;
