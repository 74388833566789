import React, { Component } from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import { Link } from 'react-router-dom'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import CtaForm from '../components/other/CtaForm'
import Footer from './blocks/Footer';
import Partner from "./partners/partner";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as apiClient from "../components/network/apiClient";

export default class AppPartners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            selectedPartner: null,
        };
    }
    onPartnerClick = (item) => {
        this.setState({
            isPopupDisplayed: true,
            selectedPartner: item
        });
    }
    popupToggle = () => {
        const { isPopupDisplayed } = this.state;
        let c = !isPopupDisplayed;
        this.setState({ isPopupDisplayed: c });
    }
    componentDidMount() {
        apiClient.getAppPartners().then(res => {
           // console.log(res);
            this.setState({
                partners: res,
                isPopupDisplayed: false,
                selectedPartner: null,
            });
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        const { partners, isPopupDisplayed } = this.state;
        return (
            <>
                <header className="header-area">
                    <Navbar />
                </header>
                <Breadcrumb title="Application partners" />
                <section className="blog-wrapper blog-grid-page">
                    <div className="container">
                        <div className="blog-grid">
                            { partners.map((item, index) => {
                                return <Partner key={ index } partner={ item } onClick={ this.onPartnerClick } />;
                            }) }
                        </div>
                        {/* <ul className="pagination d-flex">
                            <li><Link className='page-link' to="/blog-full-width"><FiChevronsLeft /></Link></li>
                            <li><Link className='page-link' to="/blog-full-width">1</Link></li>
                            <li><span className="page-link current">2</span></li>
                            <li><Link className='page-link' to="/blog-full-width">3</Link></li>
                            <li><Link className='page-link' to="/blog-full-width"><FiChevronsRight /></Link></li>
                        </ul> */}
                    </div>
                </section>
                {/* <div className="form-white-bg">
                    <CtaForm />
                </div> */}
                <Footer />
                <Modal isOpen={ isPopupDisplayed } toggle={ this.popupToggle } className="my-model-popup">
                    <ModalHeader toggle={ this.popupToggle }>
                        More details
                    </ModalHeader>
                    <ModalBody>
                        { this.state.selectedPartner && <div dangerouslySetInnerHTML={ { __html: this.state.selectedPartner.description } }></div> }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ this.popupToggle }>Close</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}
