import React, { Component } from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import img1 from '../../assets/images/team-1.jpg'
import { FiPhone, FiLink } from 'react-icons/fi';
import { FaEnvelope, FaQuoteLeft } from 'react-icons/fa';

import useModal from 'react-hooks-use-modal';

export const DisplayDetails = (props) => {
    const member = props.item;
    const [Modal, open, close, isOpen] = useModal('root', {
        preventScroll: true
    });
    return (
        <>
            <div className="theme-button" onClick={ () => {
                props.onClick(member);
            } }>
                Read His Story <FiChevronRight className="icon" /></div>
            <Modal>
                <div className="team-member-detail">
                    <section className="team-details-wrapper">
                        <div className="container">
                            <div className="team-details-grid-top d-grid">
                                <div className="team-details-img">
                                    <img src={ member.image } alt="Team Details" className="member-detail-image" />
                                </div>
                                <div className="team-details-content">
                                    <h1 className="name">{ member.name }</h1>
                                    <p className="desc" dangerouslySetInnerHTML={ { __html: member.description } }></p>
                                    {/* <ul className="team-details-contact">
                                        <li><a href={"tel:+" + member.phonenumber}><span className="icon"><FiPhone /></span> {member.phonenumber}</a></li>
                                        <li><a href={"mailto:" + member.emailAddress}><span className="icon"><FaEnvelope /></span> {member.emailAddress}</a></li>
                                        <li><a href={member.webSite} target="_blank"><span className="icon"><FiLink /></span> {member.webSite}</a></li>
                                    </ul> */}
                                    <div className="team-expertise-education-grid d-grid">
                                        <div className="left">
                                            <h2>Industries Served</h2>
                                            <ul className="team-details-expertise lists" dangerouslySetInnerHTML={ { __html: member.areaofExpertise } }>
                                            </ul>
                                        </div>
                                        <div className="right">
                                            <h2>Functional Areas</h2>
                                            <ul className="team-details-education lists" dangerouslySetInnerHTML={ { __html: member.education } }>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="team-details-grid-bottom d-grid">
                                <div className="left">
                                    <h3>A Little Description About Me.</h3>
                                    <span dangerouslySetInnerHTML={ { __html: member.moredescription } }></span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="close-parent">
                        <button onClick={ close }>CLOSE</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default class AdvisoryMemberDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMember: null
        };
    }
    onSelectMember = (item) => {
        this.setState({
            selectedMember: item
        });
    }
    componentDidMount() {

    }
    render() {
        const { members } = this.props;
        const { selectedMember } = this.state;
        return (
            <section className="team-wrapper">
                <div className="container">
                    <div className="text-center">
                        <SectionsTitle title="Meet our Esteemed Members of Advisory Panel" subtitle="" />
                    </div>
                    <div className="page-intro"><p>Members of SERVIZKART Advisory Panel are prominent independent leaders and experts of respective professional fields having experience of many years in various leadership positions in diversified industries</p>
                        <p>SERVIZKART is proud of its Advisory Panel whose members represent a diverse group from different genders, races, cultures, and backgrounds. To become a member of Advisory Panel is completely voluntary on member’s part and such member does not take care of any day to day operations of SERVIZKART and does not create any conflict of interest with any other association.</p>
                        <p>SERVIZKART will be happy to connect its clients and partners to any of the member of Advisory Panel for any specialised advisory.</p>
                    </div>
                    <div className="team-grid text-center">
                        { members.length > 0 && members.map((item, index) => {
                            return (
                                <div className="team" key={ index }>
                                    <div className="team-img">
                                        <img src={ item.image } alt="Team" />
                                        {/* <ul className="socials" >
                                            <a href="https://facebook.com"><FaFacebookF /></a>
                                            <a href="https://twitter.com"><FaTwitter /></a>
                                            <a href="https://linkedin.com"><FaLinkedinIn /></a>
                                            <a href="https://youtube.com"><FaYoutube /></a>
                                        </ul> */}
                                    </div>
                                    <div className="team-content" onClick={ () => {
                                        this.props.onClick(item);
                                    } }>
                                        <h2 className="name">{ item.name }</h2>
                                        <h2 className="name">{ item.designation }</h2>
                                        {/* <p className="designation">Director</p> */ }
                                        {/* <DisplayDetails item={item} onClick={this.props.onClick} /> */ }
                                        {/* <Link to={"/advisory-member?id=" + item.id} className="theme-button">Read His Story <FiChevronRight className="icon" /></Link> */ }

                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                </div>
            </section>
        )
    }
}
