import React, { Component } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { default as img1, default as img2, default as img3 } from '../../assets/images/about-img4.jpg';
import moredetails from '../../assets/images/moredetails.jpg';
import pricepackage from '../../assets/images/pricepackages.jpg';
import serviceproviders from '../../assets/images/serviceproviders.jpg';
import sharerequirement from '../../assets/images/sharerequirement.jpg';
import videochat from '../../assets/images/videochat.jpg';
import SectionsTitle from '../../components/common/SectionsTitle';
import * as apiClient from "../../components/network/apiClient";

import verified_ser_partners from '../../assets/images/verified_ser_partners.png';
import save_time from '../../assets/images/save_time.png';
import best_quotes from '../../assets/images/best_quotes.png';
import smooth_contract from '../../assets/images/smooth_contract.png';
import realtime_conversation from '../../assets/images/realtime_conversation.png';
import safe_payment_policy from '../../assets/images/safe_payment_policy.png';
import one_click_search from '../../assets/images/one_click_search.png';
import user_friendly from '../../assets/images/user_friendly.png';
import quick_turnaround from '../../assets/images/quick_turnaround.png';
import expand_business from '../../assets/images/expand_business.png';
import work_hot_leads from '../../assets/images/work_hot_leads.png';
import realtime_assitance from '../../assets/images/realtime_assitance.png';
import timly_payments from '../../assets/images/timly_payments.png';
import { Link } from 'react-router-dom';

export default class HomeAboutus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buddyProgramContent: "<ul class='values-list'><li>Verified Service Partners</li><li>Save Time & Individual Efforts On Search</li><li>Best Quotes Gauranteed</li>Smooth Contracting Experience</li><li>Real-Time Conversations & Feedbacks</li><li>Safe Payment Policy - Gauranteed!</li></ul>",
            buddyProgramImage: img1,
            requestCallback: "<ul class='values-list'><li>Expand Your Business In A Cost-Effective Manner.</li><li>Work On Hot Leads From The Best Of The Industries</li><li>Save Lead Generation Cost, Time & Efforts</li><li>Real-Time Assistance</li><li>Verified Businesses Only</li></ul>",
            requestCallbackImage: img2,
            shareRequirement: "<ul class='values-list'><li>One-Click Search Saves Time</li><li>Best Price Gauranteed</li><li>User-Friendly Experience</li><li>Quick Turnaround Time</li><li>Verified Service Partners</li><li>Verified Businesses Only</li></ul>",
            shareRequirementImage: img3,
        };
    }
    tabstate = {
        tabs: [
            {
                img: img1,
                desc: "ServizKart is a blended serviz© marketplace which is a unique value creation in Professional Servizes offerings.<br/> ServizKart works as a solution partner in completely transparent and flexible way. We have following options available and clients can choose the best suited options:<br/>                1.	ServizKart Buddy: Once the option is selected, highly skilled ServizKart Buddy is assigned to interact with the prospective client and will identify the best available Serviz Provider through consortium or from the marketplace. <br/>                               This option comes with a unique feature of protection of money refund and guaranteed time bound delivery.      <br/>                          2.	Search directly various professional servize providers in our serviz marketplace where all serviz providers are selected through our rigorous selection process and they carry the ServizKart Verified Tag.   <br/>                             3.	At any point of time if a client needs the assistance, the client can opt for ServizKart Buddy option.",
                button: "Get Started"
            },
            {
                img: img2,
                desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
                button: "Get Started",
                img_position: "img_right"
            },
            {
                img: img3,
                desc: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet",
                button: "Get Started"
            }
        ]
    }

    tabsnav = {
        navs: [
            {
                title: "Value for Corporates"
            },
            {
                title: "Value for Serviz partners"
            },
            {
                title: "Value for Individuals"
            }
        ]
    }
    getBuddyProgramImages = () => {
        apiClient.getContentPageConfig("BuddyProgramImages").then(res => {
            //console.log(res);
            if (res.length > 0) {
                //this.setState({ buddyProgramContent: res[0].propertyName, buddyProgramImage: res[0].imageUrl });
                this.setState({ buddyProgramImage: res[0].imageUrl });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    getRequestCallBackImages = () => {
        apiClient.getContentPageConfig("RequestCallBackImages").then(res => {
            //console.log(res);
            if (res.length > 0) {
                //this.setState({ requestCallback: res[0].propertyName, requestCallbackImage: res[0].imageUrl });
                this.setState({ requestCallbackImage: res[0].imageUrl });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    getShareTheRequirementsImages = () => {
        apiClient.getContentPageConfig("ShareTheRequirementsImages").then(res => {
            //console.log(res);
            if (res.length > 0) {
                // this.setState({ shareRequirement: res[0].propertyName, shareRequirementImage: res[0].imageUrl });
                this.setState({ shareRequirementImage: res[0].imageUrl });
            }
        }).catch(err => {
            console.log("Error : " + err);
        });
    }
    componentDidMount() {
        this.getBuddyProgramImages();
        this.getRequestCallBackImages();
        this.getShareTheRequirementsImages();
    }
    render() {
        const {
            buddyProgramContent, buddyProgramImage,
            requestCallback, requestCallbackImage,
            shareRequirement, shareRequirementImage
        } = this.state;
        return (
            <section className="tabs-wrapper">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <SectionsTitle title="Why Servizkart" />
                        </div>
                    </div>


                    <div className="tabs-content-wrap home-about-us">
                        <Tabs>
                            <TabList className="tabs-nav">
                                { this.tabsnav.navs.map((item, index) => {
                                    return <Tab key={ index }>{ item.title }</Tab>
                                }) }
                            </TabList>

                            <TabPanel className="tabs-content" key="0">
                                <div className={ 'tabs-content-flex ' }>
                                    {/* <div className="tabs-left">
                                        <img className="home-whychoose-image" src={ buddyProgramImage } alt="Tabs" />
                                    </div> */}
                                    <div className="tabs-full">
                                        <div className="about-values">
                                            <div className="value">
                                                <div className="sbicon"><img src={ verified_ser_partners } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Verified Service Partners</div>
                                                    <div className="description">We have a rigours process of Service Partner verification which includes the scrutiny of professional expertise, validation of certificates and other credentials, verification of GST and other compliances before we take Service Partner on board   </div>
                                                </div>


                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ save_time } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Save Time & Individual Efforts On Search</div>
                                                    <div className="description">Due to our rigours selection process of a Service Partner, it saves your team’s time and effort and helps you to focus on your business </div>
                                                </div>
                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ best_quotes } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Best Quotes Gauranteed</div>
                                                    <div className="description">When you share your work requirement with SERVIZKART, it goes through a preliminary discussion with your team and our back end teams find out the best Service Providers with the help of our system algorithm intelligence.  </div>
                                                </div>

                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ smooth_contract } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Smooth Contracting Experience</div>
                                                    <div className="description">Once the Service Partner is selected, the work allotment and getting into agreement is very convenient, either by using our standard templates or using your format </div>
                                                </div>
                                            </div>

                                            <div className="value">
                                                <div className="sbicon"><img src={ realtime_conversation } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Real-Time Conversations & Feedbacks</div>
                                                    <div className="description">SERVIZKART understands the difficulties being faced working virtually,
                                                        we have provided various ways to connect with least time and efforts whether it is Call Scheduling,
                                                        Video Call, Audio Call, Email or Online Chat. For any feedback please write us at <a href="mailto:connect@servizkart.com">connect@servizkart.com</a> </div>
                                                </div>
                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ safe_payment_policy } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Safe Payment Policy - Gauranteed!</div>
                                                    <div className="description">When you pay any amounts to SERVIZKART as per the terms, it goes to a RBI mandated Nodal Bank Account and payment
                                                        to Service Partner is released only after your go ahead.
                                                        We give additional Guarantee through our <Link to="/servizebuddy_program">SERVIZKART BUDDY</Link> Program. </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel className="tabs-content" key="1">
                                <div className={ 'tabs-content-flex img_right' }>
                                    {/* <div className="tabs-left">
                                        <img className="home-whychoose-image" src={ requestCallbackImage } alt="Tabs" />
                                    </div> */}
                                    {/* <div className="tabs-right">
                                        <p className="tabs-desc" dangerouslySetInnerHTML={ { __html: requestCallback } }></p>
                                    </div> */}

                                    <div className="tabs-full">
                                        <div className="about-values">
                                            <div className="value">
                                                <div className="sbicon"><img src={ expand_business } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Expand Your Business In A Cost-Effective Manner</div>
                                                    <div className="description">SERVIZKART takes care the business development, marketing and collection and you can start working with your current set up without any additional investment.</div>
                                                </div>


                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ work_hot_leads } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Work On Hot Leads From The Best Of The Industries</div>
                                                    <div className="description">Since SERVIZKART specialises in professional services, we pass on only genuine and verified leads for bidding to our partners</div>
                                                </div>
                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ one_click_search } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Save Lead Generation Cost, Time & Efforts</div>
                                                    <div className="description">There is no additional cost to buy the leads from the market or to spend on digital media as SERVIZKART does it all for you. You sit back and provide the quality and timely services</div>
                                                </div>

                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ verified_ser_partners } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Verified Business Only</div>
                                                    <div className="description">Our teams perform a rigorous process to verify all the clients and when any work requirement is shared with SERVIZKART, our teams perform a basic due diligence before the work is given to Service partners   </div>
                                                </div>
                                            </div>

                                            <div className="value">
                                                <div className="sbicon"><img src={ realtime_conversation } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Real-Time Assistance</div>
                                                    <div className="description">Our SERVIZKART BUDDY is just a call away for any assistance while delivering the work to our valued clients.
                                                        For any queries please write us at <a href="mailto:partnership@servizkart.com">partnership@servizkart.com</a></div>
                                                </div>
                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ timly_payments } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Timely Payments – Assured! </div>
                                                    <div className="description">Entire payments are governed by RBI and all payments collected go to a Nodal Account. When client gives a go ahead to release the payment, we process the payment with 2 Days after retaining our fee</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel className="tabs-content" key="2">
                                <div className={ 'tabs-content-flex ' }>
                                    {/* <div className="tabs-left">
                                        <img className="home-whychoose-image" src={ shareRequirementImage } alt="Tabs" />
                                    </div>
                                    <div className="tabs-right">
                                        <p className="tabs-desc" dangerouslySetInnerHTML={ { __html: shareRequirement } }></p>
                                    </div> */}


                                    <div className="tabs-full">
                                        <div className="about-values">
                                            <div className="value">
                                                <div className="sbicon"><img src={ one_click_search } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">One-Click Search Saves Time</div>
                                                    <div className="description">Finding a vendor for your day to day requirement to deal with various business and professional needs, we have verified vendors just a click away</div>
                                                </div>


                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ best_quotes } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Best Price Gauranteed</div>
                                                    <div className="description">SERVIZKART understands the difficulties being faced by you while finding a Professional, negotiating and handling the payments. Our prices for such Instant Services are highly competitive and saves the individual efforts</div>
                                                </div>
                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ user_friendly } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">User-Friendly Experience</div>
                                                    <div className="description">Our platform has been made keeping the user journey and is made state of the art for ease while navigating.
                                                        For any feedback please write to us at <a href="mailto:connect@servizkart.com">connect@servizkart.com</a></div>
                                                </div>

                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ quick_turnaround } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Quick Turnaround Time</div>
                                                    <div className="description">SERVIZKART knows the value of time and keeping that in mind,
                                                        the entire process is quick and guaranteed time bound delivery. You have the option to take the help of
                                                        <Link to="/servizebuddy_program"> SERVIZKART BUDDY</Link>  for all your queries</div>
                                                </div>
                                            </div>

                                            <div className="value">
                                                <div className="sbicon"><img src={ verified_ser_partners } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Verified Service Partners</div>
                                                    <div className="description">We have a rigours process of Service Partner verification which includes the scrutiny of professional expertise, validation of certificates and other credentials, verification of GST and other compliances before we take Service Partner on board   </div>
                                                </div>
                                            </div>
                                            <div className="value">
                                                <div className="sbicon"><img src={ verified_ser_partners } className="icon" /></div>
                                                <div className="details">
                                                    <div className="title">Verified Businesses Only</div>
                                                    <div className="description">Our teams perform a rigorous process to verify all the clients and when any work requirement is shared with SERVIZKART, our teams perform a basic due diligence before the work is given to Service partners   </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </TabPanel>

                            {/* {this.tabstate.tabs.map((tab, index) => {
                                return <TabPanel className="tabs-content" key={index}>
                                    <div className={'tabs-content-flex ' + tab.img_position}>
                                        <div className="tabs-left">
                                            <img src={tab.img} alt="Tabs" />
                                        </div>
                                        <div className="tabs-right">
                                            <p className="tabs-desc" dangerouslySetInnerHTML={{ __html: tab.desc }}></p>
                                        </div>
                                    </div>
                                </TabPanel>
                            })} */}
                        </Tabs>
                    </div >
                </div >
            </section >
        )
    }
}
